import React from 'react';
import { useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Group } from '../assets/icons/group.svg';
import { ReactComponent as Profile } from '../assets/icons/profile.svg';
import { ReactComponent as Key } from '../assets/icons/key.svg';
import { ReactComponent as Company } from '../assets/icons/company.svg';
import { ReactComponent as Product } from '../assets/icons/stacked-print-products.svg';
import { ReactComponent as Contact } from '../assets/icons/user-check.svg';
import { ReactComponent as Invoice } from '../assets/icons/file-invoice.svg';
import { ReactComponent as InvoiceParams } from '../assets/icons/file-config.svg';
import { ReactComponent as IconCashMachine } from '../assets/icons/cash-register-color.svg';
import { ReactComponent as CartDashboard } from '../assets/icons/business-chart.svg';
import FlatSquareRoundedCard from './FlatSquareRoundedCard';
import '../styles/components/horizontal-flat-nav.css';
import {
    USERS_LIST_ROUTE, PROFILES_LIST_ROUTE, ASSIGNMENTS_LIST_ROUTE,
    FEATURES_LIST_ROUTE, ACTIONS_LIST_ROUTE, ENTREPRISE_INFO_ROUTE, 
    PRODUCTS_LIST_ROUTE, PRODUCTSUPPLIES_LIST_ROUTE, CLIENTS_LIST_ROUTE, CASHREGISTER_PAD_ROUTE, 
    INVOICES_LIST_ROUTE, INVOICES_PARAMS_ROUTE,COSNIGNATIONS_LIST_ROUTE, INVENTORY_LIST_ROUTE,
    DASHBOARD_LIST_ROUTE,
    INVCOMMANDS_LIST_ROUTE,
    PRODUCTTYPES_LIST_ROUTE,
    MEASUREUNITS_LIST_ROUTE,
    COMMANDS_API_LIST_ROUTE,
    INVOICES_PAIMENTS_ROUTE
  } from '../constants/routes';

/**
 * @author Emerone Power
 * @returns 
 */
function HorizontalFlatNav(){
    const page = useSelector((state) => state.app.page);
    const assigments = useSelector((state) => state.app.userAssigments);
    const navigate = useNavigate();
    console.log(assigments);

    const handleOnClick = (href) => {
        if(href){
            navigate(href);
            return;
        }

       // setMenuIsOpen(!menuIsOpen);
    }
    function getModule(assigment, module) {
        if(assigment[module].authorized === 1 && module === 5){
            return <ul>
                    
                    <FlatSquareRoundedCard 
                        active={false} 
                        authorized={1} 
                        label="Utilisateurs" 
                        icon={Group} 
                        href={'USERS_LIST_ROUTE'}
                    >
                        <ul>
                            <li onClick={() => handleOnClick(USERS_LIST_ROUTE)}>
                                <label htmlFor="">Gestion des utilisateurs</label>
                            </li>
                            <li onClick={() => handleOnClick(PROFILES_LIST_ROUTE)}>
                                <label htmlFor="">Gestion des profils</label>
                            </li>
                            <li onClick={() => handleOnClick(FEATURES_LIST_ROUTE)}>
                                <label htmlFor="">Gestion des droits</label>
                            </li>
                        </ul>
                    </FlatSquareRoundedCard>
                    
                </ul>
        }
        if(assigment[module].authorized === 1 && module === 4){
            //<li onClick={() => handleOnClick(PRODUCTTYPES_LIST_ROUTE)}>
            //<label htmlFor="">Création catégorie</label>
            //</li>
            //<li onClick={() => handleOnClick(MEASUREUNITS_LIST_ROUTE)}>
                //<label htmlFor="">Création Unités</label>
            //</li>
            return <ul>
                <FlatSquareRoundedCard 
                    active={false} 
                    authorized={1} 
                    label="Articles" 
                    icon={Group} 
                    href={"USERS_LIST_ROUTE"}
                >
                    <ul>
                        <li onClick={() => handleOnClick(ENTREPRISE_INFO_ROUTE)}>
                            <label htmlFor="">Bibliothèque</label>
                        </li>
                        
                        <li onClick={() => handleOnClick(PRODUCTSUPPLIES_LIST_ROUTE)}>
                            <label htmlFor="">Approvisionnnements</label>
                        </li>
                        <li onClick={() => handleOnClick(INVENTORY_LIST_ROUTE)}>
                            <label htmlFor="">Inventaire</label>
                        </li>
                        <li onClick={() => handleOnClick(CLIENTS_LIST_ROUTE)}>
                            <label htmlFor="">Gestion des Clients</label>
                        </li>
                    </ul>
                </FlatSquareRoundedCard>
            </ul>
        }
        if(assigment[module].authorized === 1 && module === 3){
            return <ul>
            <FlatSquareRoundedCard 
                active={false} 
                authorized={1} 
                label="Journaux" 
                icon={Group} 
                href={"USERS_LIST_ROUTE"}
            >
                <ul>
                    <li onClick={() => handleOnClick(INVOICES_LIST_ROUTE)}>
                        <label htmlFor="">factures</label>
                    </li>
                    <li onClick={() => handleOnClick(INVCOMMANDS_LIST_ROUTE)}>
                        <label htmlFor="">commandes</label>
                    </li>
                    
                    <li onClick={() => handleOnClick(INVOICES_PAIMENTS_ROUTE)}>
                        <label htmlFor=""> paiements</label>
                    </li>
                    <li onClick={() => handleOnClick(PRODUCTS_LIST_ROUTE)}>
                        <label htmlFor="">Etat des stocks</label>
                    </li>
                </ul>
            </FlatSquareRoundedCard>
        </ul>
        }
        if(assigment[module].authorized === 1 && module === 0){
            return <ul>
            <FlatSquareRoundedCard 
                active={false} 
                authorized={1} 
                label="" 
                icon={Group} 
                href={"USERS_LIST_ROUTE"}
            >
                <ul>
                    <li onClick={() => handleOnClick(CASHREGISTER_PAD_ROUTE)}>
                        <label htmlFor=""><span className="link-icon"> <IconCashMachine/> </span> </label> 
                    </li>
                </ul>
            </FlatSquareRoundedCard>
            
        </ul>
        }
        if(assigment[module].authorized === 1 && module === 1){
           
        }
        if(assigment[module].authorized === 1 && module === 6){
        
        }
    }
    return (
        <nav className='h-nav'>
            {getModule(assigments, 6)}
            {getModule(assigments, 0)}
            {getModule(assigments, 1)}
            {getModule(assigments, 3)} 
            {getModule(assigments, 2)}
            {getModule(assigments, 4)}
            {getModule(assigments, 5)}
        </nav>
    );
}

export default HorizontalFlatNav;