import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import {navigate, setBreadcrumb} from '../../app/appSlice';
import { convertNumberToMoneyFormat, stringIsEmpty } from "../../utils/functions";
import {INVENTORY_API_UPDATE_ROUTE, INVENTORY_LIST_ROUTE, INVENTORY_NEW_ROUTE} from '../../constants/routes';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import Select from "../../components/form/Select";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import Add from "./Add";
import Update from "./Update";
import "./inventory.css";
import NavLink from "../../components/NavLink";
import BackdropModal from "../../components/BackdropModal";
import NonedropModal from "../../components/NonedropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import {addInventory, fetchInventory, addInvProduct} from './InventoryAPI';
import { fetchUsers } from '../users/usersAPI';
import { fetchProducts } from '../Products/ProductsAPI';


/**
 * @author Emerone Power
 * @returns 
 */
function NewInventory(){
    const [addForm, setAddForm] = useState(false);
    const [list, setList] = useState([]);
    const [listUsers, setListUsers] = useState([]);
    const [listItems, setListItems] = useState([]);
    const [listProducts, setListProducts] = useState([]);
    const [lib, setLib] = useState('');
    const [libError, setLibError] = useState(null);
    const [price, setPrice] = useState('');
    const [priceError, setPriceError] = useState(null);
    const [numref, setNumref] = useState('');
    const [numrefError, setNumrefError] = useState(null);
    const [descrip, setDescrip] = useState('');
    const [descripError, setDescripError] = useState(null);
    const [users, setUsers] = useState('');
    const [usersError, setUsersError] = useState(null);
    const [datereg, setDatereg] = useState('');
    const [dateregError, setDateregError] = useState(null);
    const [session, setSession] = useState('');
    const [sessionError, setSessionError] = useState(null);
    const [loader, setLoader] = useState(false);
    
    const [listEntries, setListEntries] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listUnit, setListUnit] = useState([]);
    const [stockResume, setStockResume] = useState('');
    const [category, setCategory] = useState("none");
    const [totalQty, setTotalQty] = useState(0);
    const [totalQtyCasier, setTotalQtyCasier] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceCasier, setTotalPriceCasier] = useState(0);
    const [pattern, setPattern] = useState('');
    const [updateForm, setUpdateForm] = useState(false);
    const [itemToManage, setItemToManage] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const dispatch = useDispatch();
    const navigateIn = useNavigate();
    const [params, setParams] = useState({});

    useEffect(() => {
        dispatch(navigate(INVENTORY_NEW_ROUTE));
        dispatch(setBreadcrumb(['Nouvel Inventaire']));
        //getTotalResume();
    }, []);

    useEffect(() => {
        getListProducts();
        getListUsers();
        //getInventory(listProducts);
        setLib(getInvLib());
    }, []);

    const getListUsers = () => {
        setListUsers([]);
        fetchUsers(0, 100).then(response => {
            const {userDatas} = response.data;
            if (response.data.userDatas) {
                setListUsers(userDatas);
            }else{
                console.log(response.data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
    const getListProducts = () => {
        setListProducts([]);
        //setListEntries([]);
        fetchProducts(0,50).then(response => {
            const {productDatas} = response.data;
            if (response.data.productDatas) {
                setListProducts(productDatas);
                getInventory(productDatas);
            }else{
                console.log(response.data);
            }
            
        })
        .catch(error => {
            console.log(error);
        });
    }
    const getInventory = (list) =>  {
        //build inventory entries
        //getListProducts()
        let errr = 0;
        let localRows = [];
        setListEntries([]);
        setPrice(0);
        //console.log(list);
        //console.log(listEntries.length);
        //list.length > 0 ? errr = 0 : getListProducts();
        list.length > 0 && listEntries.length === 0? 
            list.map((item, index) => (
                localRows.push({id: item.id, lib: item.lib, theorikQty: item.qty, qty: item.qty, price: item.price, status: 0}),
                setPrice(parseInt(item.qty*item.price)+(price*1))
                ))
        : errr = 1;
        setListEntries(localRows);
        console.log(listEntries);
    }
    const clearForm = () => {
        setLib('');
        setNumref('');
        setDescrip('');
        setPrice('');
    }

    const controlInputs = () => {
        let valid = true;
        const localPrice =  stringIsEmpty(price);

        if(localPrice === 0 ){
            valid = false;
            setPriceError('Ce champ est obligatoire');
        }else{
            setPrice(localPrice);
            setPriceError(null);
        }
        return valid;
    }

    const handleAddClick = () => {
        setLoader(true);
        let responseType = false;
        if(controlInputs()){
            addInventory({lib: lib, numref: numref, price: price, descrip: descrip, users: users, session: session}).then(response => {
                setLoader(false);
                if(response.data.params.state === 'successfull'){
                    //inisert commands
                    listEntries.map((item, index) => (
                        addInvProduct({qty: item.qty, theorikQty: item.theorikQty, price: item.price*item.qty, product: item.id, group: response.data.inventory.id}).then(res => {
                            
                            if (res.data.params.state == 'successfull' && listEntries.length == index+1) {
                                //loadInvoiceDetailsView({id: response.data.invoice.id, code: code, matricul: matricul, numref: numref, numpaie: numpaie, tva: tva, tps: tps, css: css, delay: delay, client: client, phone: phone, netprice: netprice, htprice: htprice, sold: newSold, discount: discount, status: newStatus, datelivr: datelivr, datereg: datereg});
                                clearForm();
                                fetchProducts();
                                toggleNotifyModal();
                            }
                        }).catch(error => {
                            setLoader(false);
                            console.log(error);
                        })
                    ));
                    clearForm();
                    fetchProducts();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
            }).catch(error => {
                setLoader(false);
            });
        }else
        setLoader(false);
    }
    const handleSaveClick = () => {
        setLoader(true);
        let responseType = false;
        if(controlInputs()){
            console.log("saving...");
            addInventory({lib: '', numref: numref, price: price, descrip: descrip, users: users, session: session}).then(response => {
                setLoader(false);
                console.log(response);
                if(response.data.params.state === 'successfull'){
                    //inisert commands
                    listEntries.map((item, index) => (
                        addInvProduct({qty: item.qty, theorikQty: item.theorikQty, price: item.price*item.qty, product: item.id, group: response.data.inventory.id}).then(res => {
                            
                            if (res.data.params.state == 'successfull' && listEntries.length == index+1) {
                                //loadInvoiceDetailsView({id: response.data.invoice.id, code: code, matricul: matricul, numref: numref, numpaie: numpaie, tva: tva, tps: tps, css: css, delay: delay, client: client, phone: phone, netprice: netprice, htprice: htprice, sold: newSold, discount: discount, status: newStatus, datelivr: datelivr, datereg: datereg});
                                clearForm();
                                fetchProducts();
                                toggleNotifyModal();
                            }
                        }).catch(error => {
                            setLoader(false);
                            console.log(error);
                        })
                    ));
                    clearForm();
                    fetchProducts();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
            }).catch(error => {
                setLoader(false);
            });
            console.log(listEntries);
        }else
        setLoader(false);
    }
    const addRow = (id, qty, realQty) => {
        
        console.log("real qty : "+realQty);
    }

    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
    }

    const toggleAddForm = () => {
        setAddForm(!addForm);
    }

    const toggleUpdateForm = () => {
        setUpdateForm(!updateForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const handleUpdateIconClick = (item) => {
        setItemToManage({product: item, entries: listEntries});
        toggleUpdateForm();
    }

    const handleDeleteIconClick = (item) => {
        setItemToManage(item);
        toggleConfirmModal();
    }

    
    //None / info modal
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        setItemToManage(0);
        toggleNoneModal();
    }
    const assigments = useSelector((state) => state.app.userAssigments[3]);
    function getButton(type, item = null) {
        
        var toggleModal = handleNoneIconClic;
        if (type ==='add') {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = toggleAddForm;
            }
            return  <Button 
            label="Nouvel inventaire" 
            className="primary" 
            onClick={toggleModal}
            //onClick={toggleAddForm}
        />
        }
        if (type ==='save' && listItems != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleSaveClick();
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Button 
            label="Enregistrer" 
            className="primary" 
            onClick={toggleModal}
            //onClick={toggleAddForm}
        />
        }
        if (type ==='update' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleUpdateIconClick(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Edit onClick={() => toggleModal(item)} />
        }
        if (type ==='delete' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleDeleteIconClick(item)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <Delete onClick={toggleModal} />
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    const updateListEntries = (product, qty=0 ) => {
        console.log(qty);
        setPrice(0);
        setUsers('none');
        //let total = (item.price*qty)-((item.price*qty*discount)/100);
        if(listEntries.length > 0 && product.id > 0 && qty > 0){
            console.log("rows : ->");
            console.log(listEntries);
            console.log("! real qty : " + qty);
            let itemIndex = null;
            let localPrice = 0;
            listEntries.map((item, index) => (
                item.id === product.id ? itemIndex = index : 0,
                localPrice += parseInt(item.price*item.qty)
                //setPrice(parseInt(item.price)+parseInt(price))
            ));
            console.log("length : ->"+listEntries.length);
            console.log("index : ->"+itemIndex);
            listEntries[itemIndex].qty= qty;
            
            console.log("updated : ->");
            console.log(listEntries[itemIndex]);
            setPrice(localPrice);
        }
        //newRow.push({product: item.id, lib: item.lib, price: item.price, qty: qty, discount: discount, total: total, unit: unit});
        //setTotalPrice(total+totalPrice);
    }
    function getInvLib(){
        let result = "YYYY-mm-ss";
        const today = new Date();
       console.log(today);
        return result;
    }

    function getTotalResume(list){
        let qty = 0
        let localqty = 0
        let price = 0
        let localStock = ''
        if(list.length > 0){
            list.map((item, index) => (
                qty = parseInt(parseInt(qty) + parseInt(item.qty)),
                price = parseInt(parseInt(price) + parseInt(item.price*item.qty))
            ));
        }
        setStockResume(localStock)
        setTotalQty(qty);
        setTotalPrice(price);
    }
    const handleLinkClick = (href) => {
        if(href){
            navigateIn(href);
        }
    }
    return (
        <>
            <div className="main-head main-head-users">
                
               
                
            </div>
            <div className="main-body">
            <div className="input-line">
                <Input 
                    label="libélé de l'inventaire"
                    disabled = {true} 
                    value={lib} 
                    onChange={setLib}
                    error={libError}
                />
                <Input 
                    label="Total" 
                    value={convertNumberToMoneyFormat(price)}
                    disabled = {true}
                    onChange={setPrice}
                    error={priceError}
                />
                
            </div>
            <div className="input-line">
                <Input 
                        label="Référence" 
                        value={numref} 
                        onChange={numref}
                        error={numrefError}
                    />
                <Input 
                    label="description" 
                    value={descrip} 
                    onChange={setDescrip}
                    error={descripError}
                />
                <Select selected={users} label="Témoin" onChange={e => setUsers(e.target.value)}>
                <option value="none" key="0">...</option>
                    {
                        listUsers.map(user => (
                            <option value={user.id} key={user.id}>{user.nom + ' ' + user.prenom}</option>
                        ))
                    }
                </Select>
            </div>
            {console.log(listEntries)}
            {
                listEntries.length > 0? 
                
                listEntries.map((item, index) => (
                    <div className="input-line">
                        <Input
                            label="Article"
                            disabled = {true}
                            value={item.lib} 
                            onChange={''}
                            error={false}
                        />
                        <Input
                            label="stock théorique"
                            disabled = {true}
                            value={item.theorikQty} 
                            onChange={''}
                            error={false}
                        />
                        <Input
                            label="stock réel"
                            disabled = {true}
                            value={item.theorikQty === item.qty && item.status === 0 ? '---' : item.qty}
                            type={"number"}
                            onChange={''}
                            error={false}
                        />
                         <Edit onClick={() => handleUpdateIconClick(item)} />
                    </div>
                    ))
                : <p>Vous n'avez aucun Article</p>
            }
                {getButton('save')}
                <Update 
                    className={`medium-modal ${updateForm ? '' : 'hide'}`} 
                    onClose={toggleUpdateForm}
                    item={itemToManage}
                    action={updateListEntries}
                />
                <NonedropModal
                    label="Action non autorisée"
                    onClose={toggleNoneModal}
                    className={showNonedropModal ? '' : 'hide'} 
                >
                    <p>Vous n'avez pas accès à ce type action</p>
                </NonedropModal>
                <NotifyModal
            label="Modification du stock"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>Le stock a bien été mmodifié !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la suppression du profile !</p>
        </NotifyModal>
            </div>
        </>
    );
}

export default NewInventory;