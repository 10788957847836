import {React, useRef} from "react";
import '../../styles/components/form/input.css';

/**
 * @author Don TG
 * @param {object} props    //{placeholder, type, label, value, onChange, error}
 * @returns 
 */
function Input(props){
    const inputRef = useRef('null');
    return (
        <div className="input">
            {
                props.label && 
                <label>{props.label}</label>
            }
            <div>
                <input 
                    type={props.type || 'text'} 
                    placeholder={props.placeholder} 
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    onKeyPress={props.onKeyPress}
                    disabled={props.disabled? true : false}
                    min={props.min || ''}
                    focus ={props.focus ? console.log(inputRef.current) : ''}
                    ref={props.ref}
                    autofocus={props.autofocus? "autoFocus" : ''}
                    onLoad={console.log(inputRef.current)}
                 />
                {
                    props.error && 
                    <label className="error-input">{props.error}</label>
                }
            </div>
        </div>
    );
}

export default Input;