/* eslint-disable */

import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import './add.css';
import {createThunk} from './usersSlice';
import {stringIsEmpty} from '../../utils/functions';
import Message from '../../components/Message';
import {fetchProfiles} from '../profiles/profilesAPI';
import NotifyModal from "../../components/NotifyModal"; //Notify modal

/**
 * @author Don TG
 * @param {object} props    //{className, onClose}
 * @returns 
 */
function Add(props){
    const [lastname, setLastname] = useState('');
    const [lastnameError, setLastnameError] = useState('');
    const [firstname, setFirstname] = useState('');
    const [firstnameError, setFirstnameError] = useState('');
    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirm, setConfirm] = useState('');
    const [confirmError, setConfirmError] = useState('');
    const [sex, setSex] = useState(1);
    const [compte, setCompte] = useState(0);
    const [naissance, setNaissance] = useState('');
    const [naissanceError, setNaissanceError] = useState('');
    const {loader, error} = useSelector(state => state.users.add);
    const [profiles, setProfiles] = useState([]);
    const dispatch = useDispatch();
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal

    useEffect(() => {
        fetchProfiles(0, 50)
        .then(response => {
            const {profileDatas} = response.data;
            if(profileDatas.length !== 0){
                setProfiles(profileDatas);
                setCompte(profileDatas[0].id);
            }
        })
        .catch(error => {
            console.log(error);
        });    
    }, []);

    useEffect(() => {
        if(!loader && firstname !== '' && error === undefined){
            clearForm();
            props.onClose();
        }
    }, [loader, error]);

    const clearForm = () => {
        setLastname('');
        setFirstname('');
        setAddress('');
        setEmail('');
        setPhone('');
        setPassword('');
        setConfirm('');
    }

    const controlInputs = () => {
        let valid = true;
        const localLastname = stringIsEmpty(lastname);
        const localAddress = stringIsEmpty(address);
        const localEmail = stringIsEmpty(email);
        const localPhone = stringIsEmpty(phone);
        const localFirstname = stringIsEmpty(firstname);
        const localPassword = stringIsEmpty(password);
        const localConfirm = stringIsEmpty(confirm);
        const localNaissance = stringIsEmpty(naissance);

        if(localFirstname === null){
            valid = false;
            setFirstnameError('Ce champs est obligatoire');
        }else{
            setFirstname(localFirstname);
            setFirstnameError('');
        }
        if(localNaissance === null){
            valid = false;
            setNaissanceError('Ce champs est obligatoire');
        }else{
            setNaissance(localNaissance);
            setNaissanceError('');
        }
        if(localLastname === null){
            valid = false;
            setLastnameError('Ce champs est obligatoire');
        }else{
            setLastname(localLastname);
            setLastnameError('');
        }
        if(localEmail === null){
            valid = false;
            setEmailError('Ce champs est obligatoire');
        }else{
            setEmail(localEmail);
            setEmailError('');
        }
        if(localPassword === null){
            valid = false;
            setPasswordError('Ce champs est obligatoire');
        }else{
            setPasswordError('');

            if(localConfirm === null){
                valid = false;
                setConfirmError('Ce champs est obligatoire');
            }else{
                if(localConfirm !== localPassword){
                    valid = false;
                    setConfirmError('Confirmation incorrecte');
                }else{
                    setPassword(localPassword);
                    setConfirm(localConfirm);
                    setConfirmError('');
                }
            }
        }

        return valid;
    }

    const handleAddClick = () => {
        if(controlInputs()){
            
            dispatch(createThunk({ 
                surname: firstname, 
                name: lastname, 
                tel: phone, 
                address, 
                mail: email, 
                login: email,
                password, 
                gender: sex,
                profile: compte,
                birthday: naissance,
                photo: 'hjgddfhsghdgfhdg',
                status: 2
            }));
            props.onClose;
            toggleNotifyModal();
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }
    return (
        <>
            <ModalForm 
            onClose={props.onClose} 
            onPrimaryActionClick={handleAddClick}
            className={props.className} 
            label="Nouvel utilisateur"
            loader={loader}
        >
            <div className="input-line">
                <Input 
                    label="Nom" 
                    value={lastname} 
                    onChange={setLastname}
                    error={lastnameError}
                />
                <Input 
                    label="Prénom" 
                    value={firstname} 
                    onChange={setFirstname}
                    error={firstnameError}
                />
            </div>
            <div className="input-line">
                <Input 
                    label="Adresse" 
                    value={address} 
                    onChange={setAddress}
                    error={addressError}
                />
                <Input 
                    label="Adresse électronique" 
                    value={email} 
                    onChange={setEmail}
                    error={emailError}
                />
            </div>
            <div className="input-line">
                <Input 
                    label="Numéro de téléphone" 
                    value={phone} 
                    onChange={setPhone}
                    error={phoneError}
                />
                <Select label="Sexe" selected={sex} onChange={(e) => setSex(e.target.value)}>
                    <option value="1">Masculin</option>
                    <option value="2">Féminin</option>
                </Select>
            </div>
            <div className="input-line">
                <Input 
                    label="Mot de passe" 
                    value={password} 
                    type="password"
                    onChange={setPassword}
                    error={passwordError}
                />
                <Input 
                    label="Confirmez mot de passe" 
                    type="password"
                    value={confirm} 
                    onChange={setConfirm}
                    error={confirmError}
                />
            </div>
            <div className="input-line">
                <Input 
                    label="Date de naissance" 
                    type="date"
                    value={naissance} 
                    onChange={setNaissance}
                    error={naissanceError}
                />
                <Select selected={compte} label="Compte" onChange={(e) => setCompte(e.target.value)}>
                    {
                        profiles.map(profile => (
                            <option key={profile.id} value={profile.id}>{profile.lib}</option>
                        ))  
                    }
                </Select>
            </div>
            <Message 
                className="error" 
                visible={error !== undefined} 
                message={error} 
            />
        </ModalForm>
        <NotifyModal
            label="Ajout de profile"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>L'utilisateur a été bien été crée !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la création du l'utilisateurs !</p>
        </NotifyModal>
        </>
        
    );
}

export default Add;