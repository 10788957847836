import axios from 'axios';
import {FEATURES_API_LIST_ROUTE, FEATURES_API_UPDATE_ROUTE} from '../../constants/routes';
import {convertObjectToURLSearchParams} from '../../utils/functions';

/**
 * @author Don TG
 * @param {int} profile_id
 * @returns
 */
export function fetchFeatures(profile_id){
    return axios.get(FEATURES_API_LIST_ROUTE, {params: {id: profile_id}});
}

/**
 * @author Don TG
 * @param {object} data 
 * @returns
 */
 export function updateFeature(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(FEATURES_API_UPDATE_ROUTE, params);
}