import axios from 'axios';
import { 
    INVOICEPAIMENTS_API_IVALIDATE_ROUTE,
    INVOICEPAIMENTS_API_LIST_ROUTE,
    INVOICEPAIMENTS_API_ADD_ROUTE,
    INVOICEPAIMENTS_API_FIND_ROUTE
} from '../../constants/routes';
import { convertObjectToURLSearchParams } from '../../utils/functions';

/**
 * @author Emerone Power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchPaimments(start, range){
    return axios.get(INVOICEPAIMENTS_API_LIST_ROUTE, {params: {start, range}});
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
 export function findPaiments(value, property){
    //const params = convertObjectToURLSearchParams(data);
    return axios.get(INVOICEPAIMENTS_API_FIND_ROUTE, {params: {value, property}});
    //return axios.get(INVOICES_API_FIND_ROUTE, params);
}
/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function addPaiment(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(INVOICEPAIMENTS_API_ADD_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function invalidatePaiment(id){
    const params = convertObjectToURLSearchParams({id});
    return axios.post(INVOICEPAIMENTS_API_IVALIDATE_ROUTE, params);
}