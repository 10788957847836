/* eslint-disable */
import {React, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import logo from '../assets/icons/logo.png';
import { ReactComponent as ArrowRight } from '../assets/icons/arrow-right.svg';
import { ReactComponent as Logout } from '../assets/icons/logout.svg';
import { ReactComponent as Person } from '../assets/icons/person.svg';
import { ReactComponent as Menu } from '../assets/icons/category.svg';
import { ReactComponent as Home } from '../assets/icons/home-page.svg';
import '../styles/components/header.css';
import {logout} from '../app/appSlice';
import NotifyModal from "./NotifyModal";
import HorizontalFlatNav from "./HorizontalFlatNav";
import { DASHBOARD_LIST_ROUTE } from "../constants/routes";

function Header() {
    const breadcrumb = useSelector(state => state.app.breadcrumb);
    const page = useSelector(state => state.app.page);
    const user = useSelector(state => state.app.user);
    const links = [];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal

    for(let i = 0; i < breadcrumb.length; i++){
        if(i != breadcrumb.length - 1){
            links.push(<a href="#" key={i}>{breadcrumb[i]}</a>);
            links.push(<ArrowRight />);
        }else{
            links.push(<a id="active-page" key={i}>{breadcrumb[i]}</a>);
        }
    }
    
    const handleLogoutClick = () => {
        dispatch(logout());
    }

    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    //navigate
    const handlNavigateClick = (href) => {
        if(href){
            navigate(href);
            return;
        }
    }
    return (
        <>
            <header>
            <div id="logo">
                <img src={"logo"} alt=" logo" />
                <h2></h2>
            </div>
            <div id="header-infos">
                <div id="breadcrumb">{links}</div>
                <div id="actions">
                    <div className="navigation">
                        <Home 
                                onClick={() => handlNavigateClick(DASHBOARD_LIST_ROUTE)}
                                className="icon-button"
                            />
                        {
                            page != DASHBOARD_LIST_ROUTE ?
                            <Menu 
                                onClick={toggleNotifyModal}
                                className="icon-button"
                            /> : ''
                        }
                        
                    </div>
                    <Logout 
                        onClick={handleLogoutClick}
                        className="icon-button"
                    />
                    <div>
                        <div id="profile-picture">
                            <Person />
                        </div>
                        <label>{`${user.nom} ${user.prenom}`}</label>
                    </div>
                </div>
            </div>
        </header>
        <div className="Modal-nav">
            <NotifyModal
                label={"Menu de navigation"}
                onClose={toggleNotifyModal}
                className={showNotifyModal ? 'large-modal' : 'hide'}
                notifyClassName={'modal-success'}
            >
                <HorizontalFlatNav/>
            </NotifyModal>
        </div>
        </>
    );
}

export default Header;