import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import {navigate, setBreadcrumb} from '../../app/appSlice';
import { convertNumberToMoneyFormat } from "../../utils/functions";
import {INVENTORY_LIST_ROUTE, INVENTORY_NEW_ROUTE} from '../../constants/routes';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as TableRows } from '../../assets/icons/table-row.svg';
import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import Select from "../../components/form/Select";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import Add from "./Add";
import Update from "./Update";
import "./inventory.css";
import NavLink from "../../components/NavLink";
import BackdropModal from "../../components/BackdropModal";
import NonedropModal from "../../components/NonedropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import {fetchInventory, deleteInventory, findInventory, findInvProduct, fetchInvProduct} from './InventoryAPI';


/**
 * @author Emerone Power
 * @returns 
 */
function Inventory(){
    const [addForm, setAddForm] = useState(false);
    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listUnit, setListUnit] = useState([]);
    const [stockResume, setStockResume] = useState('');
    const [inventory, setInventory] = useState(0);
    const [inventoryList, setInventoryList] = useState([]);
    const [category, setCategory] = useState("none");
    const [totalQty, setTotalQty] = useState(0);
    const [totalQtyCasier, setTotalQtyCasier] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceCasier, setTotalPriceCasier] = useState(0);
    const [pattern, setPattern] = useState('');
    const [updateForm, setUpdateForm] = useState(false);
    const [itemToManage, setItemToManage] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const dispatch = useDispatch();
    const navigateIn = useNavigate();
    const [params, setParams] = useState({});

    useEffect(() => {
        dispatch(navigate(INVENTORY_LIST_ROUTE));
        dispatch(setBreadcrumb(['Gestion des Inventaires']));
        //getTotalResume();
    }, []);

    useEffect(() => {
        //getTotalResume(list);
        getInventory();
        //console.log(inventory);
        //getInventoryDetails(inventory.id, "group");
        //setInventory(inventoryList[inventoryList.length-1])
        //getInventoryDetails(inventoryList[inventoryList.length-1]);
    }, []);

    useEffect(() => {
        //setInventory(inventoryList[0]);
        //console.log(inventoryList[0]);
    }, []);

    const getInventory = (value = "none") => {
        
        var respItem = null;
        if(value != "none" && value != "latest"){
            value = parseInt(value);
            findInventory(value, "id")
            .then(response => {
                const {inventoryDatas} = response.data;
                if (response.data.params && response.data.inventoryDatas) {
                    //setInventoryList(inventoryDatas);
                    //setParams(response.data.params);
                    setInventory(inventoryDatas[0]);
                    respItem = response.data.inventoryDatas[0];
                    console.log(inventoryDatas[0].id);
                    //getInventoryDetails(respItem.id);
                    //getTotalResume(inventoryDatas)
                }
            })
            .catch(error => {
                console.log(error);
            });
        }else{
            if(value === "none"){
                setInventoryList([]);
                fetchInventory(0,300).then(response => {
                    const {inventoryDatas} = response.data;
                    if (response.data.params && response.data.inventoryDatas) {
                        setInventoryList(inventoryDatas);
                        setParams(response.data.params);
                        setInventory(inventoryDatas[0]);
                        respItem = response.data.inventoryDatas[0];
                        //setInventory(inventoryDatas[0]);
                        console.log(respItem);
                        //getTotalResume(productDatas);
                        getInventoryDetails(respItem.id);
                    }else{
                        console.log(response.data);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            }
            if(value === "latest"){
                setInventoryList([]);
                findInventory(null, "latest").then(response => {
                    const {inventoryDatas} = response.data;
                    if (response.data.params && response.data.inventoryDatas) {
                        setInventoryList(inventoryDatas);
                        setParams(response.data.params);
                        respItem = response.data.inventoryDatas[0];
                        console.log(inventoryDatas);
                        //getTotalResume(productDatas);
                    }else{
                        console.log(response.data);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            }
        }
    }

    const getInventoryDetails = (value, property = "group") => {
        setList([]);
        if(value != "latest"){
            value = parseInt(value);
        }
        if(value != "none" && value != "latest"){
            value = parseInt(value);
            findInvProduct(value, property)
            .then(response => {
                const {inventProductDatas} = response.data;
                if (response.data.params && response.data.inventProductDatas) {
                    setList(inventProductDatas);
                    console.log(inventProductDatas);
                    //getTotalResume(inventoryDatas)
                }
            })
            .catch(error => {
                console.log(error);
            });
        }else{
            findInvProduct(value, "group")
            .then(response => {
                const {inventProductDatas} = response.data;
                if (response.data.params && response.data.inventProductDatas) {
                    setList(inventProductDatas);
                    //getTotalResume(inventoryDatas)   
                }else{
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        
    }


    const toggleAddForm = () => {
        setAddForm(!addForm);
    }

    const toggleUpdateForm = () => {
        setUpdateForm(!updateForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const handleUpdateIconClick = (item) => {
        setItemToManage(item);
        toggleUpdateForm();
    }

    const handleDeleteIconClick = (item) => {
        setItemToManage(item);
        toggleConfirmModal();
    }

    const deleteItem = () => {
        deleteInventory(itemToManage.id).then(response => {
            if(response.data.inventory?.params.state === 'successfull'){
                getInventory();
                toggleNotifyModal();
            }else{
                toggleNotifyModalError();
            }
        }).catch(error =>  {
            console.log(error);
        });
        
        toggleConfirmModal();
    }
    const handleChangeCategoryClick = (category) => {
        setCategory(category);
        getInventory(category);
        //console.log(category);
    }
    
    //None / info modal
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        setItemToManage(0);
        toggleNoneModal();
    }
    const assigments = useSelector((state) => state.app.userAssigments[3]);
    function getButton(type, item = null) {
        
        var toggleModal = handleNoneIconClic;
        if (type ==='add') {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                //toggleModal = toggleAddForm;
                toggleModal = () => handleLinkClick(INVENTORY_NEW_ROUTE);
            }
            return  <Button 
            label="Nouvel inventaire" 
            className="primary" 
            onClick={toggleModal}
            //onClick={toggleAddForm}
        />
        }
        if (type ==='view' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => navigateIn(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <TableRows onClick={() => toggleModal(item)} />
        }
        if (type ==='update' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleUpdateIconClick(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Edit onClick={() => toggleModal(item)} />
        }
        if (type ==='delete' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleDeleteIconClick(item)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <Delete onClick={toggleModal} />
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }
    const handleChangeInventoryClick = (item) => {
        console.log('item : '+item);
        getInventory(item);
        //setInventory(item);
        getInventoryDetails(item);
    }
    const handleNextClick = () => {
        if(params.end !== params.total){
            getInventory(params.end, params.end + 1);
        }
            
    }

    const handlePreviousClick = () => {
        if(params.start !== 1){
            getInventory(params.end - 1, params.start);
        }
            
    }

    function getTotalResume(list){
        let qty = 0
        let localqty = 0
        let price = 0
        let localStock = ''
        if(list.length > 0){
            list.map((item, index) => (
                qty = parseInt(parseInt(qty) + parseInt(item.qty)),
                price = parseInt(parseInt(price) + parseInt(item.price*item.qty))
            ));
        }
        setStockResume(localStock)
        setTotalQty(qty);
        setTotalPrice(price);
    }
    const handleLinkClick = (href) => {
        if(href){
            navigateIn(href);
        }
    }
    return (
        <>
            <div className="main-head main-head-users">
                {
                    inventoryList.length > 0 &&
                    <>
                        <div className="table-infos">
                            <label>{`${params.start+1} - ${params.end} sur ${params.total}`}</label>
                            <ArrowLeft 
                                className={params.start+1 === 1 ? 'disable' : undefined} 
                                onClick={handlePreviousClick} 
                            />
                            <ArrowRight 
                                className={params.end === params.total ? 'disable' : undefined}
                                onClick={handleNextClick} 
                            />
                        </div>
                        <Select selected={inventory.id} onChange={e => handleChangeInventoryClick(e.target.value)}>
                            <option value="none">Tout</option>
                                {
                                    inventoryList.map(option => (
                                        <option 
                                            value={option.id} 
                                            key={option.id}
                                        >
                                            {`${option.datereg.slice(0, 10)}`}
                                        </option>
                                    ))
                                }

                        </Select>
                        
                    </>
                }
                
                
                {getButton('add')}
                
            </div>
            {
                inventoryList.length > 0 ? 
                <div className="inventory-infos">
                    <ul>
                        <li><span>Date : </span>{inventory.id? inventory.datereg.slice(0,10) : 'aucun'}</li>
                        <li><span>Libélé : </span>{inventory.id? inventory.lib : 'aucun'}</li>
                        <li><span>Numéro de Reférence : </span>{inventory.id? inventory.numref : 'aucun'}</li>
                        <li><span>Description : </span>{inventory.id? inventory.descrip : 'aucun'}</li>
                        <li><span>Témoin : </span></li>
                        <li><span>Valeur total : </span>{inventory.id? inventory.price : 'aucun'}</li>
                    </ul>
                </div>
                : ''
            }
            <div className="main-body">
                {
                    list.length > 0 ?
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Article</th>
                                <th>Quantité</th>
                                <th>Valeur</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((item, index) => (
                                    <tr 
                                        key={item.id} 
                                        className={
                                            pattern !== '' ?
                                            !item.lib?.toLowerCase().includes(pattern.toLowerCase()) ? 'hide' : '' :
                                            ''
                                        }
                                    >
                                        <td>{index + 1}</td>
                                        <td>{item.productLib}</td>
                                        <td>{item.qty}</td>
                                        <td>{item.price}</td>
                                        <td className="table-actions">
                                            {/*<Edit onClick={() => handleUpdateIconClick(item)} />
                                            <Delete onClick={() => handleDeleteIconClick(item)} />*/}
                                            {/*getButton('view', item)*/}
                                            {/*getButton('update', item)*/}
                                            {/*getButton('delete', item)*/}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p>Vous n'avez aucun inventaire</p>
                }
                
                <Update 
                    className={`medium-modal ${updateForm ? '' : 'hide'}`} 
                    onClose={toggleUpdateForm}
                    item={itemToManage}
                    fetch={getInventory}
                />
                <BackdropModal
                    label="Confirmez cette action"
                    onClose={toggleConfirmModal}
                    onPrimaryActionClick={deleteItem}
                    className={showConfirmModal ? '' : 'hide'} 
                >
                    <p>Voulez vous vraiment supprimer ce profil ?</p>
                </BackdropModal>
                <NonedropModal
                    label="Action non autorisée"
                    onClose={toggleNoneModal}
                    className={showNonedropModal ? '' : 'hide'} 
                >
                    <p>Vous n'avez pas accès à ce type action</p>
                </NonedropModal>
                <NotifyModal
            label="Modification de profile"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>Le profile a bien été supprimé !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la suppression du profile !</p>
        </NotifyModal>
            </div>
        </>
    );
}

export default Inventory;