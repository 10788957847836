import axios from 'axios';
import { 
   INVOICESPARMS_API_LIST_ROUTE,
   INVOICESPARMS_API_UPDATE_ROUTE
} from '../../constants/routes';
import { convertObjectToURLSearchParams } from '../../utils/functions';

/**
 * @author Emerone Power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchInvoiceParams(start, range){
    return axios.get(INVOICESPARMS_API_LIST_ROUTE, {params: {start, range}});
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
 export function updateInvoiceParams(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(INVOICESPARMS_API_UPDATE_ROUTE, params);
}
