import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import {navigate, setBreadcrumb} from '../../app/appSlice';
import {deleteThunk} from './assignmentsSlice';
import {ASSIGNMENTS_LIST_ROUTE} from '../../constants/routes';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import Add from "./Add";
import BackdropModal from "../../components/BackdropModal";

/**
 * @author Don TG
 * @returns 
 */
function Assignments(){
    const [showForm, setShowForm] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [itemToManage, setItemToManage] = useState({});
    const dispatch = useDispatch();
    const {list, from, to, total} = useSelector(state => state.assignments);

    useEffect(() => {
        dispatch(navigate(ASSIGNMENTS_LIST_ROUTE));
        dispatch(setBreadcrumb(['Gestion des affectations']));
    }, [dispatch]);

    const handleShowFormClick = () => {
        setShowForm(!showForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const handleDeleteIconClick = (item) => {
        setItemToManage(item);
        toggleConfirmModal();
    }

    const deleteItem = () => {
        dispatch(deleteThunk(itemToManage.id_user, itemToManage.id_profile));
        toggleConfirmModal();
    }

    return (
        <>
            <div className="main-head main-head-users">
                {
                    list.length > 0 &&
                    <>
                        <div className="table-infos">
                            <label>{`${from} - ${to} sur ${total}`}</label>
                            <ArrowLeft className={from === 1 ? 'disable' : undefined} />
                            <ArrowRight className={to === total ? 'disable' : undefined} />
                        </div>
                        <Input placeholder="Rechercher" />
                    </>
                }
                <Button 
                    label="Affectation" 
                    className="primary" 
                    onClick={handleShowFormClick}
                />
            </div>
            <div className="main-body">
                {
                    list.length > 0 ?
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th>Utilisateur</th>
                                <th>Profil</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.user}</td>
                                        <td>{item.profile}</td>
                                        <td className="table-actions">
                                            <Edit className="hide" />
                                            <Delete onClick={() => handleDeleteIconClick(item)} />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p>Vous n'avez aucune affectation</p>
                }
                <Add 
                    className={`small-modal ${showForm ? '' : 'hide'}`} 
                    onClose={handleShowFormClick}
                />
                <BackdropModal
                    label="Confirmez cette action"
                    onClose={toggleConfirmModal}
                    onPrimaryActionClick={deleteItem}
                    className={showConfirmModal ? '' : 'hide'} 
                >
                    <p>Voulez vous vraiment supprimer cette affectation ?</p>
                </BackdropModal>
            </div>
        </>
    );
}

export default Assignments;