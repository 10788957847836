import React from "react";
import ModalForm from "../../components/ModalForm";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import { stringIsEmpty } from "../../utils/functions";
import NotifyModal from "../../components/NotifyModal";
import { updateCompanyInfos, fetchCompanyInfos } from "./CompagnyinfosAPI";
import { useState } from "react";
import { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';

/**
 * @author Emerone power
 * @param {object} props    //{className, onClose, item, fetch}
 * @returns 
 */
function Update(props){
    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(null);
    const [chiffaff, setChiffaff] = useState('');
    const [chiffaffError, setChiffaffError] = useState(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [lib, setLib] = useState('');
    const [libError, setLibError] = useState(null);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(null);
    const [pic, setPic] = useState('');
    const [picError, setPicError] = useState(null);
    const [regfisc, setRegfisc] = useState('');
    const [regfiscError, setRegfiscError] = useState(null);
    const [website, setWebsite] = useState('');
    const [websiteError, setWebsiteError] = useState(null);
    const [tva, setTva] = useState('NULL');
    const [tvaError, setTvaError] = useState(null);
    const [css, setCss] = useState('NULL');
    const [cssError, setCssError] = useState(null);
    const [tps, setTps] = useState('NULL');
    const [tpsError, setTpsError] = useState(null);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {
        
        if(Object.keys(props.item.infos[0]).length !== 0){
            setAddress(props.item.infos[0].address);
            setChiffaff(props.item.infos[0].chiffaff);
            setEmail(props.item.infos[0].email);
            setLib(props.item.infos[0].lib);
            setPhone(props.item.infos[0].phone);
            setPic(props.item.infos[0].pic);
            setRegfisc(props.item.infos[0].idregfisc);
            setWebsite(props.item.infos[0].website);
            setTva(props.item.infos[0].tva);
            setCss(props.item.infos[0].css);
            setTps(props.item.infos[0].tps);
            
        }
    }, [props.item]);

    const handleUpdateClick = () => {
        setLoader(true);
        const company = {
            id : props.item.infos[0].id,
            address : address,
            chiffaff : chiffaff,
            email : email,
            lib : lib,
            phone : phone,
            pic : pic,
            regfisc : regfisc,
            website : website
        }

        updateCompanyInfos(company).then(response => {
            setLoader(false);
            if(response.data.companyDatas.params.state === "successful"){
                props.onClose();
                props.fetch();
            }
        }).catch(error => {
            setLoader(false);
            console.log(error);
        });
    }

    return (
        <ModalForm onClose={props.onClose}
        onPrimaryActionClick={handleUpdateClick}
        className={props.className}
        label="Modifier les Informations"
        loader={loader}
        >
            <div className="input-line">
                <Input 
                    label="Nom"
                    value={lib}
                    onChange={setLib}
                    error={libError}
                 />
                <Input 
                    label="Téléphone"
                    value={phone}
                    onChange={setPhone}
                    error={phoneError}
                 />
            </div>
            <div className="input-line">
                <Input 
                    label="Adresse éledtronique"
                    value={email}
                    onChange={setEmail}
                    error={emailError}
                 />
                <Input 
                    label="Site web"
                    value={website}
                    onChange={setWebsite}
                    error={websiteError}
                 />
            </div>
            <div className="input-line">
                <Input 
                    label="Chiffre d'affaire"
                    value={chiffaff}
                    onChange={setChiffaff}
                    error={chiffaffError}
                 />
                <Input 
                    label="Adresse"
                    value={address}
                    onChange={setAddress}
                    error={addressError}
                 />
            </div>
            <div className="input-line">
                <Select
                    label="Régime fiscal"
                    Selected={regfisc}
                    onChange={(e) => setRegfisc(e.target.value)}
                >
                    {
                        props.item.regfisc.map(option => (
                            <option value={option.id} >{option.lib}</option>
                        ))
                    }
                </Select>
                
                
            </div>
            <div className="input-line">
                
                <Select
                    label="TVA"
                    Selected={tva}
                    onChange={(e) => setRegfisc(e.target.value)}
                >
                    <option value="1" >Oui</option>
                    <option value="NULL" >Non</option>
                </Select>
                <Select
                    label="CSS"
                    Selected={css}
                    onChange={(e) => setRegfisc(e.target.value)}
                >
                    <option value="1" >Oui</option>
                    <option value="NULL" >Non</option>
                </Select>
                <Select
                    label="TPS"
                    Selected={tps}
                    onChange={(e) => setRegfisc(e.target.value)}
                >
                    <option value="1" >Oui</option>
                    <option value="NULL" >Non</option>
                </Select>
                
            </div>
        </ModalForm>
    );
}

export default Update;