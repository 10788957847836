import React, {useState, useEffect} from 'react';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {stringIsEmpty} from '../../utils/functions';
import {updateClient, fetchClients} from './ClientsAPI';
import NotifyModal from "../../components/NotifyModal"; //Notify modal


/**
 * @author Don TG
 * @param {object} props    //{className, onClose, item, fetch}
 * @returns 
 */
function Update(props){
    const [lib, setLib] = useState('');
    const [libError, setLibError] = useState(null);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(null);
    const [nif, setNif] = useState('');
    const [nifError, setNifError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal


    useEffect(() => {
        if(Object.keys(props.item).length !== 0){
            setLib(props.item.lib);
            setPhone(props.item.phone);
            setCode(props.item.code);
            setEmail(props.item.mail);
            setNif(props.item.nif);
        }
    }, [props.item]);

    const clearForm = () => {
        setLib('');
        setCode('');
        setPhone('');
        setEmail('');
        setNif('');
    }

    const controlInputs = () => {
        let valid = true;
        const localLib = stringIsEmpty(lib);
        const localPhone =  stringIsEmpty(phone);
        const localCode = stringIsEmpty(code);
        const localEmail = stringIsEmpty(email);
        const localNif = stringIsEmpty(nif);

        if(localLib === null){
            valid = false;
            setLibError('Ce champ est obligatoire');
        }else{
            setLib(localLib);
            setLibError(null);
        }

        return valid;
    }

    const handleUpdateClick = () => {
        if(controlInputs()){
            updateClient({id: props.item.id, code: code, lib: lib, phone: phone, email: email, nif: nif}).then(response => {
                setLoader(false);
                console.log(response);
                if(response.data.params.state == 'successfull'){
                    clearForm();
                    props.onClose();
                    props.fetch();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
            }).catch(error => {
                setLoader(false);
                console.log(error);
            });
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    return (
        <>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Modifier les informations"
            onPrimaryActionClick={handleUpdateClick}
            loader={loader}
        >
            <div className="input-line">
                <Input 
                    label="Nom du client" 
                    value={lib} 
                    onChange={setLib}
                    error={libError}
                />
                <Input 
                    label="Tel" 
                    value={phone} 
                    onChange={setPhone}
                    error={phoneError}
                />
                
            </div>
            <div className="input-line">
                <Input 
                    label="Email" 
                    value={email !== null ? email : ''} 
                    onChange={setEmail}
                    error={emailError}
                />
                <Input 
                    label="NIF" 
                    value={nif !== null ? nif : ''} 
                    onChange={setNif}
                    error={nifError}
                />
            </div>
            
        </ModalForm>
        <NotifyModal
            label="Modification de l'article"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>Le profile a bien été modifié !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la modification du profile !</p>
        </NotifyModal>
        </>
        
    );
}

export default Update;