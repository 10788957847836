import React, {useState, useEffect} from 'react';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {fetchProducts} from '../Products/ProductsAPI';
import {addProductSupplies, fetchProductSupplies} from './ProductSuppliesAPI';
import {stringIsEmpty} from '../../utils/functions';
import NotifyModal from "../../components/NotifyModal"; //Notify modal

/**
 * @author Don TG
 * @param {object} props    //{className, onClose, fetch}
 * @returns 
 */

function Add(props){
    const [listProducts, setListProducts] = useState([]);
    const [lib, setlib] = useState('');
    const [libError, setLibError] = useState(null);
    const [price, setPrice] = useState('');
    const [priceError, setPriceError] = useState(null);
    const [product, setProduct] = useState(0);
    const [productError, setProductError] = useState(null);
    const [qty, setQty] = useState('');
    const [qtyError, setQtyError] = useState(null);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(null);
    const [datereg, setDatereg] = useState('');
    const [dateregError, setDateregError] = useState(null);
    const [numref, setNumref] = useState('');
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal

    
    useEffect(() => {
        setListProducts([]);
        fetchProducts(0, 50)
        .then(response => {
            const {productDatas} = response.data;
            if(response.data.productDatas){
                setListProducts(productDatas);
            }else{
                console.log(response.data)
            }
            
        })
        .catch(error => {
            console.log(error);
        });
        console.log(listProducts); 
    }, []);

    const clearForm = () => {
        setQty(1);
        setNumref('');
        setDatereg(getDate());
    }

    const controlInputs = () => {
        let valid = true;
        const localProd = stringIsEmpty(product);
        const localQty =  stringIsEmpty(qty);
        

        if(localProd === null){
            valid = false;
            setLibError('Ce champ est obligatoire');
        }else{
            setProduct(localProd);
            setProductError(null);
        }
        if(localQty === null){
            valid = false;
            setQtyError('Ce champ est obligatoire');
        }else{
            setQty(localQty);
            setQtyError(null);
        }

        return valid;
    }

    const handleAddClick = () => {
        setLoader(true);
        let responseType = false;
        if(controlInputs()){
            console.log({qty: qty, datereg: datereg, product: product, numref: numref, session: null});
            addProductSupplies({qty: qty, datereg: datereg, product: product, numref: numref, session: null}).then(response => {
                setLoader(false);
                console.log(response.data);
                if(response.data.params.state === 'successfull'){
                    clearForm();
                    props.onClose();
                    props.fetch();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
            }).catch(error => {
                setLoader(false);
            });
        }else
        setLoader(false);
        
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
    }
    function getDate(){
        let result = new Date();
        let month = parseInt(result.getMonth())+1;
        let year = result.getFullYear();
        let day = result.getDate();
        if (parseInt(month) < 10) {
            month = "0"+parseInt(parseInt(month)+1);
        }
        if (parseInt(day) < 10) {
            day = "0"+day;
        }
        
        return year+'-'+month+'-'+day;
    }

    return (<>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Nouvel article"
            onPrimaryActionClick={handleAddClick}
            loader={loader}
        >
            <div className="input-line">
                <Select selected={product} label="Artticle" onChange={(e) => setProduct(e.target.value)}>
                <option value="none" key="">...</option>
                    {
                        listProducts.map(prod => (
                            <option value={prod.id} key={prod.id}>{prod.lib}</option>
                        ))
                    }
                </Select>
                <Input 
                    label="Quantité" 
                    value={qty} 
                    onChange={setQty}
                    error={qtyError}
                />
            </div>
            <div className="input-line">
                <Input 
                        label="Référence" 
                        value={numref} 
                        onChange={setNumref}
                        error={''}
                    />
                <Input
                    type="date"
                    label="Date" 
                    value={datereg} 
                    onChange={setDatereg}
                    error={dateregError}
                />
            </div>
            
            
        </ModalForm>
        <NotifyModal
            label="Ajout article"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>L'article a été ajouté !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors l'ajout du profile !</p>
        </NotifyModal>
        </>
        
    );
}

export default Add;