import axios from 'axios';
import { 
    PRODUCTS_API_LIST_ROUTE,
    PRODUCTS_API_ADD_ROUTE,
    PRODUCTS_API_DELETE_ROUTE,
    PRODUCTS_API_UPDATE_ROUTE,
    PRODUCTTYPE_API_LIST_ROUTE,
    PRODUCTS_API_FIND_ROUTE,
} from '../../constants/routes';
import { convertObjectToURLSearchParams } from '../../utils/functions';

/**
 * @author Emerone Power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchProducts(start, range){
    return axios.get(PRODUCTS_API_LIST_ROUTE, {params: {start, range}});
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function addProduct(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(PRODUCTS_API_ADD_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
 export function updateProduct(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(PRODUCTS_API_UPDATE_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {int} id
 * @returns
 */
export function deleteProduct(id){
    const params = convertObjectToURLSearchParams({id});
    return axios.post(PRODUCTS_API_DELETE_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
 export function fetchProductTypes(start, range){
    return axios.get(PRODUCTTYPE_API_LIST_ROUTE, {params: {start, range}});
}
/**
 * @author Emerone Power
 * @param {int} id 
 * @returns
 */
 export function findProducts(value, property){
    return axios.get(PRODUCTS_API_FIND_ROUTE, {params: {value, property}});
}