import axios from 'axios';
import { 
    INVENTORY_API_ADD_ROUTE,
    INVENTORY_API_LIST_ROUTE,
    INVENTORY_API_UPDATE_ROUTE,
    INVENTORY_API_FIND_ROUTE,
    INVENTORY_API_DELETE_ROUTE,
    INVENTORYPRODUCT_API_ADD_ROUTE,
    INVENTORYPRODUCT_API_DELETE_ROUTE,
    INVENTORYPRODUCT_API_FIND_ROUTE,
    INVENTORYPRODUCT_API_LIST_ROUTE,
    INVENTORYPRODUCT_API_UPDATE_ROUTE
} from '../../constants/routes';
import { convertObjectToURLSearchParams } from '../../utils/functions';

/**
 * @author Emerone Power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchInventory(start, range){
    return axios.get(INVENTORY_API_LIST_ROUTE, {params: {start, range}});
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function findInventory(value, property){
    //const params = convertObjectToURLSearchParams(data);
    return axios.get(INVENTORY_API_FIND_ROUTE, {params: {value, property}});
    //return axios.get(INVOICES_API_FIND_ROUTE, params);
}
/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function addInventory(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(INVENTORY_API_ADD_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
 export function updateInventory(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(INVENTORY_API_UPDATE_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {int} id
 * @returns
 */
export function deleteInventory(id){
    const params = convertObjectToURLSearchParams({id});
    return axios.post(INVENTORY_API_DELETE_ROUTE, params);
}

//Inventory product
/**
 * @author Emerone Power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchInvProduct(start, range){
    return axios.get(INVENTORYPRODUCT_API_LIST_ROUTE, {params: {start, range}});
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function findInvProduct(value, property){
    //const params = convertObjectToURLSearchParams(data);
    return axios.get(INVENTORYPRODUCT_API_FIND_ROUTE, {params: {value, property}});
    //return axios.get(INVOICES_API_FIND_ROUTE, params);
}
/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function addInvProduct(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(INVENTORYPRODUCT_API_ADD_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
 export function updateInvProduct(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(INVENTORYPRODUCT_API_UPDATE_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {int} id
 * @returns
 */
export function deleteInvProduct(id){
    const params = convertObjectToURLSearchParams({id});
    return axios.post(INVENTORYPRODUCT_API_DELETE_ROUTE, params);
}