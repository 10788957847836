import { createSlice } from "@reduxjs/toolkit";
import {DEFAULT_LIST_ITEMS_NUMBER} from '../../constants/config';
import { fetchProfiles, addProfile, updateProfile, deleteProfile } from "./profilesAPI";

/**
 * @author Don TG
 */
 export const profilesSlice = createSlice({
    name: 'profiles',
    initialState: {
        list: [], // list of profiles in current page
        from: 1, // number left
        to: DEFAULT_LIST_ITEMS_NUMBER, // number right
        by: DEFAULT_LIST_ITEMS_NUMBER, // number of profiles per page
        total: null, // total of profiles in database,
        pattern: '', // input search text
    },
    reducers: {
        fetch: (state, action) => {
            state.list = action.payload.list;
            state.from = action.payload.from;
            state.to = action.payload.to;
            state.total = action.payload.total;
        },
        create: (state, action) => {
            state.list = [action.payload, ...state.list];
        },
        update: (state, action) => {
            state.list = state.list.map(profile => {
                if(profile.id === action.payload.id)
                    return action.payload;
                return profile;
            });
        },
        remove: (state, action) => {
            state.list = state.list.filter(profile => profile.id !== action.payload);
        }
    }
});

export const { fetch, create, update, remove } = profilesSlice.actions;

/**
 * First fetching.
 * 
 * @author Don TG
 * @returns 
 */
 export const firstFetchThunk = () => (dispatch, getState) => {
    const { by } = getState();

    fetchProfiles(0, by)
    .then(response => {
        const { profiles, total } = response.data; 
        const to = by > total ? total : by;
        dispatch(fetch({list: profiles, from: 1, to, total}));
    })
    .catch(error => {
        console.log(error);
    });    
}

/**
 * Add new profile.
 * 
 * @author Don TG
 * @param {object} data 
 * @returns 
 */
 export const createThunk = (data) => (dispatch, getState) => {
    dispatch(create(data));
    /*addProfile(data)
    .then(response => {
        data.id = response.data.id;
        dispatch(create(data));
    })
    .catch(error => {
        console.log(error);
    }); */
}

/**
 * Update profile data.
 * 
 * @author Don TG
 * @param {object} data 
 * @returns 
 */
 export const updateThunk = (data) => (dispatch, getState) => {
    dispatch(update(data));
    /*updateProfile(data)
    .then(response => {
        dispatch(update(data));
    })
    .catch(error => {
        console.log(error);
    }); */
}

/**
 * Delete profile.
 * 
 * @author Don TG
 * @param {int} id
 * @returns 
 */
export const deleteThunk = (id) => (dispatch, getState) => {
    dispatch(remove(id));
    /*deleteProfile(id)
    .then(response => {
        dispatch(remove(id));
    })
    .catch(error => {
        console.log(error);
    }); */
}

export default profilesSlice.reducer;