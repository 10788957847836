import React from "react";
import '../styles/components/buttonCircle.css';

/**
 * @author Emerone Power
 * @param {object} props    //{label,icon, className, onClick}
 * @returns 
 */
function Button(props){
    const Icon = props.icon;
    return (
        <button 
            onClick={props.onClick}
            className={`buttton-circle ${props.className}`}
        >  {props.label}</button>
    );
}

export default Button;