import React from "react";
import '../../styles/components/form/button.css';

/**
 * @author Don TG
 * @param {object} props    //{label, className, onClick}
 * @returns 
 */
function Button(props){
    return (
        <button 
            onClick={props.onClick}
            className={props.className}
        >{props.label}</button>
    );
}

export default Button;