import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertNumberToMoneyFormat } from "../../utils/functions";
import { navigate, setBreadcrumb } from "../../app/appSlice";
import { CASHREGISTER_PAD_ROUTE } from "../../constants/routes";
import { fetchProducts, fetchProductTypes, findProducts } from "../Products/ProductsAPI";
import { fetchCompanyInfos } from "../companyinfos/CompagnyinfosAPI";
import "./cash-register.css";
import BackdropModal from "../../components/BackdropModal";
import Button from "../../components/ButtonCircle";
import Input from "../../components/form/Input";
import Add from "./Add";
import AddCommand from "./AddCommand";
import ProductItem from "./ProductItem";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";

/**
 * @author Emerone Power
 * @returns 
 */
function CashRegister(){
    const dispatch = useDispatch();
    const user = useSelector(state => state.app.user);
    const [productList, setProductList] = useState([]);
    const [productTypeList, setProductTypeList] = useState([]);
    const [companyInfos, setCompanyInfos] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [unit, setUnit] = useState(0);
    const [myDate, setMyDate] = useState('');
    const [myTime, setMyTime] = useState('');
    const [itemToManage, setItemToManage] = useState({});
    const [rowToManage, setRowToManage] = useState(null);
    const [newRow, setNewRow] = useState([]);
    const [productStatus, setProductStatus] = useState([]);
    const [category, setCategory] = useState('none');
    const [addForm, setAddForm] = useState(false);
    const [pattern, setPattern] = useState('');
    const [AddCommandForm, setAddCommandForm] = useState(false);
    const [productRowForm, setProductRowForm] = useState(false);

    useEffect(() => {
        dispatch(navigate(CASHREGISTER_PAD_ROUTE));
        dispatch(setBreadcrumb(['Caisse']));
        getProducts();
        getProductTypes();
        getCompanyInfos();
        //setProductStatus(initProductStatus);
        console.log(document.getElementById("ele1"));
    }, []);

    useEffect(() => {
        setUnit(1);
        
    }, []);

    const getProducts = () =>{
        fetchProducts().then(
            response => {
                const {productDatas} = response.data;
                if(response.data.productDatas){
                    setProductList(productDatas);
                }else{
                    console.log(response.data);
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }
    const initProductStatus = () =>{
        let list = [];
        if(productList.length > 0){
            for (let i = 0; i < productList.length; i++) {
                list.push({item : productList[i].id, state: 1});    
            }
        }
        console.log(list);
        return list;
    }

    const getProductTypes = () =>{
        fetchProductTypes().then(
            response => {
                const {productTypeDatas} = response.data;
                setProductTypeList(productTypeDatas);
                if(response.data.productTypeDatas){
                    setProductTypeList(productTypeDatas);
                }else{
                    console.log(response.data);
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }
    const getCompanyInfos = () =>{
        fetchCompanyInfos(1).then(
            response => {
                const {companyDatas, companyActivities} = response.data;
                if (companyDatas.infos) {
                    setCompanyInfos(companyDatas);
                }else{
                    console.log(companyDatas);
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }

    const getProductsOfCategory = (category)=>{
        setProductList([]);
        if (category !== "none") {
            findProducts(category, "group").then( response => {
                const {productDatas} = response.data;
                if (response.data.productDatas) {
                    setProductList(productDatas);
                }else{
                    console.log(productDatas);
                }
                console.log(response.data);
            }).catch(error => {
                console.error(error);
            });
        }else{
            getProducts();
        }
    }

    

    const toggleAddForm = () => {
        setAddForm(!addForm);
    }
    const toggleAddCommandForm = () => {
        setAddCommandForm(!AddCommandForm);
    }
    const toggleProductRowForm = () => {
        setProductRowForm(!productRowForm);
    }
    const handleAddIconClick = () => {
        toggleAddForm();
    }
    const handleAddCommandIconClick = () => {
        toggleAddCommandForm();
    }

    const handleChangeCategoryClick = (item) =>{
        setCategory(item.lib);
        getProductsOfCategory(item.id);
    }
    const handleProductClick = (item, index = null) => {
        setItemToManage(item);
        setRowToManage(index);
        toggleProductRowForm();
    }

    const handleClearTableClick = () =>{
        setNewRow([]);
        setTotalPrice(0);
        setTotalDiscount(0);
        getProducts();
    }

    
    const updateTableRow = (item, qty=1, discount=0, unit=1, row=null, action="add" ) => {
        
        let total = (item.price*qty)-((item.price*qty*discount)/100);
        
        if(row === null){
            addRow({product: item.id, lib: item.lib, price: item.price, qty: qty, stock: item.qty, discount: discount, total: total, unit: unit});
            productList.splice(productList.indexOf(item), 1);
            //handleClearTableClick()
            //newRow.push({product: item.id, lib: item.lib, price: item.price, qty: qty, discount: discount, total: total, unit: unit});
        }else{
            if(action === "update"){
                updateRow(row, item.price, qty, discount, unit);
            }
            if(action === "delete"){
                deleteRow(row);
            }
            //handleClearTableClick()
            //console.log("Index: "+getRowIndex(item));
        }
        //newRow.push({product: item.id, lib: item.lib, price: item.price, qty: qty, discount: discount, total: total, unit: unit});
        //setTotalPrice(total+totalPrice);
        setTotalPrice(getTotalPrice());
        setTotalDiscount(getTotalDiscount());
        //setTotalDiscount(((item.price*qty)-total)+totalDiscount);
    }
    const addRow = (item) => {
        newRow.push(item);
        //setNewRow(newRow);
    }
    const deleteRow = (index) => {
        newRow.splice(index, 1);
        //console.log(newRow);
    }
    function updateRow(index, price, qty, discount, unit=1){
        
        //let index = -1;
        let total = (price*qty)-((price*qty*discount)/100);
        if(index <= newRow.length && index >= 0){
            newRow[index].qty = qty; 
            newRow[index].discount = discount; 
            newRow[index].price = price; 
            newRow[index].unit = unit; 
            newRow[index].total = total; 
        }
       
        return index;
    }
    function getRowIndex(item){
        let index = -1;
        for (let i = 0; i < newRow.length; i++) {
            const obj = newRow[i];
            if(obj.product === item.id){
                index = i;  
              }
        }
       
        return index;
    }
    function getTotalPrice(){
        let total =0;
        if (newRow.length >= 1){
            for (let i = 0; i < newRow.length; i++) {
                total += newRow[i].total;
            }
        }
        //setTotalPrice(total);
        return total;
    }
    function getTotalDiscount(){
        let total =0;
        if (newRow.length >= 1){
            for (let i = 0; i < newRow.length; i++) {
                total += ((newRow[i].price*newRow[i].qty*newRow[i].discount)/100);
            }
        }
        //setTotalPrice(total);
        return total;
    }

    function getDiscount(){
        let total =0;
        if (newRow.length > 1){
            newRow.map((item, index) => (
                total += ((item.product.price*1)*0)/100
            ));
        }
        setTotalPrice(total);
        return total;
    }

    function getDate(){
        let date = new Date().toString;
        setMyDate(date);
        //console.log(date);
        //return 'date';
    }
    const assigments = useSelector((state) => state.app.userAssigments[0]);
    return (
        <>
            <div className="cash-reg-console">
                <div className="console-left">
                    <div className="interface screen">
                        <h2>Caisse : { companyInfos.infos ? companyInfos.infos[0].lib : '' }</h2>
                    </div>
                    <div className="interface resume">
                        <div className="time">  
                            <span className="date">{  /*getDate()*/ }</span>
                            <span className="time">{  }</span>
                        </div>
                        <div className="user-infos">
                            <p id="discount"> Vendeur : <span> {user.nom + ' '+ user.prenom}</span></p>
                        </div>
                        <div className="prices">
                            <p id="discount"><span>Rémise : {convertNumberToMoneyFormat(totalDiscount)}</span></p>
                            <p id="paiment"><span>NAP : {convertNumberToMoneyFormat(totalPrice)}</span></p>
                        </div>
                    </div>
                    <div className="interface calculator">
                        calculator
                    </div>
                    <div className="interface table">
                        <table id="invoice-table">
                            <thead>
                                <th>N°</th>
                                <th>Article</th>
                                <th>PU</th>
                                <th>Qté</th>
                                <th>Remise</th>
                                <th>Prix</th>
                            </thead>
                            <tbody> 
                                {
                                    newRow.length > 0? newRow.map((item, index) => (
                                        <tr onClick={ () => handleProductClick(item, index) } >
                                            <td>{(index*1)+1}</td>
                                            <td>{ item.lib}</td>
                                            <td>{ convertNumberToMoneyFormat(item.price)}</td>
                                            <td >{ item.qty}</td>
                                            <td >{ item.discount + "%"}</td>
                                            <td>{ convertNumberToMoneyFormat(item.total)}</td>
                                        </tr>
                                    )) : ''
                                }
                            </tbody>
                        </table>
                    </div>

                <div className="interface options">
                    <div className="buttons">
                        <ul>
                            <li key={0} className="option-item"><Button label="Annuler" icon={Edit} className="primary" onClick={handleClearTableClick} /></li>
                            
                            {
                                assigments.manage === 1 ? 
                                <>
                                    <li key={1} className="option-item "><Button label="Facturer" icon={Edit} className={newRow.length == 0 ? "disabled": " primary"} onClick={toggleAddForm} /></li>
                                    <li key={2} className="option-item "><Button label="Commander" icon={Edit} className={newRow.length == 0 ? "disabled": " primary"} onClick={toggleAddCommandForm} /></li>
                                </> : ''
                            }
                        </ul>
                    </div>
                </div>
                </div>
                <div className="console-right">
                    <div className="interface list-category">
                        <h3>Catégorie</h3>
                        <div className="area">
                            <ul>
                                <li key={0} onClick={ () => handleChangeCategoryClick({id:"none", lib: "Tout"}) } ><div className="area-item">
                                    <span>Tout</span>
                                </div></li>
                                {
                                    productTypeList.length > 0 ? 
                                    productTypeList.map((item, index) => (
                                        <li key={index+1} onClick={ () => handleChangeCategoryClick(item)}><div className="area-item">
                                            <span>{item.lib}</span>
                                        </div>
                                    </li>
                                    ))
                                    : <p>aucune catégorie</p>
                                }
                                
                            </ul>
                        </div>
                    </div>
                    <div className="interface list-product">
                        <div className="list-header">
                            <h3 className="title">Articles ({productList.length}): <b> { category === "none" ? "*" : category }</b> </h3>
                            <div className="search-bar">
                                {
                                    productList.length > 0 &&
                                    <Input 
                                        placeholder="Rechercher" 
                                        value={pattern}
                                        onChange={setPattern}  
                                        autoFocus
                                        id="ele1"  
                                    />
                                }
                            </div>
                        </div>
                        
                        <div className="area">
                            <ul>
                                
                                {
                                    productList.length > 0 ? 
                                    productList.map((item, index) => (
                                        item.qty > 0 ? 
                                        <li key={index} className={newRow[getRowIndex(item.id)] > 0 ? "hidden" : "" ||
                                        pattern !== '' ?
                                            !item.lib?.toLowerCase().includes(pattern.toLowerCase()) ? 'hidden' : '' :
                                            '' } onClick={ () => handleProductClick({product: item, discount: 0, qty: 1, unit: unit}) } ><div className="area-item">
                                                <span>{item.lib}</span>
                                                </div>
                                            </li> : ""
                                    ))
                                    : <p>aucun article</p>
                                }
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Add 
                    className={`medium-modal ${addForm ? '' : 'hide'}`} 
                    onClose={toggleAddForm}
                    clear =  {handleClearTableClick}
                    rows={newRow}
                    item={{netprice : totalPrice, discount: totalDiscount}}
                    
                />
            <AddCommand 
                    className={`medium-modal ${AddCommandForm ? '' : 'hide'}`} 
                    onClose={toggleAddCommandForm}
                    clear =  {handleClearTableClick}
                    rows={newRow}
                    item={{netprice : totalPrice, discount: totalDiscount}}
                    
                />
            <ProductItem
                className={`small-modal ${productRowForm ? '' : 'hide'}`}
                onClose={toggleProductRowForm}
                row={rowToManage}
                item={itemToManage}
                action={updateTableRow}
            />
        </>
    );
}

export default CashRegister;