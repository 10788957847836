import axios from 'axios';
import { 
    CLIENTS_API_ADD_ROUTE,
    CLIENTS_API_DELETE_ROUTE,
    CLIENTS_API_LIST_ROUTE,
    CLIENTS_API_UPDATE_ROUTE
} from '../../constants/routes';
import { convertObjectToURLSearchParams } from '../../utils/functions';

/**
 * @author Emerone Power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchClients(start, range){
    return axios.get(CLIENTS_API_LIST_ROUTE, {params: {start, range}});
}
/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function findClients(value, property){
    //const params = convertObjectToURLSearchParams(data);
    return axios.get("COMMANDS_API_FIND_ROUTE", {params: {value, property}});
    //return axios.get(INVOICES_API_FIND_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function addClient(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(CLIENTS_API_ADD_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
 export function updateClient(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(CLIENTS_API_UPDATE_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {int} id
 * @returns
 */
export function deleteClient(id){
    const params = convertObjectToURLSearchParams({id});
    return axios.post(CLIENTS_API_DELETE_ROUTE, params);
}
