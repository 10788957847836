/**
 * Test if string is empty,
 * return null is empty or good string if not empty.
 * 
 * @author Don TG
 * @param {string} str
 * @returns
 */
export function stringIsEmpty(str){
    try{
        let goodStr = str.trim();
        if(goodStr === '') return null;
        return goodStr;
    }catch(e){
        return null;
    }
}

/**
 * Convert simple object to URLSearchParams object.
 * 
 * @author Don TG
 * @returns 
 */
export function convertObjectToURLSearchParams(obj){
    try{
        if(!obj || typeof obj !== 'object' || Object.keys(obj).length === 0)
            return null;

        const result = new URLSearchParams();

        for(let key in obj){
            result.append(key, obj[key]);
        }
        return result;
    }catch(e){
        return null;
    }
}

/**
 * Convert Numeric value to String Money format object.
 * 
 * @author Emerone Power
 * @returns 
 */
export function convertNumberToMoneyFormat(value){
    let result = value+"";
    let valueLenght = result.length;
    let format = "";
    format = result;
    if(valueLenght > 3){
        if (valueLenght === 4) {
            format = result.slice(0, 1) + " " + result.slice(1, 4);
        }
        if (valueLenght === 5) {
            format = result.slice(0, 2) + " " + result.slice(2, 5);
        }
        if (valueLenght === 6) {
            format = result.slice(0, 3) + " " + result.slice(3, 6);
        }
        if (valueLenght === 7) {
            format = result.slice(0, 1) + " " + result.slice(1, 4) + " " + result.slice(4, 7);
        }
        if (valueLenght === 8) {
            format = result.slice(0, 2) + " " + result.slice(2, 5) + " " + result.slice(5, 8);
        }
        if (valueLenght === 9) {
            format = result.slice(0, 3) + " " + result.slice(3, 6) + " " + result.slice(6, 9);
        }
        if (valueLenght === 10) {
            format = result.slice(0, 1) + " " + result.slice(1, 4) + " " + result.slice(4, 7) + " " + result.slice(7, 10);
        }
        
        
    }
    result = format+" FCFA";
    return result;
}