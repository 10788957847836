import axios from 'axios';
import { 
    INVOICES_API_ADD_ROUTE,
    INVOICES_API_DELETE_ROUTE,
    INVOICES_API_LIST_ROUTE,
    INVOICES_API_UPDATE_ROUTE,
    INVOICES_API_FIND_ROUTE,
    INVOICESPARMS_API_LIST_ROUTE,
    INVOICES_API_COUNT_ROUTE
} from '../../constants/routes';
import { convertObjectToURLSearchParams } from '../../utils/functions';

/**
 * @author Emerone Power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchInvoices(start, range){
    return axios.get(INVOICES_API_LIST_ROUTE, {params: {start, range}});
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function findInvoices(value, property){
    //const params = convertObjectToURLSearchParams(data);
    return axios.get(INVOICES_API_FIND_ROUTE, {params: {value, property}});
    //return axios.get(INVOICES_API_FIND_ROUTE, params);
}
/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function addInvoice(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(INVOICES_API_ADD_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
 export function updateInvoice(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(INVOICES_API_UPDATE_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {int} id
 * @returns
 */
export function deleteInvoice(id){
    const params = convertObjectToURLSearchParams({id});
    return axios.post(INVOICES_API_DELETE_ROUTE, params);
}


/**
 * @author Emerone Power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
 export function fetchInvoicesParams(start, range){
    return axios.get(INVOICESPARMS_API_LIST_ROUTE, {params: {start, range}});
}

/**
 * @author Emerone Power
 * @returns
 */
 export function invoicesCount(value, property){
    return axios.get(INVOICES_API_COUNT_ROUTE, {params: {value, property}});
}