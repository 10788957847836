import React, {useState, useEffect} from 'react';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {fetchProducts} from '../Products/ProductsAPI';
import {addProductTypes, fetchProductTypes} from './ProductTypesAPI';
import {stringIsEmpty} from '../../utils/functions';
import NotifyModal from "../../components/NotifyModal"; //Notify modal

/**
 * @author Don TG
 * @param {object} props    //{className, onClose, fetch}
 * @returns 
 */

function Add(props){
    const [lib, setlib] = useState('');
    const [libError, setLibError] = useState(null);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(null);
    const [descrip, setDescrip] = useState('');
    const [descripError, setDescripError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal

    
    useEffect(() => {
        
    }, []);

    const clearForm = () => {
        setlib('');
        setCode('');
        setDescrip('');
    }

    const controlInputs = () => {
        let valid = true;
        const localLib = stringIsEmpty(lib);
        const localDescrip =  stringIsEmpty(descrip);
        

        if(localLib === null){
            valid = false;
            setLibError('Ce champ est obligatoire');
        }else{
            setlib(localLib);
            setLibError(null);
        }

        return valid;
    }

    const handleAddClick = () => {
        setLoader(true);
        let responseType = false;
        if(controlInputs()){
            addProductTypes({lib: lib, code: null, descrip: descrip}).then(response => {
                setLoader(false);
                console.log(response.data);
                if(response.data.params.state === 'successfull'){
                    clearForm();
                    props.onClose();
                    props.fetch();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
            }).catch(error => {
                setLoader(false);
            });
        }else
        setLoader(false);
        
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
    }
    
    return (<>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Nouvelle catégorie"
            onPrimaryActionClick={handleAddClick}
            loader={loader}
        >
            <div className="input-line">
                
                <Input 
                    label="Nom" 
                    value={lib} 
                    onChange={setlib}
                    error={libError}
                />
                <Input 
                    label="Description" 
                    value={descrip} 
                    onChange={setDescrip}
                    error={descripError}
                />
            </div>
            <div className="input-line">
                
            </div>
            
            
        </ModalForm>
        <NotifyModal
            label="Ajout article"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>La catégorie a été ajouté !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors l'ajout de la catégorie !</p>
        </NotifyModal>
        </>
        
    );
}

export default Add;