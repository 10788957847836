import React from "react";
import '../styles/components/labelInfo.css';
/**
 * @author Emerone Power
 * @param {object} props        // {Label, value, icon}
 * @returns 
 */
function LabelInfo(props){
    const Icon = props.icon;
    return (
        <div className="label-infos">
            <Icon className="icon" />
            <label>{props.label}</label>
            <p>{props.value}</p>
        </div>
    );
}
export default LabelInfo;