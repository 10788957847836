import axios from 'axios';
import { 
    COMMANDS_API_ADD_ROUTE,
   COMMANDS_API_LIST_ROUTE,
   COMMANDS_API_FIND_ROUTE
} from '../../constants/routes';
import { convertObjectToURLSearchParams } from '../../utils/functions';

/**
 * @author Emerone Power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchCommands(start, range){
    return axios.get(COMMANDS_API_LIST_ROUTE, {params: {start, range}});
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
 export function findCommands(value, property){
    //const params = convertObjectToURLSearchParams(data);
    return axios.get(COMMANDS_API_FIND_ROUTE, {params: {value, property}});
    //return axios.get(INVOICES_API_FIND_ROUTE, params);
}
/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function addCommand(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(COMMANDS_API_ADD_ROUTE, params);
}