import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import {navigate, setBreadcrumb} from '../../app/appSlice';
import { convertNumberToMoneyFormat } from "../../utils/functions";
//import {remove} from './ProductsSlice';
import {INVOICES_PARAMS_ROUTE, INVOICES_LIST_ROUTE} from '../../constants/routes';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import Button from "../../components/form/Button";
import ButtonCircle from "../../components/ButtonCircle";
import Update from "./Update";
import './invoiceparams.css'
import BackdropModal from "../../components/BackdropModal";
import NonedropModal from "../../components/NonedropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import { fetchInvoiceParams} from './InvoiceparamsAPI';


/**
 * @author Emerone Power
 * @returns 
 */
function ProductTypes(){
    const [list, setList] = useState([]);
    const [updateForm, setUpdateForm] = useState(false);
    const [itemToManage, setItemToManage] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const dispatch = useDispatch();
    const navigateIn = useNavigate();

    useEffect(() => {
        dispatch(navigate(INVOICES_PARAMS_ROUTE));
        dispatch(setBreadcrumb(['Gestion des paramètre de facture']));
        getInvoiceParams();
    }, []);

    useEffect(() => {
        
    }, [list]);

    const getInvoiceParams = () => {
        setList([]);
        fetchInvoiceParams().then(response => {
                const {invParamDatas} = response.data;
                if (response.data.invParamDatas) {
                    setList(invParamDatas);
                }else{
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const toggleUpdateForm = () => {
        setUpdateForm(!updateForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const handleUpdateIconClick = (item) => {
        setItemToManage(item);
        toggleUpdateForm();
    }
    
    //None / info modal
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        setItemToManage(0);
        toggleNoneModal();
    }
    const assigments = useSelector((state) => state.app.userAssigments[3]);
    
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    return (
        <>
            <div className="main-head main-head-users">
                
            </div>
            <div className="main-body">
                {
                    list.length > 0 ?
                    <div className="invparams-area">
                        {
                                list.map((item, index) => (
                                            <div className="invparams-item">
                                                <span className="label">{item.lib} : </span>
                                                <span className="descrip">{item.descrip}</span>
                                                <span className="IconUpdateBtn" onClick={() => handleUpdateIconClick(item)} ><Edit /></span>
                                            </div>
                                ))
                            }
                    </div>
                    :
                    <p>Vous n'avez aucun paramètre</p>
                }
                
                <br />
                <ButtonCircle
                    label="Retour" 
                    className="buttton-circle-info" 
                    onClick={() => navigateIn(INVOICES_LIST_ROUTE)}/>
                <BackdropModal
                    label="Confirmez cette action"
                    onClose={toggleConfirmModal}
                    onPrimaryActionClick={''}
                    className={showConfirmModal ? '' : 'hide'} 
                >
                    <p>Voulez vous vraiment enregistré les changements ?</p>
                </BackdropModal>
                <NonedropModal
                    label="Action non autorisée"
                    onClose={toggleNoneModal}
                    className={showNonedropModal ? '' : 'hide'} 
                >
                    <p>Vous n'avez pas accès à ce type action</p>
                </NonedropModal>
                <Update 
                    className={`medium-modal ${updateForm ? '' : 'hide'}`} 
                    onClose={toggleUpdateForm}
                    item={itemToManage}
                    fetch={getInvoiceParams}
                />
                <NotifyModal
                    label="Modification de profile"
                    onClose={toggleNotifyModal}
                    className={showNotifyModal ? '' : 'hide'}
                    notifyClassName={'modal-success'}
                
                        >
                    <p>Les paramètres ont bien été modifiés !</p>
                </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la modification des paramètres !</p>
        </NotifyModal>
            </div>
        </>
    );
}

export default ProductTypes;