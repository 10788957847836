import React, {useEffect} from "react";
import { useDispatch } from 'react-redux'
import {navigate, setBreadcrumb} from '../../app/appSlice';
import {ACTIONS_LIST_ROUTE} from '../../constants/routes';
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";

/**
 * @author Don TG
 * @returns 
 */
function Actions(){
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(navigate(ACTIONS_LIST_ROUTE));
        dispatch(setBreadcrumb(['Gestion des actions système']));
    }, [dispatch]);

    return (
        <>
            <div className="main-head main-head-users">
                <Input placeholder="Rechercher" />
                <Button 
                    label="Enregistrer" 
                    className="primary" 
                />
            </div>
            <div className="main-body main-body-features">
                <table className="main-table">
                    <thead>
                        <tr>
                            <th>Action système</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Ajouter</td>
                            <td className="table-actions">
                                <input type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <td>Consulter</td>
                            <td className="table-actions">
                                <input type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <td>Modifier</td>
                            <td className="table-actions">
                                <input type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <td>Supprimer</td>
                            <td className="table-actions">
                                <input type="checkbox" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Actions;