// ROUTES

export const USERS_LIST_ROUTE = '/utilisateurs/liste';
export const PROFILES_LIST_ROUTE = '/utilisateurs/profils';
export const ASSIGNMENTS_LIST_ROUTE = '/utilisateurs/affectations';
export const FEATURES_LIST_ROUTE = '/utilisateurs/fonctionnalites';
export const ACTIONS_LIST_ROUTE = '/utilisateurs/actions';
export const ENTREPRISE_INFO_ROUTE = '/bibliotheque/entreprise';
export const PRODUCTS_LIST_ROUTE = '/products/liste';
export const PRODUCTTYPES_LIST_ROUTE = '/products/category/list';
export const MEASUREUNITS_LIST_ROUTE = '/products/measure-units/list';
export const PRODUCTSUPPLIES_LIST_ROUTE = '/products/supplies/liste';
export const CLIENTS_LIST_ROUTE = '/products/clients';
export const CASHREGISTER_PAD_ROUTE = '/caisse/console';
export const INVOICES_LIST_ROUTE = '/factures/list';
export const INVOICES_PARAMS_ROUTE = '/factures/params';
export const INVOICES_PAIMENTS_ROUTE = '/factures/paiements';
export const INVCOMMANDS_LIST_ROUTE = '/factures/commands';
export const COSNIGNATIONS_LIST_ROUTE = '/consignations/list';
export const INVENTORY_LIST_ROUTE = '/products/inventory/list';
export const INVENTORY_NEW_ROUTE = '/products/inventory/New';
export const DASHBOARD_LIST_ROUTE = '/home/dashboard';

// API ROUTES
/*
Le générale sorti un badge et se dirigea vers l'une des portes
entourées de peinture jaune. Il glissa le badge dans la fente
située à droite.La porte s'ouvrit 
*/
//const BASE_FRONTEND_URL = 'http://localhost/afup/ikoku/frontend/build';
const BASE_API_URL = 'http://localhost/afup/eedzo';
//const BASE_API_URL = 'http://ikoku.afup.net';
//const BASE_API_URL = 'https://eedzo.afup.net';
//const BASE_API_URL = 'http://powerison.rf.gd/ikoku';

export const USERS_API_LIST_ROUTE = BASE_API_URL + '/getUsers';
export const USERS_API_ADD_ROUTE = BASE_API_URL + '/addUser';
export const USERS_API_UPDATE_ROUTE = BASE_API_URL + '/modifyUser';
export const USERS_API_DELETE_ROUTE = BASE_API_URL + '/supprUser';
export const USERS_API_ACTIVATE_ROUTE = '/add';
export const PROFILES_API_LIST_ROUTE = BASE_API_URL + '/getProfiles';
export const PROFILES_API_ADD_ROUTE = BASE_API_URL + '/addProfile';
export const PROFILES_API_UPDATE_ROUTE = BASE_API_URL + '/modifyProfile';
export const PROFILES_API_DELETE_ROUTE = BASE_API_URL + '/supprProfile';
export const ASSIGNMENTS_API_LIST_ROUTE = '/users';
export const ASSIGNMENTS_API_ADD_ROUTE = '/add';
export const ASSIGNMENTS_API_UPDATE_ROUTE = '/add';
export const ASSIGNMENTS_API_DELETE_ROUTE = '/add';
export const FEATURES_API_LIST_ROUTE = BASE_API_URL + '/getAccess';
export const FEATURES_API_UPDATE_ROUTE = BASE_API_URL + '/modifyAccess';
export const AUTH_API_ROUTE = BASE_API_URL + '/auth';
export const ENTREPRISE_API_LIST_ROUTE = BASE_API_URL + '/getCompany';
export const ENTREPRISE_API_UPDATE_ROUTE = BASE_API_URL + '/modifyCompany';
export const ENTREPRISE_API_RESET_ROUTE = BASE_API_URL + '/resetCompany';
export const ENTREPRISEACTIVITIES_API_ADD_ROUTE = BASE_API_URL + '/addCompanyActivity';
export const ENTREPRISEACTIVITIES_API_DELETE_ROUTE = BASE_API_URL + '/supprCompanyActivity';
export const REGFISC_API_LIST_ROUTE = BASE_API_URL + '/getRegfisc';
export const PRODUCTS_API_LIST_ROUTE = BASE_API_URL + '/getProducts';
export const PRODUCTS_API_ADD_ROUTE = BASE_API_URL + '/addProduct';
export const PRODUCTS_API_UPDATE_ROUTE = BASE_API_URL + '/modifyProduct';
export const PRODUCTS_API_DELETE_ROUTE = BASE_API_URL + '/supprProducts';
export const PRODUCTS_API_FIND_ROUTE = BASE_API_URL + '/searchProducts';
export const PRODUCTTYPE_API_LIST_ROUTE = BASE_API_URL + '/getProdCategory';
export const PRODUCTTYPE_API_ADD_ROUTE = BASE_API_URL + '/addProdCategory';
export const PRODUCTTYPE_API_UPDATE_ROUTE = BASE_API_URL + '/modifyProdCategory';
export const PRODUCTTYPE_API_DELETE_ROUTE = BASE_API_URL + '/supprProdCategory';
export const PRODUCTTYPE_API_FIND_ROUTE = BASE_API_URL + '/searchProdCategory';
export const MEASUREUNIT_API_LIST_ROUTE = BASE_API_URL + '/getUnits';
export const MEASUREUNIT_API_ADD_ROUTE = BASE_API_URL + '/addUnit';
export const MEASUREUNIT_API_UPDATE_ROUTE = BASE_API_URL + '/modifyUnit';
export const MEASUREUNIT_API_DELETE_ROUTE = BASE_API_URL + '/supprUnit';
export const PRODUCTSUPPPLIES_API_LIST_ROUTE = BASE_API_URL + '/getProdSupplies';
export const PRODUCTSUPPPLIES_API_ADD_ROUTE = BASE_API_URL + '/addProdSupplies';
export const PRODUCTSUPPPLIES_API_DELETE_ROUTE = BASE_API_URL + '/supprProdSupplies';
export const PRODUCTSUPPPLIES_API_INVALIDATE_ROUTE = BASE_API_URL + '/invalidProdSupplies';
export const PRODUCTSUPPPLIES_API_FIND_ROUTE = BASE_API_URL + '/searchProdSupplies';
export const INVENTORY_API_LIST_ROUTE = BASE_API_URL + '/getInventory';
export const INVENTORY_API_ADD_ROUTE = BASE_API_URL + '/addInventory';
export const INVENTORY_API_FIND_ROUTE = BASE_API_URL + '/searchInventory';
export const INVENTORY_API_UPDATE_ROUTE = BASE_API_URL + '/modifyInventory';
export const INVENTORY_API_DELETE_ROUTE = BASE_API_URL + '/supprInventory';
export const INVENTORYPRODUCT_API_LIST_ROUTE = BASE_API_URL + '/getInventoryProduct';
export const INVENTORYPRODUCT_API_ADD_ROUTE = BASE_API_URL + '/addInventoryProduct';
export const INVENTORYPRODUCT_API_FIND_ROUTE = BASE_API_URL + '/searchInventoryProduct';
export const INVENTORYPRODUCT_API_UPDATE_ROUTE = BASE_API_URL + '/modifyInventoryProduct';
export const INVENTORYPRODUCT_API_DELETE_ROUTE = BASE_API_URL + '/supprInventoryProduct';
export const CLIENTS_API_LIST_ROUTE = BASE_API_URL + '/getClients';
export const CLIENTS_API_ADD_ROUTE = BASE_API_URL + '/addClient';
export const CLIENTS_API_UPDATE_ROUTE = BASE_API_URL + '/modifyClient';
export const CLIENTS_API_DELETE_ROUTE = BASE_API_URL + '/supprClient';
export const INVOICES_API_LIST_ROUTE = BASE_API_URL + '/getInvoices';
export const INVOICES_API_ADD_ROUTE = BASE_API_URL + '/addInvoice';
export const INVOICES_API_UPDATE_ROUTE = BASE_API_URL + '/modifyInvoice';
export const INVOICES_API_DELETE_ROUTE = BASE_API_URL + '/supprInvoice';
export const INVOICES_API_FIND_ROUTE = BASE_API_URL + '/searchInvoices';
export const INVOICES_API_COUNT_ROUTE = BASE_API_URL + '/getInvoicesCount';
//export const INVOICES_API_LIST_ROUTE = BASE_API_URL + '/getInvoicesCOmmands';
export const INVOICEPAIMENTS_API_LIST_ROUTE = BASE_API_URL + '/getPaiments';
export const INVOICEPAIMENTS_API_ADD_ROUTE = BASE_API_URL + '/addPaiment';
export const INVOICEPAIMENTS_API_IVALIDATE_ROUTE = BASE_API_URL + '/invalidPaiments';
export const INVOICEPAIMENTS_API_FIND_ROUTE = BASE_API_URL + '/searchPaiments';
export const INVOICESPARMS_API_LIST_ROUTE = BASE_API_URL + '/getInvoicesParams';
export const INVOICESPARMS_API_UPDATE_ROUTE = BASE_API_URL + '/modifyInvoicesParams';
export const CONSIGNATION_API_LIST_ROUTE = BASE_API_URL + '/getConsignations';
export const CONSIGNATION_API_FIND_ROUTE = BASE_API_URL + '/searchConsignations';
export const CONSIGNATION_API_ADD_ROUTE = BASE_API_URL + '/addConsignation';
export const CONSIGNATION_API_UPDATE_ROUTE = BASE_API_URL + '/modifyConsignation';
export const CONSIGNATION_API_DELETE_ROUTE = BASE_API_URL + '/SupprConsignation';
export const COMMANDS_API_LIST_ROUTE = BASE_API_URL + '/getCommand';
export const COMMANDS_API_ADD_ROUTE = BASE_API_URL + '/addCommand';
export const COMMANDS_API_FIND_ROUTE = BASE_API_URL + '/searchCommands';
//
export const MEDIA_API_URL = BASE_API_URL + '/assets';