import React, {useState, useEffect} from 'react';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import {stringIsEmpty} from '../../utils/functions';
import {updateProfile} from './profilesAPI';
import NotifyModal from "../../components/NotifyModal"; //Notify modal


/**
 * @author Don TG
 * @param {object} props    //{className, onClose, item, fetch}
 * @returns 
 */
function Update(props){
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal

    useEffect(() => {
        if(Object.keys(props.item).length !== 0){
            setName(props.item.lib);
        }
    }, [props.item]);

    const clearForm = () => {
        setName('');
    }

    const controlInputs = () => {
        let valid = true;
        const localName = stringIsEmpty(name);

        if(localName === null){
            valid = false;
            setNameError('Ce champs est obligatoire');
        }else{
            setName(localName);
            setNameError(null);
        }

        return valid;
    }

    const handleUpdateClick = () => {
        if(controlInputs()){
            updateProfile({id: props.item.id, lib: name}).then(response => {
                setLoader(false);
                if(response.data.profile?.params.state == 'successful'){
                    clearForm();
                    props.onClose();
                    props.fetch();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
            }).catch(error => {
                setLoader(false);
                console.log(error);
            });
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    return (
        <>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Modifier les informations"
            onPrimaryActionClick={handleUpdateClick}
            loader={loader}
        >
            <Input 
                label="Nom du profil" 
                value={name} 
                onChange={setName}
                error={nameError}
            />
        </ModalForm>
        <NotifyModal
            label="Modification de profile"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>Le profile a bien été modifié !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la modification du profile !</p>
        </NotifyModal>
        </>
        
    );
}

export default Update;