import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {navigate, setBreadcrumb} from '../../app/appSlice';
import {loading, fetchThunk} from './usersSlice';
import {deleteUser} from './usersAPI';
import {DASHBOARD_LIST_ROUTE, USERS_LIST_ROUTE} from '../../constants/routes';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import './users.css';
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import ButtonCircle from "../../components/ButtonCircle";
import Add from "./Add";
import Update from "./Update";
import NonedropModal from "../../components/NonedropModal";
import BackdropModal from "../../components/BackdropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import Loader from '../../components/Loader';

/**
 * @author Don TG
 * @returns 
 */
function Users(){
    const [showAddForm, setShowAddForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const [userToUpdate, setUserToUpdate] = useState({});
    const dispatch = useDispatch();
    const navigateIn = useNavigate();
    const {list, from, to, total, loader, by} = useSelector(state => state.users);


    useEffect(() => {
        dispatch(navigate(USERS_LIST_ROUTE));
        dispatch(setBreadcrumb(['Gestion des utilisateurs']));
        dispatch(fetchThunk());
    }, [dispatch]);

    const handleShowAddFormClick = () => {
        setShowAddForm(!showAddForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const handleShowUpdateFormClick = () => {
        setShowUpdateForm(!showUpdateForm);
    }

    const handleUpdatIconClick = (user) => {
        setUserToUpdate(user);
        handleShowUpdateFormClick();
    }

    const handleDeleteIconClick = (user) => {
        setUserToUpdate(user);
        toggleConfirmModal();
    }

    const deleteUserAPI = () => {
        dispatch(loading(true));
        deleteUser(userToUpdate.id).then(response => {
            if(response.data.user?.params.state === 'succesful'){
                dispatch(fetchThunk(from - 1));
                toggleNotifyModal();
            }else{
                dispatch(loading(false));
                toggleNotifyModalError();
            }
        }).catch(error =>  {
            console.log(error);
            dispatch(loading(false));
        });

        toggleConfirmModal();
    }

    /*const handleLockIconClick = (user) => {
        dispatch(active(user.id));
    }*/

    const handleNextClick = () => {
        if(to !== total)
            dispatch(fetchThunk(to));
    }

    const handlePreviousClick = () => {
        if(from !== 1)
            dispatch(fetchThunk(from - by - 1));
    }

    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }
    //
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        //setItemToManage(0);
        toggleNoneModal();
    }
    
    const assigments = useSelector((state) => state.app.userAssigments[5]);
    function getButton(type, item = null) {
    
        var toggleModal = handleNoneIconClic;
        if (type ==='add') {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = handleShowAddFormClick
            }
            return  <Button 
                label="Ajouter un utilisateur" 
                className="primary" 
                onClick={toggleModal}
            />
        }
        if (type ==='update' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleUpdatIconClick(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Edit onClick={() => toggleModal(item)} />
        }
        if (type ==='delete' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleDeleteIconClick(item)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <Delete onClick={toggleModal} />
        }
    }

    return (
        <>
            {
                loader ? 
                <div className="list-loader">
                    <Loader visible={loader} />
                </div>
                :
                <>
                    <div className="main-head main-head-users">
                        {
                            list.length > 0 &&
                            <>
                                <div className="table-infos">
                                    <label>{`${from} - ${to} sur ${total}`}</label>
                                    <ArrowLeft 
                                        className={from === 1 ? 'disable' : undefined} 
                                        onClick={handlePreviousClick} 
                                    />
                                    <ArrowRight 
                                        className={to === total ? 'disable' : undefined}
                                        onClick={handleNextClick} 
                                    />
                                </div>
                                <Input placeholder="Rechercher" />
                            </>
                        }
                        {getButton('add')}
                        {/*<Button 
                            label="Ajouter un utilisateur" 
                            className="primary" 
                            onClick={handleShowAddFormClick}
                    />*/}
                    </div>
                    <div className="main-body">
                        {
                            list.length > 0 ?
                            <table className="main-table">
                                <thead>
                                    <tr>
                                        <th>Num</th>
                                        <th>Nom et prénom</th>
                                        <th>Adresse</th>
                                        <th>Adresse électronique</th>
                                        <th>Compte</th>
                                        <th>Date de création</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        list.map((user, index) => (
                                            <tr key={user.id} className={user.Status !== 'Activé' ? 'disable' : undefined}>
                                                <td>{index + 1}</td>
                                                <td>{`${user.nom} ${user.prenom}`}</td>
                                                <td>{user.adresse}</td>
                                                <td>{user.email}</td>
                                                <td>{user.profil}</td>
                                                <td>{user.datcreat}</td>
                                                <td className="table-actions">
                                                    {getButton('update', user)}
                                                    {getButton('delete', user)}
                                                    {/*<Edit onClick={() => handleUpdatIconClick(user)} />
                                                    <Delete onClick={() => handleDeleteIconClick(user)} />*/}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            :
                            <p>Vous n'avez aucun utilisateur</p>
                        }
                        <ButtonCircle
                            label="Accueil" 
                            className="buttton-circle-info" 
                            onClick={() => navigateIn(DASHBOARD_LIST_ROUTE)}/>
                        <Add 
                            className={`medium-modal ${showAddForm ? '' : 'hide'}`} 
                            onClose={handleShowAddFormClick}
                        />
                        <Update 
                            className={`medium-modal ${showUpdateForm ? '' : 'hide'}`} 
                            onClose={handleShowUpdateFormClick}
                            user={userToUpdate}
                        />
                        <BackdropModal
                            label="Confirmez cette action"
                            onClose={toggleConfirmModal}
                            onPrimaryActionClick={deleteUserAPI}
                            className={showConfirmModal ? '' : 'hide'} 
                        >
                            <p>Voulez vous vraiment supprimer cet utilisateur ?</p>
                        </BackdropModal>
                        <NonedropModal
                            label="Action non autorisée"
                            onClose={toggleNoneModal}
                            className={showNonedropModal ? '' : 'hide'} 
                        >
                            <p>Vous n'avez pas accès à ce type action</p>
                        </NonedropModal>
                        <NotifyModal
                            label="Ajout de profile"
                            onClose={toggleNotifyModal}
                            className={showNotifyModal ? '' : 'hide'}
                            notifyClassName={'modal-success'}
                        
                        >
                            <p>L'utilisateur a été supprimé !</p>
                        </NotifyModal>
                        <NotifyModal
                            label="Erreur"
                            onClose={toggleNotifyModalError}
                            className={showNotifyModalError ? '' : 'hide'}
                            notifyClassName={'modal-danger'}
                        >
                            <p>Erreur lors l'a suppression du de l'utilisateur !</p>
                        </NotifyModal>
                    </div>
                </>
            }
        </>
    );
}

export default Users;