import axios from 'axios';
import { 
    USERS_API_LIST_ROUTE, 
    USERS_API_ADD_ROUTE ,
    USERS_API_UPDATE_ROUTE,
    USERS_API_DELETE_ROUTE,
    USERS_API_ACTIVATE_ROUTE
} from '../../constants/routes';
import { convertObjectToURLSearchParams } from '../../utils/functions';

/**
 * @author Don TG
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchUsers(start, range){
    return axios.get(USERS_API_LIST_ROUTE, {params: {start, range}});
}

/**
 * @author Don TG
 * @param {object} data 
 * @returns
 */
export function addUser(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(USERS_API_ADD_ROUTE, params);
}

/**
 * @author Don TG
 * @param {object} data 
 * @returns
 */
export function updateUser(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(USERS_API_UPDATE_ROUTE, params);
}

/**
 * @author Don TG
 * @param {int} id
 * @returns
 */
export function deleteUser(id){
    const params = convertObjectToURLSearchParams({id});
    return axios.post(USERS_API_DELETE_ROUTE, params);
}

/**
 * @author Don TG
 * @returns
 */
 export function activeAccountUser(){
    return axios.get(USERS_API_ACTIVATE_ROUTE);
}