import React from "react";
import { ReactComponent as Close } from '../assets/icons/close.svg';
import '../styles/components/modal-form.css';
import Button from "./form/Button";

/**
 * @author Don TG
 * @param {object} props    //{label, className, onClose, onPrimaryActionClick}
 * @returns 
 */
function BackdropModal(props){
    return (
        <div className={`backdrop ${props.className}`}>
            <div className={`modal-content modal-confirm`}>
                <div className="modal-content-head">
                    <label>{props.label}</label>
                    <Close onClick={props.onClose} />
                </div>
                <div className="modal-content-body">
                    {props.children}
                </div>
                <div className="modal-content-foot">
                    <Button 
                        label="Annuler"  
                        onClick={props.onClose}
                    />
                    <Button 
                        label="Confirmer" 
                        className="primary" 
                        onClick={props.onPrimaryActionClick}
                    />
                </div>
            </div>
        </div>
    );
}

export default BackdropModal;