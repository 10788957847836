import React, {useState, useEffect} from 'react';
import {convertNumberToMoneyFormat} from '../../utils/functions';
//import {Document, Page} from 'react-pdf';
//import { Document, Page, Text, View, StyleSheet } from '@react-18-pdf/renderer';
//import {html2canvas} from '../../pdf/html2canvas';
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {stringIsEmpty} from '../../utils/functions';
import {updateInvoice, fetchInvoices, fetchInvoicesParams} from './InvoicesAPI';
import {fetchCompanyInfos} from '../companyinfos/CompagnyinfosAPI';
import {findCommands} from '../commands/CommandsAPI';
import NotifyModal from "../../components/NotifyModal"; //Notify modal


/**
 * @author Emerone Power
 * @param {object} props    //{className, onClose, items, rows}
 * @returns 
 */
function Update(props){
    const [rows, setRows] = useState([]);
    const [items, setItems] = useState([]);
    const [client, setClient] = useState('');
    const [invParams, setInvParams] = useState([]);
    const [company, setCompany] = useState([]);
    const [totalQty, setTotalQty] = useState(0);
    const [clientError, setClientError] = useState(null);
    const [clientId, setClientId] = useState('');
    const [clientIdError, setClientIdError] = useState(null);
    const [clientList, setClientList] = useState([]);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(null);
    const [css, setCss] = useState('');
    const [cssError, setCssError] = useState(null);
    const [datelivr, setDatelivr] = useState('');
    const [datelivrError, setDatelivrError] = useState(null);
    const [datereg, setDatereg] = useState('');
    const [dateregError, setDateregError] = useState(null);
    const [dateupdate, setDateupdate] = useState('');
    const [dateupdateError, setDateupdateError] = useState(null);
    const [delay, setDelay] = useState('');
    const [delayError, setDelayError] = useState(null);
    const [htprice, setHtprice] = useState('');
    const [htpriceError, setHtpriceError] = useState(null);
    const [matricul, setMatricul] = useState('');
    const [matriculError, setMatriculError] = useState(null);
    const [netprice, setNetprice] = useState('');
    const [netpriceError, setNetpriceError] = useState(null);
    const [numpaie, setNumpaie] = useState('');
    const [numref, setNumref] = useState('');
    const [numrefError, setNumrefError] = useState(null);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(null);
    const [sessionid, setSessionid] = useState('');
    const [sessionidError, setSessionidError] = useState(null);
    const [sold, setSold] = useState(0);
    const [discount, setDiscount] = useState('');
    const [statusid, setStatusid] = useState(0);
    const [tps, setTps] = useState('');
    const [tva, setTva] = useState('');
    const [payment, setPayment] = useState(0);
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const [numPages, setNumpages] = useState(null); //Notify Modal
    const [pageNumber, setPageNumber] = useState(1); //
    const [viewFormat, setViewFormat] = useState('');


    useEffect(() => {
        if(Object.keys(props.items).length > 0){
            setItems(props.items);
            reset();
            if(props.items.client !== "none" && props.items.viewFormat){
                setViewFormat(props.items.viewFormat);
            }
            if(props.items.client === "none" && !props.items.viewFormat){
                setViewFormat("ticket");
            }
            //get commands rows
            findCommands(props.items.id, "invoice").then(response => {
                //
                const {commandDatas} = response.data;
                if(response.data.commandDatas){
                    setRows(commandDatas);
                    //getResume();
                }else{
                    console.log(response.data);
                }
            }).catch(error => {
                console.log()
            });
            //get company infos
            fetchCompanyInfos(1).then(response => {
                //
                const {companyDatas} = response.data;
                if(companyDatas.length !== 0){
                    setCompany(companyDatas);
                }
            }).catch(error => {
                console.log()
            });
            //get invoices Params infos
            fetchInvoicesParams().then(response => {
                const {invParamDatas} = response.data;
                if(invParamDatas.length !== 0){
                    setInvParams(invParamDatas);
                }
            }).catch(error => {
                console.log()
            });
        }

    }, [props.items]);

    useEffect(() => { 
        if (rows.length > 0) {
            getResume();
        }
    
    }, [rows]);

   const reset = () => {
        setRows([]);
        setTotalQty(0);
        setViewFormat('invoice');
   }

    const handleUpdateClick = () => {
        let newSold = parseInt(sold) + parseInt(payment);
            let newStatus = 1;
            if (newSold > 0) {
                setSold(newSold);
            }
            newSold === netprice? newStatus = 2 : newStatus = 1;

            updateInvoice({id: props.item.id, code: code, matricule: matricul, numref: numref, numpaie: numpaie, tva: tva, tps: tps, css: css, delay: delay, client: client, phone: phone, netprice: netprice, htprice: htprice, sold: newSold, status: newStatus, datelivr: datelivr, datereg: datereg}).then(response => {
                setLoader(false);
                if(response.data.params.state == 'successfull'){
                    props.onClose();
                    props.fetch();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
            }).catch(error => {
                setLoader(false);
                console.log(error);
            });
    }
    
    const handlePrintPDFClic = () => {
        setLoader(false);
    }

    const getResume = () => {
        let qty = 0;
        setTotalQty(qty);
        if(rows.length > 0){
            rows.forEach(element => {
                qty =  parseInt(qty) + parseInt(element.qty);
            });
        }
        setTotalQty(qty);
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }
    function onDocumentLoadSuccess ({numPages}){
        setNumpages(numPages);
    }
    //for pdf render

    function printDocument() {
        const input = document.getElementById('divToPrint');
        let format = "A4";
        if(viewFormat === "ticket"){
            format = "A6";
        }
        //console.log(document.querySelector("#divToPrint"));
       // pdf.save("download.pdf");
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const fileName = "AfupInvoice-facture-"+items.matricul+"pdf";
            const pdf = new jsPDF({
                format: format
            });
            pdf.addImage(imgData, 'JPEG', 10, 10);
            // pdf.output('dataurlnewwindow');
            pdf.save(fileName);
          });
      }
    

    return (
        <>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Aperçu de la facture"
            onPrimaryActionClick={printDocument}
            loader={loader}
        >
            <div className={`invoice-details ${viewFormat === "invoice" ? "view-invoice" : "view-ticket"}`} id='divToPrint'>
                <div className="invoice-header">
                    <div className="company-infos">
                        {
                            invParams.length > 0 ? 
                            <>
                                <h2>{ invParams[0].descrip }</h2>
                                <p>{ invParams[2].descrip }</p>
                                <p>{ invParams[3].descrip }</p>
                                <p>{ invParams[4].descrip }</p>
                                <p>{ invParams[5].descrip }</p>
                                <p>{ invParams[6].descrip }</p>
                            </>
                         : "..."
                        }
                        
                    </div>
                    {
                        viewFormat === "invoice" ? 
                            <div className="client-infos">
                                <p>{ items.client === "none" ? "Anonyme" : items.client }</p>
                                <p>{ items.phone }</p>
                                <p></p>
                            </div>
                         : ''
                    }
                    
                    <div className="ref-infos">
                        <table>
                            <thead>
                                <th>NUMERO</th>
                                <th>DATE</th>
                                {
                                    viewFormat === "invoice" ? 
                                    <th>REFERENCE</th>
                                    : ''
                                }
                            </thead>
                            <tbody>
                                {
                                    items.id  ? 
                                    <tr>
                                    <td>{ items.matricul }</td>
                                    <td>{ items.datereg.slice(0,10) }</td>
                                    {
                                        viewFormat === "invoice" ? 
                                        <td>{ items.numref }</td>
                                        : ''
                                    }
                                    
                                </tr>
                                 : "..."
                                }
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="invoice-body">
                <table id="invoice-table">
                            <thead>
                                <th className="th-number">N°</th>
                                <th>Article</th>
                                <th>PU</th>
                                <th className="th-number">Qté</th>
                                <th className="th-number">Remise</th>
                                <th>Prix</th>
                            </thead>
                            <tbody>
                                
                                {
                                    
                                    rows.length > 0? rows.map((item, index) => (
                                        <tr>
                                            <td>{(index*1)+1}</td>
                                            <td className="text-left">{ item.lib}</td>
                                            <td className='text-left'>{ convertNumberToMoneyFormat(item.price)}</td>
                                            <td>{ item.qty}</td>
                                            <td>{ item.discount}</td>
                                            <td className='text-left'>{ convertNumberToMoneyFormat((item.price*item.qty)-((item.price*item.qty*item.discount)/100))}</td>
                                            
                                        </tr>
                                    )) : 'Aucun produit'
                                }
                                
                            </tbody>
                            <tfoot>
                                <table id='footer-table'>
                                    <thead>
                                        <th>Total Casiers</th>
                                        <th>Total remise</th>
                                        <th>NET PAYER</th>
                                    </thead>
                                    <tbody>
                                        {
                                            rows.length >  0 ? rows.map((item, index) => (
                                                //setTotalQty(parseInt(totalQty + item.qty))
                                                ""
                                            )) : ""
                                        }
                                        {
                                            rows.length > 0  ?
                                            <tr>
                                                <td>{ totalQty }</td>
                                                <td>{ items.discount? convertNumberToMoneyFormat(items.discount) : 0 }</td>
                                                <td>{ convertNumberToMoneyFormat(items.netprice) }</td>
                                            </tr>
                                        : "..."
                                        }
                                        
                                    </tbody>
                                </table>
                            </tfoot>
                        </table>
                </div>
                <div className="invoice-footer">
                        
                </div>
            </div>
            <div className="input-line">
                <Select label="Format d'impression" value={viewFormat} onChange={(e) => {setViewFormat(e.target.value)} }>
                    <option value="invoice">Facture</option>
                    <option value="ticket" >Ticket</option>

                </Select>
            </div>
            
        </ModalForm>
        <NotifyModal
            label="Modification de l'article"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>Le  a bien été modifié !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la !</p>
        </NotifyModal>
        <div>
            
        </div>
        </>
        
    );
}

export default Update;