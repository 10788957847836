import axios from 'axios';
import { 
    CONSIGNATION_API_ADD_ROUTE,
    CONSIGNATION_API_DELETE_ROUTE,
    CONSIGNATION_API_FIND_ROUTE,
    CONSIGNATION_API_LIST_ROUTE,
    CONSIGNATION_API_UPDATE_ROUTE
} from '../../constants/routes';
import { convertObjectToURLSearchParams } from '../../utils/functions';

/**
 * @author Emerone Power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchconsignations(start, range){
    return axios.get(CONSIGNATION_API_LIST_ROUTE, {params: {start, range}});
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
 export function findConsignations(value, property){
    //const params = convertObjectToURLSearchParams(data);
    return axios.get(CONSIGNATION_API_FIND_ROUTE, {params: {value, property}});
    //return axios.get(INVOICES_API_FIND_ROUTE, params);
}
/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function addConsignation(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(CONSIGNATION_API_ADD_ROUTE, params);
}
/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function updateConsignation(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(CONSIGNATION_API_UPDATE_ROUTE, params);
}
/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function deleteConsignation(id){
    const params = convertObjectToURLSearchParams({id});
    return axios.post(CONSIGNATION_API_DELETE_ROUTE, params);
}