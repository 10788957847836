import React from "react";
import '../styles/components/message.css';

/**
 * @author Don TG
 * @param {object} props        // {className, visible, message}
 * @returns 
 */
function Message(props){
    return (
        <div className={`message ${props.className} ${props.visible ? '' : 'hide'}`}>
            <p>{props.message}</p>
        </div> 
    );
}

export default Message;