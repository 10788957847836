import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import {navigate, setBreadcrumb} from '../../app/appSlice';
import { useNavigate } from "react-router-dom";
import {convertNumberToMoneyFormat} from '../../utils/functions';
//import {remove} from './ProductsSlice';
import {INVOICES_LIST_ROUTE, INVOICES_PARAMS_ROUTE, INVOICES_PAIMENTS_ROUTE} from '../../constants/routes';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as MoneyCheck } from '../../assets/icons/checkmark-done2.svg';
import { ReactComponent as PaymentUpdate } from '../../assets/icons/money-check.svg';
import { ReactComponent as TableRows } from '../../assets/icons/table-row.svg';
import { ReactComponent as TableRows2 } from '../../assets/icons/table-freeze-row.svg';
import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import Add from "./Add";
import Update from "./Update";
import ViewDetails from "./ViewDetails";
import "./invoices.css";
import BackdropModal from "../../components/BackdropModal";
import NonedropModal from "../../components/NonedropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import {fetchInvoices, findInvoices, deleteInvoice} from './InvoicesAPI';
import {fetchClients} from '../Clients/ClientsAPI';
import UpdateTable from "./UpdatePayment";

/**
 * @author Emerone Power
 * @returns 
 */

function Invoices(){
    const [addForm, setAddForm] = useState(false);
    const [list, setList] = useState([]);
    const [client, setClient] = useState('');
    const [clientList, setClientList] = useState([]);
    const [totalSold, setTotalSold] = useState(0);
    const [totalSoldPrice, setTotalSoldPricce] = useState(0);
    const [apiresponse, setApiresponse] = useState({});
    const [pattern, setPattern] = useState('');
    const [updateForm, setUpdateForm] = useState(false);
    const [tableRowForm, setTableRowForm] = useState(false);
    const [invoiceVeiwDetails, setInvoiceVeiwDetails] = useState(false);
    const [itemToManage, setItemToManage] = useState({});
    const [showViewDetailsModal, setShowViewDetailsModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const [showTableModal, setShowTableModal] = useState(false); //Notify Modal
    const [showTableModalError, setShowTableModalError] = useState(false); //Notify Modal
    const dispatch = useDispatch();
    const navigateIn = useNavigate();
    const [params, setParams] = useState({});

    useEffect(() => {
        dispatch(navigate(INVOICES_LIST_ROUTE));
        dispatch(setBreadcrumb(['Gestion des factures']));
        getInvoices();
        getClients();
    }, []);

    const getInvoices = (start = 0, end = 25) => {
        fetchInvoices(start, end)
        .then(response => {
            const {invoiceDatas} = response.data;
            if (response.data.invoiceDatas) {
                setList(invoiceDatas);
                getTotalResume(invoiceDatas);
                if (response.data.params) {
                    setParams(response.data.params);
                }
            }else{
                setList([]);
            }
            setApiresponse(response.data);
        })
        .catch(error => {
            console.log(error);
        });    
    }
    const getClients = () => {
        fetchClients()
        .then(response => {
            const {clientDatas} = response.data;
            if(clientDatas.length !== 0){
                setClientList(clientDatas);
            }
        })
        .catch(error => {
            console.log(error);
        }); 
    }

    const toggleAddForm = () => {
        setAddForm(!addForm);
    }

    const toggleUpdateForm = () => {
        setUpdateForm(!updateForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }
    const toggleTableModal = () => {
        setTableRowForm(!tableRowForm);
    }

    const toggleViewDetailsModal = () => {
        setInvoiceVeiwDetails(!invoiceVeiwDetails);
    }

    const handleViewDetailsIconClick = (item) => {
        setItemToManage(item);
        toggleViewDetailsModal();
    }

    const handleUpdateIconClick = (item) => {
        setItemToManage(item);
        toggleUpdateForm();
    }

    const handleDeleteIconClick = (item) => {
        setItemToManage(item);
        toggleConfirmModal();
    }
    const handleTableEditIconClick = (item) => {
        setItemToManage(item);
        toggleTableModal();
    }

    const deleteItem = () => {
        deleteInvoice(itemToManage.id).then(response => {
            if(response.data.invoice?.params.state === 'successfull'){
                getInvoices();
                toggleNotifyModal();
            }else{
                toggleNotifyModalError();
            }
        }).catch(error =>  {
            console.log(error);
        });
        toggleConfirmModal();
    }
    
    //None / info modal
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        setItemToManage(0);
        toggleNoneModal();
    }

    const getInvoicesOfClient = (client) => {
        setList([]);
        if (client !== "none") {
            switch (client) {
                case "unknow":
                    findInvoices("none", "client").then(response => {
                        const {invoiceDatas} = response.data;
                        if (!response.data.error) {
                            setList(invoiceDatas);
                            getTotalResume(invoiceDatas);
                            if (response.data.params) {
                                setParams(response.data.params);
                            }
                        }else{
                            setList([]);
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                    break;
                case "paid":
                    findInvoices(2, "status").then(response => {
                        const {invoiceDatas} = response.data;
                        if (!response.data.error) {
                            setList(invoiceDatas);
                            getTotalResume(invoiceDatas);
                            if (response.data.params) {
                                setParams(response.data.params);
                            }
                            
                        }else{
                            setList([]);
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                    break;
                case "unpaid":
                    findInvoices(0, "sold").then(response => {
                        const {invoiceDatas} = response.data;
                        if (!response.data.error) {
                            setList(invoiceDatas);
                            getTotalResume(invoiceDatas);
                            if (response.data.params) {
                                setParams(response.data.params);
                            }
                        }else{
                            setList([]);
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                    break;
                case "halfpaid":
                    findInvoices(3, "status").then(response => {
                        const {invoiceDatas} = response.data;
                        if (!response.data.error) {
                            setList(invoiceDatas);
                            getTotalResume(invoiceDatas);
                            if (response.data.params) {
                                setParams(response.data.params);
                            }
                        }else{
                            setList([]);
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                    break;
                case "returned":
                    findInvoices(4, "status").then(response => {
                        const {invoiceDatas} = response.data;
                        if (!response.data.error) {
                            setList(invoiceDatas);
                            getTotalResume(invoiceDatas);
                            if (response.data.params) {
                                setParams(response.data.params);
                            }
                        }else{
                            setList([]);
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                    break;
            
                default:
                    findInvoices(client, "client").then(response => {
                        const {invoiceDatas} = response.data;
                        if (!response.data.error) {
                            setList(invoiceDatas);
                            getTotalResume(invoiceDatas);
                            if (response.data.params) {
                                setParams(response.data.params);
                            }
                        }else{
                            setList([]);
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                    break;
            }
        }else{
            getInvoices();
        }
    }

    const handleChangeClient = (client) => {
        setClient(client);
        getInvoicesOfClient(client);
    }

    const assigments = useSelector((state) => state.app.userAssigments[1]);
    function getButton(type, item = null) {
        
        var toggleModal = handleNoneIconClic;
        if (type ==='add') {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = toggleAddForm
            }
            return  <Button 
            label="Ajouter une facture" 
            className="primary" 
            onClick={toggleModal}
            //onClick={toggleAddForm}
        />
        }
        if (type ==='view' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleViewDetailsIconClick(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <TableRows onClick={() => toggleModal(item)} />
        }
        if (type ==='update' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleUpdateIconClick(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Edit onClick={() => toggleModal(item)} />
        }
        if (type ==='delete' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleDeleteIconClick(item)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <Delete onClick={toggleModal} />
        }
        if (type ==='payment' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleTableEditIconClick(item)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <PaymentUpdate onClick={toggleModal} />
        }
    }
    const handleNextClick = () => {
        if(params.end !== params.total){
            getInvoices(params.end, params.end + 25);
        }
            
    }

    const handlePreviousClick = () => {
        if(params.start !== 1){
            getInvoices(params.end - 25, params.start);
        }
            
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    const getSold = (netprice, sold) =>{
        return netprice - sold;
    }

    function getTotalResume(item){
        let qty = 0;
        let localqty = 0;
        let price = 0;
        if(item.length > 0){
            item.map((item, index) => (
                item.sold < item.netprice ? qty++ : '',
                item.sold < item.netprice ? price = parseInt(parseInt(price) + parseInt(item.netprice - item.sold)) : ''
            ));
        }
        setTotalSold(qty);
        setTotalSoldPricce((price));
    }

    return (
        <>
        <div className="main-head main-head-users">
            {
                list.length > 0 &&
                    <div className="table-infos">
                        <label>{`${params.start+1} - ${params.end} sur ${params.total}`}</label>
                        <ArrowLeft 
                            className={params.start+1 === 1 ? 'disable' : undefined} 
                            onClick={handlePreviousClick} 
                        />
                        <ArrowRight 
                            className={params.end === params.total ? 'disable' : undefined}
                            onClick={handleNextClick} 
                        />
                    </div>
            }
            <Select selected={client} onChange={e => handleChangeClient(e.target.value)}>
                <option value="none" selected>Tout</option>
                <option value="none" disabled>Clients</option>
                <option value="unknow">Anonyme</option>
                {
                    clientList.map(option => (
                        <option 
                            value={option.lib} 
                            key={option.lib}
                        >
                            {`${option.lib}`}
                        </option>
                    ))
                }

            </Select>
            
                {
                    list.length > 0 &&
                    <>
                    <Input 
                        placeholder="Rechercher" 
                        value={pattern}
                        onChange={setPattern}    
                    />

                    
                    </>
                }
            <Select selected={client} onChange={e => handleChangeClient(e.target.value)}>
                <option value="none">Tout</option>
                <option value="none" disabled selected>Statut</option>
                <option value="paid">Payée</option>
                <option value="halfpaid">En cours</option>
                <option value="unpaid">Non payée</option>
                <option value="returned">Retours</option>
            </Select>
                
            </div>
            <div className="main-body">
                <div className="options page-navlink">
                    <ul>
                        <li>
                            <div className="item" onClick={() => navigateIn(INVOICES_PARAMS_ROUTE)}>
                                <Profile />
                                <span className="label">Paramètres</span>
                            </div>
                        </li>
                        <li>
                            <div className="item" onClick={() => navigateIn(INVOICES_PAIMENTS_ROUTE)}>
                                <Profile />
                                <span className="label">Paiments</span>
                            </div>
                        </li>
                    </ul>
                    
                </div>
                <div className="data-stats">
                    {
                        list.length > 0 ?
                        
                        <ul>
                            <li className="data-item"> Nombre total impayé : <span className="item-value">{ totalSold }</span></li>
                            <li className="data-item"> Valeur  Total impayé: <span className="item-value">{ convertNumberToMoneyFormat(totalSoldPrice) }</span></li>
                        </ul> : ''
                    }
                </div>
                {
                    list.length > 0 ?
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Client</th>
                                <th>Date</th>
                                <th>Matricule</th>
                                <th>NAP</th>
                                <th>PMTS</th>
                                <th>Solde</th>
                                <th>Statut</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((item, index) => (
                                    item.statusId !== 6 ? 
                                        <tr 
                                            key={item.id} 
                                            className={
                                                pattern !== '' ?
                                                !item.matricul?.toLowerCase().includes(pattern.toLowerCase()) ? 'hide' : '' :
                                                ''
                                            }
                                        >
                                            <td>{index + 1}</td>
                                            <td>{item.client !== "none" && item.client !== "" ? item.client : "Anonyme"}</td>
                                            <td>{item.datereg.slice(0,11)}</td>
                                            <td>{item.matricul}</td>
                                            <td className="text-left">{convertNumberToMoneyFormat(item.netprice)}</td>
                                            <td>{item.sold === item.netprice ?  <MoneyCheck className="icon-success" /> : <b className="icon-danger">{item.sold !== 0? convertNumberToMoneyFormat(item.sold) : "--0--"}</b> }</td>
                                            <td>{item.sold ? convertNumberToMoneyFormat(item.netprice - item.sold) : "--0--"}</td>
                                            <td>{item.statusId ? item.sold === item.netprice ? "Payée" : item.sold < item.netprice && item.sold > 0 ? "En cours" : "Non Payée" : "Indéfini" }</td>
                                            
                                            <td className="table-actions">
                                                {/*<Edit onClick={() => handleUpdateIconClick(item)} />
                                                <Delete onClick={() => handleDeleteIconClick(item)} />*/}
                                                
                                                {
                                                    assigments.manage === 1 ? 
                                                    <>
                                                    {getButton('view', item)}
                                                    {getButton('payment', item)}
                                                    {getButton('update', item)}
                                                    {getButton('delete', item)}
                                                    </> : ''
                                                }
                                                
                                            </td>
                                        </tr>
                                    : ''
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p>Vous n'avez aucune Facture</p>
                }
                
                <Add 
                    className={`medium-modal ${addForm ? '' : 'hide'}`} 
                    onClose={toggleAddForm}
                    fetch={getInvoices}
                />
                <Update 
                    className={`medium-modal ${updateForm ? '' : 'hide'}`} 
                    onClose={toggleUpdateForm}
                    item={itemToManage}
                    fetch={getInvoices}
                />
                <UpdateTable 
                    className={`medium-modal ${tableRowForm ? '' : 'hide'}`} 
                    onClose={toggleTableModal}
                    item={itemToManage}
                    fetch={getInvoices}
                />
                <ViewDetails  
                    className={`medium-modal ${invoiceVeiwDetails ? '' : 'hide'}`}
                    onClose={toggleViewDetailsModal}
                    items={itemToManage}
                />
                <NotifyModal
            label="Modification de profile"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>Le profile a bien été supprimé !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la suppression du profile !</p>
        </NotifyModal>
        <BackdropModal
            label="Confirmez cette action"
            onClose={toggleConfirmModal}
            onPrimaryActionClick={deleteItem}
            className={showConfirmModal ? '' : 'hide'} 
        >
            <p>Voulez vous vraiment supprimer ce profil ?</p>
        </BackdropModal>
            </div>
        </>
    );
}
export default Invoices;