import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/logo.png';
import Input from '../../components/form/Input';
import Button from '../../components/form/Button';
import './login.css';
import {stringIsEmpty} from '../../utils/functions';
import {login} from '../../app/appSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import {auth} from './loginAPI';
import { DASHBOARD_LIST_ROUTE } from '../../constants/routes';

/**
 * @author Don TG
 * @returns 
 */
function Login(){
    const [email, setEmail] = useState('admin@gmail.com');
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [emailError, setEmailError] = useState(null);
    const [password, setPassword] = useState('man@22');
    const [passwordError, setPasswordError] = useState(null);
    const dispatch = useDispatch();
    const navigateIn = useNavigate();

    const controlInputs = () => {
        const localEmail = stringIsEmpty(email);
        let valid = true;

        if(localEmail === null){
            valid = false;
            setEmailError('Donnez votre adresse électronique');
        }else{
            setEmail(localEmail);
            setEmailError(null);
        }

        if(password === ''){
            valid = false;
            setPasswordError('Donnez votre mot de passe');
        }else{
            setPasswordError(null);
        }
        
        return valid;
    }

    const handleLoginClick = () => {
        if(controlInputs()){
            setError(false);
            setLoader(true);

            auth({login: email, password}).then(response => {
                const data = response.data;

                if(data.error){
                    setError(true);
                }else{
                    dispatch(login({user: data.userAccountData, userAssigments : data.assigments}));
                    navigateIn(DASHBOARD_LIST_ROUTE);
                }

                setLoader(false);
            }).catch(error => {
                console.log(error);
                setLoader(false);
                setError(true);
            });
        }
    }
    //Emerone power
    const handleClickEnter = (e) => {
        if(e.key==='Enter'){
            handleLoginClick();
        }
    }
    

    return (//à Ikoku
        <div id="login-wrap">
            
            <div id="login">
                <div className="logo">
                    <img src={''} alt=" logo" />
                </div>
                <h2>Connectez vous </h2>
                <Input 
                    label="Adresse électronique" 
                    value={email}
                    onChange={setEmail}
                    error={emailError}
                />
                <Input 
                    label="Mot de passe" 
                    type="password" 
                    value={password}
                    onChange={setPassword}
                    error={passwordError}
                    onKeyPress={handleClickEnter}
                />
                <Button 
                    label="Se connecter" 
                    className={`primary ${loader ? 'hide' : ''}`} 
                    onClick={handleLoginClick}
                    type="submit"
                />
                <Loader visible={loader} />
                <Message className="error" visible={error} message="Login ou mot de passe incorrect" />
            </div>
        </div>
    );
}

export default Login;