import React, {useState, useEffect} from 'react';
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import ViewDetails from '../invoices/ViewDetails';
import {stringIsEmpty} from '../../utils/functions';
import {addInvoice, fetchInvoicesParams, invoicesCount, findInvoices} from '../invoices/InvoicesAPI';
import {fetchClients} from '../Clients/ClientsAPI';
import {addCommand} from '../commands/CommandsAPI';
import { addConsignation } from '../consignations/ConsignationAPI';
import { addPaiment } from '../paiments/PaimentsAPI';
import NotifyModal from "../../components/NotifyModal"; //Notify modal


/**
 * @author Emerone Power
 * @param {object} props    //{className, onClose, item}
 * @returns 
 */
function Update(props){
    const [invParams, setInvParams] = useState([]);
    const [commandDatas, setCommandDatas] = useState([]);
    const [client, setClient] = useState('');
    const [clientError, setClientError] = useState(null);
    const [clientId, setClientId] = useState('');
    const [clientIdError, setClientIdError] = useState(null);
    const [clientList, setClientList] = useState([]);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(null);
    const [css, setCss] = useState('');
    const [cssError, setCssError] = useState(null);
    const [datelivr, setDatelivr] = useState('');
    const [datelivrError, setDatelivrError] = useState(null);
    const [datereg, setDatereg] = useState('');
    const [dateregError, setDateregError] = useState(null);
    const [dateupdate, setDateupdate] = useState('');
    const [dateupdateError, setDateupdateError] = useState(null);
    const [delay, setDelay] = useState('');
    const [delayError, setDelayError] = useState(null);
    const [htprice, setHtprice] = useState('');
    const [htpriceError, setHtpriceError] = useState(null);
    const [matricul, setMatricul] = useState('');
    const [matriculError, setMatriculError] = useState(null);
    const [netprice, setNetprice] = useState('');
    const [netpriceError, setNetpriceError] = useState(null);
    const [numpaie, setNumpaie] = useState('');
    const [numpaieError, setNumpaieError] = useState(null);
    const [numref, setNumref] = useState('');
    const [numrefError, setNumrefError] = useState(null);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(null);
    const [sessionid, setSessionid] = useState('');
    const [sessionidError, setSessionidError] = useState(null);
    const [sold, setSold] = useState(0);
    const [soldError, setSoldError] = useState(null);
    const [discount, setDiscount] = useState('');
    const [discountError, setDiscountError] = useState(null);
    const [statusid, setStatusid] = useState(0);
    const [statusidError, setStatusidError] = useState(null);
    const [tps, setTps] = useState('');
    const [tpsError, setTpsError] = useState(null);
    const [tva, setTva] = useState('');
    const [tvaError, setTvaError] = useState(null);
    const [payment, setPayment] = useState(0);
    const [consignation, setConsignation] = useState(0);
    const [isReturned, setIsReturned] = useState(0);
    const [itemToManage, setItemToManage] = useState({});
    const [invoiceVeiwDetails, setInvoiceVeiwDetails] = useState(false);
    const [viewFormat, setViewFormat] = useState('');
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal


    useEffect(() => {
        if(Object.keys(props.item).length !== 0){
            let myDate = new Date();
            setCommandDatas(props.rows);
            setNetprice(props.item.netprice);
            setDiscount(props.item.discount);
            setMatricul(getInvoicesMatricul());
            setSold(0);
            setPayment(0);
            setTps((props.item.netprice*10)/100 );
            setTva((props.item.netprice*8)/100);
            setCss((props.item.netprice*1)/100);
            setDatereg(getDate());
            setConsignation(0);
            setViewFormat("ticket");
            
        }
    }, [props.item]);

    useEffect(() => {
        fetchClients()
        .then(response => {
            const {clientDatas} = response.data;
            if(clientDatas.length !== 0){
                setClientList(clientDatas);
            }
        })
        .catch(error => {
            console.log(error);
        });

        getInvoicesParams();
        getInvoicesMatricul();
    }, []);
    //
    const getInvoicesMatricul = ()=>{
        let count = null;
        let isExist = false;
        let invMat = '';
        let date = getDate();
        //verify if exist
       //set matricul
       invoicesCount(date.substring(0,7), "datereg-like").then( response => {
           
            //default value
            if(invParams[7] && !response.data.invoiceCount){
                invMat = invParams[7].descrip
                invMat = invMat.replace(":i", '0001');
                invMat = invMat.replace(":m", date.substring(5,7));
                invMat = invMat.replace(":y", date.substring(2,4));
                setMatricul(invMat);
            }
            
            //new value
            const {invoiceCount} = response.data;
            if (response.data.invoiceCount) {
                count = invoiceCount+1;
                
                invMat = '';
                if(invParams[7]){
                    invMat = invParams[7].descrip
                }
                if(count < 10){
                    invMat = invMat.replace(":i", "000"+count);
                }
                if(count >=  10 && count < 100){
                    invMat = invMat.replace(":i", "00"+count);
                }
                if (count >=  100 && count < 1000) {
                    invMat = invMat.replace(":i", "0"+count);
                }
                if (count === 0){
                    count = 1;
                }
                
                invMat = invMat.replace(":i", count);
                invMat = invMat.replace(":m", date.substring(5,7));
                invMat = invMat.replace(":y", date.substring(2,4));
                setMatricul(invMat);
            }else{
                console.log(response.data);
            }
        }).catch(error => {
            console.error(error);
        });
        //setMatricul(invMat);
        return invMat;
    }
    const getInvoicesParams = ()=>{
        fetchInvoicesParams().then( response => {
            const {invParamDatas} = response.data;
            if (response.data.invParamDatas) {
                setInvParams(invParamDatas);
            }else{
                console.log(invParamDatas);
            }
            
        }).catch(error => {
            console.error(error);
        });
    }
    //
    const clearForm = () => {
        setClient('');
        setCode('');
        setCss('');
        setDatelivr('');
        setDatereg('');
        setDateupdate('');
        setDelay('');
        setHtprice('');
        setMatricul('');
        setNetprice('');
        setNumpaie('');
        setPhone('');
        setSold(0);
        setTps('');
        setTva('');
    }

    const controlInputs = () => {
        let valid = true;
        const localMatricul = stringIsEmpty(matricul);
        const localNumref = stringIsEmpty(numref);
    
        if(localMatricul === null){
            valid = false;
            setMatriculError('Ce champ est obligatoire');
        }else{
            setMatricul(localMatricul);
            setMatriculError(null);
        }
        if(isReturned){
            if(localNumref === null){
                valid = false;
                setNumrefError('Ce champ est obligatoire');
            }else{
                setNumref(localMatricul);
                setNumrefError(null);
            }
        }
        return valid;
    }

    const handleUpdateClick = () => {
        if(controlInputs() && props.rows.length > 0){
            if(props.rows.length > 0){
                let newSold = parseInt(sold) + parseInt(payment);
                let newStatus = 1;

                if (newSold > 0) {
                    setSold(newSold);
                }

                if(isReturned === 0){
                    newSold === netprice && newSold > 0? newStatus = 2 : newStatus = 3;
                    //newSold <= 0 ? newStatus = 1 : newSold = newSold;
                }else{
                    newStatus = 4;
                }
                newSold <= 0 ? newStatus = newStatus : newSold = newSold;
                //let newPhone = client !== 'none' && client !== '' ? getClientPhone(client) : '';
                getInvoicesMatricul();
                addInvoice({commands: props.rows, code: code, matricule: matricul, numref: numref, numpaie: numpaie, tva: tva, tps: tps, css: css, delay: delay, client: client, phone: phone, netprice: netprice, htprice: htprice, sold: newSold, discount: discount, status: newStatus, datelivr: datelivr, datereg: datereg}).then(response => {
                    setLoader(false);
                    
                    if(response.data.params.state == 'successfull'){
                        
                        //insert Paimment
                        if(payment > 0){
                            addPaiment({price: payment, datereg: datereg, invoice: response.data.invoice.id, session: null}).then(res => {
                                if (res.data.params.state == 'successfull') {
                                    
                                    
                                }
                            }).catch(error => {
                                setLoader(false);
                                console.log(error);
                            });
                        }
                        //insert consignation
                        if(consignation > 0){
                            addConsignation({qty: consignation, returned: 0, datereg: datereg, invoice: response.data.invoice.id}).then(res => {
                                if (res.data.params.state == 'successfull') {
                                    
                                }
                            }).catch(error => {
                                setLoader(false);
                                console.log(error);
                            })
                        }
                        //inisert commands
                        props.rows.map((item, index) => (
                            addCommand({product: item.product, price: item.price, qty: item.qty, discount: item.discount, invoice: response.data.invoice.id,unit: item.unit, invoiceStatus: isReturned}).then(res => {
                                
                                if (res.data.params.state == 'successfull' && props.rows.length == index+1) {
                                    loadInvoiceDetailsView({id: response.data.invoice.id, code: code, matricul: matricul, numref: numref, numpaie: numpaie, tva: tva, tps: tps, css: css, delay: delay, client: client, phone: phone, netprice: netprice, htprice: htprice, sold: newSold, discount: discount, status: newStatus, datelivr: datelivr, datereg: datereg, viewFormat: viewFormat});
                                    clearForm();
                                    props.clear();
                                    props.onClose();
                                    toggleNotifyModal();
                                }
                            }).catch(error => {
                                setLoader(false);
                                console.log(error);
                            })
                        ));
                        //clearForm();
                        //props.onClose();
                        //toggleNotifyModal();
                    }else{
                        toggleNotifyModalError();
                        console.log(response);
                    }
                    console.log(isReturned);
                }).catch(error => {
                    setLoader(false);
                    console.log(error);
                });
            }
            
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }
    const toggleViewDetailsModal = () => {
        setInvoiceVeiwDetails(!invoiceVeiwDetails);
    }
    function getDate(){
        let result = new Date();
        let month = parseInt(result.getMonth())+1;
        let year = result.getFullYear();
        let day = result.getDate();
        if (parseInt(month) < 10) {
            month = "0"+parseInt(parseInt(month));
        }
        if (parseInt(day) < 10) {
            day = "0"+day;
        }
        
        return year+'-'+month+'-'+day;
    }
    //get client pphone
    function getClientPhone(name){
        let result = null;
        clientList.forEach(item => {
            if(item.lib === name){
                result = item.phone;
            }
        });
        if(result === null){
            result = '';
        }
        return result;
    }
    //for pdf render

    function loadInvoiceDetailsView(item) {
        setItemToManage(item);
        setInvoiceVeiwDetails(!invoiceVeiwDetails);
        toggleViewDetailsModal();
      }

    return (
        <>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label={"NAP : "+netprice}
            onPrimaryActionClick={handleUpdateClick}
            loader={loader}
        >
            <div className="input-line">
                <Input 
                    label="Matricule" 
                    value={matricul} 
                    onChange={setMatricul}
                    error={matriculError}
                />
                <Input
                    label="Numéro de référence" 
                    value={numref} 
                    onChange={setNumref}
                    error={numrefError}
                />
                
            </div>
            
            <div className="input-line">
                <Select label="Nom du client" value={client} onChange={(e) => {setPhone(getClientPhone(e.target.value)); setClient(e.target.value)} }>
                    <option value="none" selected>...</option>
                    {
                        clientList.map(list => (
                            <option key={list.id} value={list.lib}>{list.lib} </option>
                        ))  
                    }
                </Select>

                <Input 
                    label="Tel" 
                    value={phone} 
                    onChange={setPhone}
                    error={phoneError}
                />
                
            </div>
            <div className="input-line">
                <Input
                    type="number"
                    label="Acompte / versement" 
                    value={payment !== null ? payment : 0} 
                    onChange={setPayment}
                    error={""}
                />
                <Input
                    disabled={true}
                    type="number" 
                    label="PMT" 
                    value={sold !== null ? parseInt(sold) + parseInt(payment) : 0} 
                    onChange={setSold}
                    error={soldError}
                />
            </div>
            <div className="input-line">
                
                <Input
                    type="date"
                    label="Date" 
                    value={datereg} 
                    onChange={setDatereg}
                    error={dateregError}
                />
            </div>
            <div className="input-line">
                
                <Input
                    type="number"
                    label="Consignation" 
                    value={consignation} 
                    onChange={setConsignation}
                    error={null}
                />
                <Select label="Retour" value={isReturned} onChange={(e) => {setIsReturned(e.target.value)} }>
                    <option value="0">Non</option>
                    <option value="1" >Oui</option>
                    
                </Select>
                
            </div>
            <div className="input-line">
                <Select label="Format d'impression" value={viewFormat} onChange={(e) => {setViewFormat(e.target.value)} }>
                    <option value="invoice">Facture</option>
                    <option value="ticket" >Ticket</option>
                    
                </Select>
                
            </div>
            <div className="input-line">
                <Input
                    disabled={true}
                    type="number"
                    label="TPS" 
                    value={tps !== null && tps !== 0 ? (netprice*10)/100 : 0} 
                    onChange={setTps}
                    error={tpsError}
                />
                <Input
                    disabled={true}
                    type="number" 
                    label="TVA" 
                    value={tva !== null && tva!==0 ? (netprice*8)/100 : 0} 
                    onChange={setTva}
                    error={tvaError}
                />
                <Input
                    disabled={true}
                    type="number" 
                    label="css" 
                    value={css !== null && css !== 0 ? (netprice*1)/100 : 0} 
                    onChange={setTva}
                    error={tvaError}
                />
            </div>
            
            <div className="interface table">
                        <table id="invoice-table">
                            <thead>
                                <th>N°</th>
                                <th>Article</th>
                                <th>PU</th>
                                <th>Qté</th>
                                <th>Remise</th>
                                <th>Prix</th>
                            </thead>
                            <tbody>
                                {   
                                    props.rows.length > 0? props.rows.map((item, index) => (
                                        <tr>
                                            <td>{(index*1)+1}</td>
                                            <td>{ item.lib}</td>
                                            <td>{ item.price}</td>
                                            <td >{ item.qty}</td>
                                            <td >{ item.discount}</td>
                                            <td>{ item.total}</td>
                                        </tr>
                                    )) : ''
                                }
                                {/*newRow? newRow.map((item, index) => (
                                    /*this.appendChild(document.createTextNode(item))
                                    //document.getElementById('invoice-table').appendChild(document.createTextNode(item))
                                     addTableRow()
                                )) : ''*/  }
                            </tbody>
                        </table>
                    </div>
        </ModalForm>
        <ViewDetails  
            className={`medium-modal ${invoiceVeiwDetails ? '' : 'hide'}`}
            onClose={toggleViewDetailsModal}
            items={itemToManage}
        />
        <NotifyModal
            label="Commande enregistrée"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>La facture a bien été Ajouté !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors de l'ajout de la facture !</p>
        </NotifyModal>
        </>
        
    );
}

export default Update;