import axios from "axios";
import {
    ENTREPRISE_API_UPDATE_ROUTE, 
    ENTREPRISE_API_LIST_ROUTE,
    REGFISC_API_LIST_ROUTE,
    ENTREPRISEACTIVITIES_API_ADD_ROUTE,
    ENTREPRISEACTIVITIES_API_DELETE_ROUTE
} from "../../constants/routes";
import { convertObjectToURLSearchParams } from '../../utils/functions';

/**
 * @author Emerone power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchCompanyInfos(id){
    return axios.get(ENTREPRISE_API_LIST_ROUTE)
}
/**
 * @author Emerone power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchCompanyRegfisc(){
    return axios.get(REGFISC_API_LIST_ROUTE)
}
/**
 * @author Emerone power
 * @param {object} data 
 * @returns
 */
export function updateCompanyInfos(data){
    const params  = convertObjectToURLSearchParams(data);
    return axios.post(ENTREPRISE_API_UPDATE_ROUTE, params);
}
/**
 * @author Emerone power
 * @param {object} data 
 * @returns
 */
export function addCompanyActivty(data){
    const params  = convertObjectToURLSearchParams(data);
    return axios.post(ENTREPRISEACTIVITIES_API_ADD_ROUTE, params);
}
/**
 * @author Emerone power
 * @param {object} data 
 * @returns
 */
export function deleteCompanyActivty(id){
    const params  = convertObjectToURLSearchParams({id});
    return axios.post(ENTREPRISEACTIVITIES_API_DELETE_ROUTE, params);
}