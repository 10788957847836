import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import {navigate, setBreadcrumb} from '../../app/appSlice';
import { convertNumberToMoneyFormat } from "../../utils/functions";
//import {remove} from './ProductsSlice';
import {PRODUCTS_LIST_ROUTE, MEASUREUNITS_LIST_ROUTE, PRODUCTTYPES_LIST_ROUTE} from '../../constants/routes';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import Select from "../../components/form/Select";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import Add from "./Add";
import Update from "./Update";
import "./products.css";
import NavLink from "../../components/NavLink";
import BackdropModal from "../../components/BackdropModal";
import NonedropModal from "../../components/NonedropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import {fetchProducts, deleteProduct, fetchProductTypes, findProducts} from './ProductsAPI.js';
import { fetchMeasureUnits } from '../measureUnits/MeasureUnitsAPI';


/**
 * @author Emerone Power
 * @returns 
 */
function Products(){
    const [addForm, setAddForm] = useState(false);
    const [list, setList] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listUnit, setListUnit] = useState([]);
    const [stockResume, setStockResume] = useState('');
    const [category, setCategory] = useState("none");
    const [totalQty, setTotalQty] = useState(0);
    const [totalQtyCasier, setTotalQtyCasier] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceCasier, setTotalPriceCasier] = useState(0);
    const [pattern, setPattern] = useState('');
    const [updateForm, setUpdateForm] = useState(false);
    const [itemToManage, setItemToManage] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const dispatch = useDispatch();
    const navigateIn = useNavigate();
    const [params, setParams] = useState({});

    useEffect(() => {
        dispatch(navigate(PRODUCTS_LIST_ROUTE));
        dispatch(setBreadcrumb(['Gestion des Articles']));
        getProducts();
        getCategory();
        getListUnits();
        //getTotalResume();
    }, []);

    useEffect(() => {
        getTotalResume(list);
    }, [list]);

    const getProducts = (value = "none") => {
        setList([]);
        if (value !== "none") {
            value = parseInt(value)
        }
        switch (value) {
            case "none":
                fetchProducts()
            .then(response => {
                const {productDatas} = response.data;
                if (response.data.params && response.data.productDatas) {
                    setList(productDatas);
                    setParams(response.data.params);
                    getTotalResume(productDatas);
                    getTotalCasier(productDatas);
                }else{
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
                break;
            case value:
                findProducts(value, "group")
            .then(response => {
                const {productDatas} = response.data;
                if (response.data.params && response.data.productDatas) {
                    setList(productDatas);
                    setParams(response.data.params);
                    getTotalResume(productDatas);
                    getTotalCasier(productDatas);
                }
            })
            .catch(error => {
                console.log(error);
            });
                break;
        
            default:
                fetchProducts()
            .then(response => {
                const {productDatas} = response.data;
                if (response.data.params && response.data.productDatas) {
                    setList(productDatas);
                    setParams(response.data.params);
                    getTotalResume(productDatas);
                    getTotalCasier(productDatas);
                    
                }else{
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
                break;
        }
    }

    const getCategory = () => {
        setListCategory([]);
        fetchProductTypes()
        .then(response => {
            const {productTypeDatas} = response.data;
            //setListCategory(productTypeDatas);
            if ( response.data.productTypeDatas) {
                setListCategory(productTypeDatas);
            }else{
                console.log(response.data);
            }
        })
        .catch(error => {
            console.log(error);
        }); 
          
    }

    const getListUnits = () => {
        setListUnit([]);
        fetchMeasureUnits().then(response => {
            const {unitDatas} = response.data;
            if (response.data.unitDatas) {
                setListUnit(unitDatas);
            }else{
                console.log(response.data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const toggleAddForm = () => {
        setAddForm(!addForm);
    }

    const toggleUpdateForm = () => {
        setUpdateForm(!updateForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const handleUpdateIconClick = (item) => {
        setItemToManage(item);
        toggleUpdateForm();
    }

    const handleDeleteIconClick = (item) => {
        setItemToManage(item);
        toggleConfirmModal();
    }

    const deleteItem = () => {
        deleteProduct(itemToManage.id).then(response => {
            if(response.data.product?.params.state === 'successfull'){
                getProducts();
                toggleNotifyModal();
            }else{
                toggleNotifyModalError();
            }
        }).catch(error =>  {
            console.log(error);
        });
        
        toggleConfirmModal();
    }
    const handleChangeCategoryClick = (category) => {
        setCategory(category);
        getProducts(category);
        //console.log(category);
    }
    
    //None / info modal
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        setItemToManage(0);
        toggleNoneModal();
    }
    const assigments = useSelector((state) => state.app.userAssigments[3]);
    function getButton(type, item = null) {
        
        var toggleModal = handleNoneIconClic;
        if (type ==='add') {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = toggleAddForm
            }
            return  <Button 
            label="Ajouter un article" 
            className="primary" 
            onClick={toggleModal}
            //onClick={toggleAddForm}
        />
        }
        if (type ==='update' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleUpdateIconClick(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Edit onClick={() => toggleModal(item)} />
        }
        if (type ==='delete' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleDeleteIconClick(item)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <Delete onClick={toggleModal} />
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    const handleNextClick = () => {
        if(params.end !== params.total){
            getProducts(params.end, params.end + 25);
        }
            
    }

    const handlePreviousClick = () => {
        if(params.start !== 1){
            getProducts(params.end - 25, params.start);
        }
            
    }

    function getTotalResume(items){
        let qty = 0
        let localqty = 0
        let price = 0
        let localStock = ''
        if(items.length > 0){
            items.map((item, index) => (
                qty = parseInt(parseInt(qty) + parseInt(item.qty)),
                price = parseInt(parseInt(price) + parseInt(item.price*item.qty))
            ));
        }
        setStockResume(localStock)
        setTotalQty(qty);
        setTotalPrice(price);
        //getTotalCasier(list);
    }
    function getTotalCasier(items){
        let qty = 0
        let localqty = 0
        let price = 0
        let localStock = ''
        if(items.length > 0){
            items.map((item, index) => (
                item.unit === 3 ?  qty = parseInt(parseInt(qty) + parseInt(item.qty)) : '',
                item.unit === 3 ?  price = parseInt(parseInt(price) + parseInt(item.price*item.qty)) : ''
                //qty = parseInt(parseInt(qty) + parseInt(item.qty)),
                //price = parseInt(parseInt(price) + parseInt(item.price*item.qty))
            ));
        }
        //setStockResume(localStock)
        setTotalQtyCasier(qty);
        setTotalPriceCasier(price);
    }
    const handleLinkClick = (href) => {
        if(href){
            navigateIn(href);
        }
    }
    return (
        <>
            <div className="main-head main-head-users">
                {
                    list.length > 0 &&
                    <>
                        <div className="table-infos">
                            <label>{`${params.start+1} - ${params.end} sur ${params.total}`}</label>
                            <ArrowLeft 
                                className={params.start+1 === 1 ? 'disable' : undefined} 
                                onClick={handlePreviousClick} 
                            />
                            <ArrowRight 
                                className={params.end === params.total ? 'disable' : undefined}
                                onClick={handleNextClick} 
                            />
                        </div>
                        <Select selected={category} onChange={e => handleChangeCategoryClick(e.target.value)}>
                            <option value="none" selected>Tout</option>
                                {
                                    listCategory.map(option => (
                                        <option 
                                            value={option.id} 
                                            key={option.id}
                                        >
                                            {`${option.lib}`}
                                        </option>
                                    ))
                                }

                        </Select>
                        
                        {
                            list.length > 0 &&
                            <Input 
                                placeholder="Rechercher" 
                                value={pattern}
                                onChange={setPattern}    
                            />
                        }
                        {/*<Button 
                            label="Ajouter un profil" 
                            className="primary" 
                            onClick={toggleAddForm}
                        />*/}
                        {getButton('add')}
                    </>
                }
                
            </div>
            <div className="main-body">
                <div className="options page-navlink">
                    <ul>
                        <li>
                            <div className="item" onClick={() => navigateIn(MEASUREUNITS_LIST_ROUTE)}>
                                <Profile />
                                <span className="label">Unités de mesures</span>
                            </div>
                        </li>
                        <li>
                            <div className="item" onClick={() => navigateIn(PRODUCTTYPES_LIST_ROUTE)}>
                                <Profile />
                                <span className="label">Catégorie</span>
                            </div>
                        </li>
                    </ul>
                    
                </div>
                <div className="data-stats">
                    { //<li className="data-item "> Stock total : <span className="item-value">{ totalQty }</span></li>
                    //<li className="data-item"> Stock Casiers A: <span className="item-value">{ totalQtyCasier }</span> </li>
                    //<li className="data-item"> Valeur Stock casiers: <span className="item-value">{ convertNumberToMoneyFormat(totalPriceCasier) }</span></li>
                        list.length > 0 ?
                        
                        <ul>
                            <li className="data-item "> Nombre total d'articles: <span className="item-value">{ totalQty }</span></li>
                            <li className="data-item"> Valeur Stock Total: <span className="item-value">{ convertNumberToMoneyFormat(totalPrice) }</span></li>
                            
                            
                        </ul> : ''
                    }
                </div>
                {
                    list.length > 0 ?
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Nom</th>
                                <th>Coût d'achat</th>
                                <th>PU</th>
                                <th>Valeur stock</th>
                                <th>Bénéfice Stock</th>
                                <th>Catégorie</th>
                                <th>Stock</th>
                                <th>Référence</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((item, index) => (
                                    <tr 
                                        key={item.id} 
                                        className={
                                            pattern !== '' ?
                                            !item.lib?.toLowerCase().includes(pattern.toLowerCase()) ? 'hide' : '' :
                                            ''
                                        }
                                    >
                                        <td>{index + 1}</td>
                                        <td>{item.lib}</td>
                                        <td>{convertNumberToMoneyFormat(item.pricebuy)}</td>
                                        <td>{convertNumberToMoneyFormat(item.price)}</td>
                                        <td>{convertNumberToMoneyFormat(item.price*item.qty)}</td>
                                        <td>{convertNumberToMoneyFormat((item.price*item.qty)-(item.pricebuy*item.qty))}</td>
                                        <td>{item.categorylib}</td>
                                        <td>{item.qty}</td>
                                        <td>{item.code}</td>
                                        <td className="table-actions">
                                            {/*<Edit onClick={() => handleUpdateIconClick(item)} />
                                            <Delete onClick={() => handleDeleteIconClick(item)} />*/}
                                            {getButton('update', item)}
                                            {getButton('delete', item)}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p>Vous n'avez aucun article</p>
                }
                
                <Add 
                    className={`medium-modal ${addForm ? '' : 'hide'}`} 
                    onClose={toggleAddForm}
                    fetch={getProducts}
                />
                <Update 
                    className={`medium-modal ${updateForm ? '' : 'hide'}`} 
                    onClose={toggleUpdateForm}
                    item={itemToManage}
                    fetch={getProducts}
                />
                <BackdropModal
                    label="Confirmez cette action"
                    onClose={toggleConfirmModal}
                    onPrimaryActionClick={deleteItem}
                    className={showConfirmModal ? '' : 'hide'} 
                >
                    <p>Voulez vous vraiment supprimer ce article ?</p>
                </BackdropModal>
                <NonedropModal
                    label="Action non autorisée"
                    onClose={toggleNoneModal}
                    className={showNonedropModal ? '' : 'hide'} 
                >
                    <p>Vous n'avez pas accès à ce type action</p>
                </NonedropModal>
                <NotifyModal
            label="Modification de profile"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>L'article a bien été supprimé !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la suppression de l'article !</p>
        </NotifyModal>
            </div>
        </>
    );
}

export default Products;