/* eslint-disable */

import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import './add.css';
import {fetchThunk} from './usersSlice';
import {stringIsEmpty} from '../../utils/functions';
import {fetchProfiles} from '../profiles/profilesAPI';
import {updateUser} from './usersAPI';

/**
 * @author Don TG
 * @param {object} props    //{className, onClose, user}
 * @returns 
 */
function Update(props){
    const [lastname, setLastname] = useState('');
    const [lastnameError, setLastnameError] = useState(null);
    const [firstname, setFirstname] = useState('');
    const [firstnameError, setFirstnameError] = useState(null);
    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(null);
    const [sex, setSex] = useState(0);
    const [compte, setCompte] = useState(0);
    const [status, setStatus] = useState(0);
    const [naissance, setNaissance] = useState('');
    const [naissanceError, setNaissanceError] = useState('');
    const [loader, setLoader] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const {from} = useSelector(state => state.users);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchProfiles()
        .then(response => {
            const {profileDatas} = response.data;
            if(profileDatas.length !== 0){
                setProfiles(profileDatas);
                setCompte(profileDatas[0].id);
            }
        })
        .catch(error => {
            console.log(error);
        });    
    }, []);

    useEffect(() => {
        if(Object.keys(props.user).length !== 0){
            setLastname(props.user.nom);
            setFirstname(props.user.prenom);
            setAddress(props.user.adresse);
            setEmail(props.user.email);
            setPhone(props.user.tel);
            setSex(props.user.genreId);
            setCompte(props.user.profilId);
            setStatus(props.user.StatusId);
            setNaissance(props.user.datnaiss);
        }
    }, [props.user]);

    const clearForm = () => {
        setLastname('');
        setFirstname('');
        setAddress('');
        setEmail('');
        setPhone('');
        setNaissance('');
    }

    const controlInputs = () => {
        let valid = true;
        const localLastname = stringIsEmpty(lastname);
        const localAddress = stringIsEmpty(address);
        const localEmail = stringIsEmpty(email);
        const localPhone = stringIsEmpty(phone);
        const localFirstname = stringIsEmpty(firstname);
        const localNaissance = stringIsEmpty(naissance);

        if(localFirstname === null){
            valid = false;
            setFirstnameError('Ce champs est obligatoire');
        }else{
            setFirstname(localFirstname);
            setFirstnameError(null);
        }
        if(localLastname === null){
            valid = false;
            setLastnameError('Ce champs est obligatoire');
        }else{
            setLastname(localLastname);
            setLastnameError(null);
        }
        if(localEmail === null){
            valid = false;
            setEmailError('Ce champs est obligatoire');
        }else{
            setEmail(localEmail);
            setEmailError(null);
        }
        if(localNaissance === null){
            valid = false;
            setNaissanceError('Ce champs est obligatoire');
        }else{
            setNaissance(localNaissance);
            setNaissanceError('');
        }

        return valid;
    }

    const handleUpdateClick = () => {
        if(controlInputs()){
            setLoader(true);
            const user = { 
                id: props.user.id,
                surname: firstname, 
                name: lastname, 
                tel: phone, 
                address, 
                mail: email, 
                login: email,
                gender: sex,
                profile: compte,
                birthday: naissance,
                photo: 'null',
                status
            }
            updateUser(user).then(response => {
                setLoader(false);
                if(response.data.user?.params.state == 'successful'){
                    clearForm();
                    props.onClose();
                    dispatch(fetchThunk(from - 1));
                }else{
                    ;
                }
            }).catch(error => {
                setLoader(false);
                console.log(error);
            });
        }
    }

    return (
        <ModalForm 
            onClose={props.onClose} 
            onPrimaryActionClick={handleUpdateClick}
            className={props.className} 
            label="Modifier les informations"
            loader={loader}
        >
            <div className="input-line">
                <Input 
                    label="Nom" 
                    value={lastname} 
                    onChange={setLastname}
                    error={lastnameError}
                />
                <Input 
                    label="Prénom" 
                    value={firstname} 
                    onChange={setFirstname}
                    error={firstnameError}
                />
            </div>
            <div className="input-line">
                <Input 
                    label="Adresse" 
                    value={address} 
                    onChange={setAddress}
                    error={addressError}
                />
                <Input 
                    label="Adresse électronique" 
                    value={email} 
                    onChange={setEmail}
                    error={emailError}
                />
            </div>
            <div className="input-line">
                <Input 
                    label="Numéro de téléphone" 
                    value={phone} 
                    onChange={setPhone}
                    error={phoneError}
                />
                <Select label="Sexe" selected={sex} onChange={(e) => setSex(e.target.value)}>
                    <option value="1">Masculin</option>
                    <option value="2">Féminin</option>
                </Select>
            </div>
            <div className="input-line">
                <Input 
                    label="Date de naissance" 
                    type="date"
                    value={naissance} 
                    onChange={setNaissance}
                    error={naissanceError}
                />
                <Select selected={compte} label="Compte" onChange={(e) => setCompte(e.target.value)}>
                    {
                        profiles.map(profile => (
                            <option key={profile.id} value={profile.id}>{profile.lib}</option>
                        ))  
                    }
                </Select>
            </div>
            <div className="input-line">
                <Select selected={status} label="Status" onChange={(e) => setStatus(e.target.value)}>
                    <option value="1">Désactivé</option>
                    <option value="2">Activé</option>
                </Select>
            </div>
        </ModalForm>
    );
}

export default Update;