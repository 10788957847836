import { createSlice } from "@reduxjs/toolkit";
import {DEFAULT_LIST_ITEMS_NUMBER} from '../../constants/config';
import { fetchUsers, addUser, updateUser, deleteUser, activeAccountUser } from "./usersAPI";

/**
 * @author Don TG
 */
export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        list: [], // list of users in current page
        from: 1, // number left
        to: DEFAULT_LIST_ITEMS_NUMBER, // number right
        by: DEFAULT_LIST_ITEMS_NUMBER, // number of users per page
        total: undefined, // total of users in database,
        loader: true,
        add: {loader: false, error: undefined},  // add request data
        pattern: '', // input search text
    },
    reducers: {
        fetch: (state, action) => {
            state.list = action.payload.list;
            state.from = action.payload.from;
            state.to = action.payload.to;
            state.total = action.payload.total;
            state.loader = false;
        },
        loading: (state, action) => {
            state.loader = action.payload;
        },
        add_request: (state, action) => {
            state.add.loader = action.payload.loader;
            state.add.error = action.payload.error;
        },
        create: (state, action) => {
            state.list = [action.payload, ...state.list];
            state.add.loader = false;
        },
        update: (state, action) => {
            state.list = state.list.map(user => {
                if(user.id === action.payload.id)
                    return action.payload;
                return user;
            });
        },
        remove: (state, action) => {
            state.list = state.list.filter(user => user.id !== action.payload);
        },
        active: (state, action) => {
            state.list = state.list.filter(user => {
                if(user.id === action.payload)
                    user.active = !user.active;
                return user;
            });
        }
    }
});

export const { fetch, create, update, remove, active, loading, add_request } = usersSlice.actions;

/**
 * First fetching.
 * 
 * @author Don TG
 * @returns 
 */
export const fetchThunk = (from = 0) => (dispatch, getState) => {
    const { users } = getState();
    dispatch(loading(true));

    fetchUsers(from, users.by)
    .then(response => {
        const {userDatas, params} = response.data;
        const nextTo = users.by + from;
        const to = nextTo > params.total ? params.total : nextTo;
        dispatch(fetch({list: userDatas, from: from + 1, to, total: params.total}));
    })
    .catch(error => {
        dispatch(loading(false));
        console.log(error);
    });    
}

/**
 * Add new user.
 * 
 * @author Don TG
 * @param {object} data 
 * @returns 
 */
export const createThunk = (data) => (dispatch, getState) => {
    dispatch(add_request({loader: true}));
    addUser(data)
    .then(response => {
        const {user, params} = response.data;
        if(params.state == 'succesful'){
            data.id = user.id;
            //dispatch(create(data));
            dispatch(fetchThunk());
            dispatch(add_request({loader: false, error: undefined}));
        }else{
            dispatch(add_request({loader: false, error: 'Erreur lors de l\'ajout, Réessayez'}));
        }
    })
    .catch(error => {
        dispatch(add_request({loader: false, error: 'Une erreur est survenue'}));
        console.log(error);
    }); 
}

/**
 * Update user data.
 * 
 * @author Don TG
 * @param {object} data 
 * @returns 
 */
export const updateThunk = (data) => (dispatch, getState) => {
    dispatch(update(data));
    /*updateUser(data)
    .then(response => {
        dispatch(update(data));
    })
    .catch(error => {
        console.log(error);
    }); */
}

/**
 * Delete user.
 * 
 * @author Don TG
 * @param {int} id
 * @returns 
 */
export const deleteThunk = (id) => (dispatch, getState) => {
    dispatch(remove(id));
    /*deleteUser(id)
    .then(response => {
        dispatch(remove(id));
    })
    .catch(error => {
        console.log(error);
    }); */
}

/**
 * Toggle state of user account.
 * 
 * @author Don TG
 * @param {int} id
 * @returns 
 */
export const activeThunk = (id) => (dispatch, getState) => {
    dispatch(active(id));
    /*activeAccountUser(id)
    .then(response => {
        dispatch(active(id));
    })
    .catch(error => {
        console.log(error);
    });*/ 
}

export default usersSlice.reducer;