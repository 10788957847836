import React, {useState} from "react";
import ModalForm from "../../components/ModalForm";
import Input from "../../components/form/Input";
import { addCompanyActivty } from "./CompagnyinfosAPI";
import { stringIsEmpty } from "../../utils/functions";
import NotifyModal from "../../components/NotifyModal";

/**
 * @author Emerone Power
 * @param {object} props    //{className, onClose, fetch}
 * @returns 
 */

function Add(props) {
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(null);
    const [modalClass, setModalClass] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false);
    const [showNotifyModalError, setShowNotifyModalError] = useState(null);

    const clearForm = () => {
        setName('');
    }

    const controlInputs = () => {
        let valid = true;
        const localName = stringIsEmpty(name);

        if (localName === null) {
            valid = false;
            setNameError('Ce champ est obligtoire');
        }else{
            setName(localName);
            setNameError(null);
        }
        return valid;
    }

    const handleAddClick = () => {
        setLoader(true);
        let responseType = false;
        if(controlInputs()){
            addCompanyActivty({lib: name, code: 'null'}).then(response => {
                setLoader(false);
                setModalClass("modal-danger");
                setModalMessage("échoué");
                if (response.data.params.state === 'successfull') {
                    clearForm('');
                    props.onClose();
                    props.fetch();
                    setModalClass("modal-success");
                    setModalMessage("Réussi");
                    toggleNotifyModal()
                }
            }).catch(error => {
                setLoader(false);
            });
        }
    }
    

    //notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }

   

    return (
        <>
            <ModalForm 
                onClose={props.onClose}
                className={props.className}
                label="Nouvel produits / service"
                onPrimaryActionClick={handleAddClick}
                loader={loader} >
                    <Input
                        label="Nom du produit / service"
                        value={name}
                        onChange={setName}
                        error={nameError} />

            </ModalForm>
            <NotifyModal
             label="Ajout de service / produits"
             onClose={toggleNotifyModal}
             className={showNotifyModal ? '' : 'hide'}
             notifyClassName={modalClass} >
                <p>{modalMessage}</p>
            </NotifyModal>
        </>
    )
}

export default Add;