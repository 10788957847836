import { createSlice } from '@reduxjs/toolkit';
import { DASHBOARD_LIST_ROUTE } from '../constants/routes';

/**
 * @author Don TG
 */
export const appSlice = createSlice({
    name: 'app',
    initialState: {
        page: 'login',
        auth: false,
        user: null,
        modules: null,
        breadcrumb: []
    },
    reducers: {
        navigate: (state, action) => {
            state.page = action.payload;
        },
        login: (state, action) => {
            state.auth = true;
            state.user = action.payload.user;
            state.userAssigments = action.payload.userAssigments;
        },
        logout: (state) => {
            state.auth = false;
            state.user = null;
        },
        setBreadcrumb: (state, action) => {
            state.breadcrumb = action.payload;
        }
    }
});

export const {navigate, login, setBreadcrumb, logout} = appSlice.actions;
export default appSlice.reducer;