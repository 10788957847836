import React, {useState, useEffect} from 'react';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {stringIsEmpty} from '../../utils/functions';
import {updateConsignation } from './ConsignationAPI';
import NotifyModal from "../../components/NotifyModal"; //Notify modal


/**
 * @author Don TG
 * @param {object} props    //{className, onClose, item, fetch}
 * @returns 
 */
function Update(props){
    const [id, setId] = useState('');
    const [returned, setReturned] = useState('');
    const [returnedError, setReturnedError] = useState(null);
    const [invoice, setInvoice] = useState('');
    const [invoiceError, setInvoiceError] = useState(null);
    const [qty, setQty] = useState('');
    const [qtyError, setQtyError] = useState(null);
    const [datereg, setDatereg] = useState('');
    const [dateregError, setDateregrror] = useState(null);
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        if(Object.keys(props.item).length !== 0){
            setId(props.item.id);
            setReturned(props.item.returned);
            setInvoice(props.item.invoiceMat);
            setDatereg(props.item.datereg);
            setQty(props.item.qty);
        }
    }, [props.item]);

    const clearForm = () => {
        setReturned(0);
        setQty(0);
    }

    const controlInputs = () => {
        let valid = true;
        const localQty = qty >= 0 ? true : false;
        const localReturned =  returned >= 0 ? true : false;

        if(localQty){
            setQty(qty);
            setQtyError(null);
            
        }else{
            valid = false;
            setQtyError('Ce champ est obligatoire');
        }
      
        if(localReturned){
            setReturned(returned);
            setReturnedError(null);
        }else{
            valid = false;
            setReturnedError('Ce champ est obligatoire');
        }
        return valid;
    }

    const handleUpdateClick = () => {
        if(controlInputs()){
            updateConsignation({id: props.item.id, qty: qty, returned: returned, datereg: datereg, invoice: props.item.invoiceRef}).then(response => {
                setLoader(false);
                if(response.data.params.state == 'successfull'){
                    clearForm();
                    props.onClose();
                    props.fetch();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
                
            }).catch(error => {
                setLoader(false);
                console.log(error);
            });
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    return (
        <>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Modifier les informations"
            onPrimaryActionClick={handleUpdateClick}
            loader={loader}
        >
            <div className="input-line">
                <Input 
                    label="Consignations" 
                    value={qty} 
                    onChange={setQty}
                    error={setQtyError}
                />
                <Input 
                    label="Retour" 
                    value={returned} 
                    onChange={setReturned}
                    error={returnedError}
                />
                
            </div>
            
        </ModalForm>
        <NotifyModal
            label="Modification de l'article"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>La consignation a bien été modifié !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la modification de la consignation !</p>
        </NotifyModal>
        </>
        
    );
}

export default Update;