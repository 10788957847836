import React from "react";
import { ReactComponent as Close } from '../assets/icons/close.svg';
import '../styles/components/modal-form.css';
import Button from "./form/Button";
import Loader from './Loader';

/**
 * @author Don TG
 * @param {object} props    //{label, className, onClose, onPrimaryActionClick, loader}
 * @returns 
 */
function ModalForm(props){
    return (
        <div className={`backdrop transparent ${props.className}`}>
            <div className={`modal-content`}>
                <div className="modal-content-head">
                    <label>{props.label}</label>
                    <Close onClick={props.onClose} />
                </div>
                <div className="modal-content-body">
                    {props.children}
                </div>
                <div className="modal-content-foot">
                    <Button 
                        label="Enregistrer" 
                        className={`primary ${props.loader ? 'hide' : ''}`}  
                        onClick={props.onPrimaryActionClick}
                    />
                    <Loader visible={props.loader} />
                </div>
            </div>
        </div>
    );
}

export default ModalForm;