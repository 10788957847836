import React from "react";
import '../styles/components/activityCard.css';
/**
 * @author Emerone Power
 * @param {object} props        // {Label, children, icon}
 * @returns 
 */
function ActivityCard(props){
    const Icon = props.icon;
    return (
        <div className="card-activity">
            <Icon className="icon" />
            <label>{props.label}</label>
            <div className="children">{props.children}</div>
        </div>
    );
}
export default ActivityCard;