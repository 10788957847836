import React from "react";
import '../../styles/components/form/select.css';

/**
 *  
 * @param {object} props    //{label, onChange, selected}
 * @returns 
 */
function Select(props){
    return (
        <div className="select">
            {
                props.label && 
                <label>{props.label}</label>
            }
            <div>
                <select value={props.selected} onChange={props.onChange}>
                    {props.children}
                </select>
            </div>
        </div>
    );
}

export default Select;