import React from 'react';
import { useSelector} from 'react-redux'
import {
  Routes,
  Route,
} from "react-router-dom";
import Users from '../features/users/Users';
import Profiles from '../features/profiles/Profiles';
import Assignments from '../features/assignments/Assignments';
import Features from '../features/features/Features';
import Actions from '../features/actions/Actions';
import Login from '../features/auth/Login';
import Nav from '../components/Nav';
import Header from '../components/Header';
import {
  USERS_LIST_ROUTE, PROFILES_LIST_ROUTE, ASSIGNMENTS_LIST_ROUTE,
  FEATURES_LIST_ROUTE, ACTIONS_LIST_ROUTE, ENTREPRISE_INFO_ROUTE, 
  PRODUCTS_LIST_ROUTE, PRODUCTTYPES_LIST_ROUTE, MEASUREUNITS_LIST_ROUTE, 
  PRODUCTSUPPLIES_LIST_ROUTE, INVENTORY_LIST_ROUTE, INVENTORY_NEW_ROUTE, CLIENTS_LIST_ROUTE, 
  INVOICES_LIST_ROUTE, INVOICES_PAIMENTS_ROUTE, INVOICES_PARAMS_ROUTE, CASHREGISTER_PAD_ROUTE,
  COSNIGNATIONS_LIST_ROUTE, DASHBOARD_LIST_ROUTE, INVCOMMANDS_LIST_ROUTE
} from '../constants/routes';
import './app.css';
import CompanyInfos from '../features/companyinfos/Compagnyinfos';
import Products from '../features/Products/Products';
import ProductSupplies from '../features/ProductSupplies/ProductSupplies';
import Clients from '../features/Clients/Clients';
import Invoices from '../features/invoices/Invoices';
import CashRegister from '../features/CashRegister/CashRegister';
import ProductTypes from '../features/productTypes/ProductType';
import InvoiceParmas from '../features/invoiceparams/InvoiceParams';
import MeasureUnits from '../features/measureUnits/MeasureUnits';
import Consignations from '../features/consignations/Consignation';
import Paiments from '../features/paiments/Paiments';
import Inventory from '../features/inventory/Inventory';
import NewInventory from '../features/inventory/NewInventory';
import Dashboard from '../features/dashboard/Dashboard';
import InvoiceCommands from '../features/invoices/InvoiceCommands';

/**
 * @author Don TG
 * @returns 
 */
function App() {
  const auth = useSelector((state) => state.app.auth);
  //const navigateIn = useNavigate();

  return (
    <>
      {!auth && <Login />}
      {
        auth && 
        <>
          <Header />
          <section>
            {<Nav />}
            <div id="main">
              <Routes>
                <Route path={USERS_LIST_ROUTE} element={<Users />} />
                <Route path={PROFILES_LIST_ROUTE} element={<Profiles />} />
                <Route path={ASSIGNMENTS_LIST_ROUTE} element={<Assignments />} />
                <Route path={FEATURES_LIST_ROUTE} element={<Features />} />
                <Route path={ACTIONS_LIST_ROUTE} element={<Actions />} />
                <Route path={ENTREPRISE_INFO_ROUTE} element={<CompanyInfos />} />
                <Route path={PRODUCTS_LIST_ROUTE} element={<Products />} />
                <Route path={CLIENTS_LIST_ROUTE} element={<Clients />} />
                <Route path={INVOICES_LIST_ROUTE} element={<Invoices />} />
                <Route path={INVOICES_PARAMS_ROUTE} element={<InvoiceParmas />} />
                <Route path={CASHREGISTER_PAD_ROUTE} element={<CashRegister />} />
                <Route path={PRODUCTSUPPLIES_LIST_ROUTE} element={<ProductSupplies />} />
                <Route path={PRODUCTTYPES_LIST_ROUTE} element={<ProductTypes />} />
                <Route path={MEASUREUNITS_LIST_ROUTE} element={<MeasureUnits />} />
                <Route path={COSNIGNATIONS_LIST_ROUTE} element={<Consignations />} />
                <Route path={INVOICES_PAIMENTS_ROUTE} element={<Paiments />} />
                <Route path={INVENTORY_LIST_ROUTE} element={<Inventory />} />
                <Route path={INVENTORY_NEW_ROUTE} element={<NewInventory />} />
                <Route path={DASHBOARD_LIST_ROUTE} element={<Dashboard />} />
                <Route path={INVCOMMANDS_LIST_ROUTE} element={<InvoiceCommands />} />
              </Routes>
            </div>
          </section>
        </>
      }
    </>
  );
}

export default App;

/**/