import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {ReactComponent as IconPhone } from "../../assets/icons/phone.svg"
import {ReactComponent as IconEmail } from "../../assets/icons/email.svg"
import {ReactComponent as IconWebsite } from "../../assets/icons/website.svg"
import {ReactComponent as IconCalendar } from "../../assets/icons/calendar.svg"
import {ReactComponent as IconStats } from "../../assets/icons/stats.svg"
import {ReactComponent as IconAddress } from "../../assets/icons/address.svg"
import {ReactComponent as IconBalance } from "../../assets/icons/balance.svg"
import {ReactComponent as IconTax1 } from "../../assets/icons/tax-1.svg"
import {ReactComponent as IconTax2 } from "../../assets/icons/tax-2.svg"
import {ReactComponent as IconTax3 } from "../../assets/icons/economy-percentage-symbol.svg"
import {ReactComponent as Tasklist } from "../../assets/icons/tasklist.svg"
import {ReactComponent as Edit } from "../../assets/icons/edit.svg"
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { navigate, setBreadcrumb } from "../../app/appSlice";
import { ENTREPRISE_INFO_ROUTE, MEDIA_API_URL } from "../../constants/routes";
import logo from '../../assets/icons/logo.png';
//import {logo2} from '../../assets/company/';
import LabelInfo from "../../components/LabelInfo";
import ActivityCard from "../../components/ActivityCard";
import AddButton from "../../components/ButtonCircle";
import Update from "./Update";
import "./compagnyInfos.css";
import BackdropModal from "../../components/BackdropModal";
import Add from "./Add";
import {fetchCompanyInfos, updateCompanyInfos, deleteCompanyActivty} from "./CompagnyinfosAPI";
import Button from "../../components/ButtonCircle";

/**
 * @author Emerone Power
 * @returns 
 */
function CompanyInfos(){
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [itemToManage, setItemToManage] = useState({});
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [updateForm, setUpdateForm] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNotifyModalError, setShowNotifyModalError] = useState(null);
    const [addForm, setAddForm] = useState(false)
    const imgDir = MEDIA_API_URL+"/img/company";

    useEffect(() => {
        dispatch(navigate(ENTREPRISE_INFO_ROUTE));
        dispatch(setBreadcrumb(['Gestion des Infos de L\'entreprise']));
        getCompanyInfos();
    }, []);

    const getCompanyInfos = () =>{
        fetchCompanyInfos(1).then(
            response => {
                const {companyDatas, companyActivities} = response.data;
                setList(companyDatas);
                //console.log(companyDatas);
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }

    const toggleAddForm = () => {
        setAddForm(!addForm);
    }
    const handleShowUpdateFormClick = () => {
        setShowUpdateForm(!showUpdateForm);
    }

    const handleUpdateClick = () => {
        handleShowUpdateFormClick();
    }
    const handleDeleteIconClick = (item) =>{
        setItemToManage(item);
        toggleConfirmModal();
    }

    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
    }
    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }
    
    const deleteItem = () =>{
        deleteCompanyActivty(itemToManage.id).then(response => {
            if(response.data.EActivityDatas.params.state === 'successfull'){
                getCompanyInfos();
                toggleConfirmModal();
            }
        }
            
        ).catch(

        );
    }
    const assigments = useSelector((state) => state.app.userAssigments[3]);
    
    function getImgSrc(name){
        const src = "../../assets/company/"+name;
        //import logo from src;
        //return logo;
    }
    return (
        <div className="compagny-info">
            <>
            {
            assigments.manage === 1 ?
                <div className="more-options">
                    <AddButton label="Modifier" icon={Edit} className="primary" onClick={handleShowUpdateFormClick}/>
                </div> : ''
            
            }
            </>
        
        {
            
            list.infos ?
            <>
                <div id="Complogo">
                    <img src={imgDir+'/'+list.infos[0].pic} alt="Compagny logo" />
                </div>
                <h2 id="compName">{list.infos[0].lib}</h2>
                <div className="compDescription">
                    <ul>
                        <li>
                            <LabelInfo icon={IconPhone} label="Téléphone" value={list.infos[0].phone}/>
                        </li>
                        <li>
                            <LabelInfo icon={IconEmail} label="Adresse électronique" value={list.infos[0].email}/>
                        </li>
                        <li>
                            <LabelInfo icon={IconWebsite} label="Site web" value={list.infos[0].website}/>
                        </li>
                        <li>
                            <LabelInfo icon={IconCalendar} label="Date de création" value={list.infos[0].datecreat}/>
                        </li>
                        <li>
                            <LabelInfo icon={IconStats} label="Chiffre d'affaire" value={list.infos[0].chiffaff + " FCFA"}/>
                        </li>
                        <li>
                            <LabelInfo icon={IconAddress} label="Adresse" value={list.infos[0].address}/>
                        </li>
                        <li>
                            <LabelInfo icon={IconBalance} label="Régime fiscal" value={list.infos[0].regfisc}/>
                        </li>
                        <li>
                            <LabelInfo icon={IconTax1} label="TVA" value="oui"/>
                        </li>
                        <li>
                            <LabelInfo icon={IconTax2} label="CSS" value="oui"/>
                        </li>
                        <li>
                            <LabelInfo icon={IconTax3} label="TPS" value="Non"/>
                        </li>
                    </ul>
                </div>
                <div className="compActivity">
                    <div className="label"><h3>Acivités</h3></div>
                    <div className="area">
                        <ul>
                            {
                                list.activities ?
                                    list.activities.map((item, index) => (
                                        <li>
                                            <ActivityCard icon={Tasklist} label={item.lib}>
                                            
                                            </ActivityCard>
                                            <Delete className="button" onClick={() => handleDeleteIconClick(item)} />
                                        </li>
                                    ))
                                    :
                                    <p>Vous n'avez aucune Activité enregistrée</p>
                            }
                            {/*<li>
                                <ActivityCard icon={Tasklist} label="management"></ActivityCard>
                            </li>*/}
                            
                        </ul>
                    </div>
                    {
                        assigments.manage === 1 ? 
                            <div className="buton-add">
                                <AddButton label="+" className="primary" onClick={toggleAddForm} />
                            </div>
                        : ''
                    }
                    
                </div>
                <BackdropModal
                    label= "Confirmez cette action"
                    onClose={toggleConfirmModal}
                    onPrimaryActionClick={deleteItem}
                    className={showConfirmModal ? '' : 'hide'} >
                        <p>Voulezvous vraiment supprimer ce produit / service ?</p>
                </BackdropModal>
                <Update 
                    className={`medium-modal ${showUpdateForm ? '' : 'hide'}`}
                    onClose={handleShowUpdateFormClick}
                    item = {list}
                    fetch = {getCompanyInfos}
                    />
                <Add
                    className={`small-modal ${addForm ? '' : 'hide'}`}
                    onClose={toggleAddForm}
                    fetch={getCompanyInfos}  />
            </> 
            : 
            <p>Vous n'avez aucun Entreprise enregistrée</p>
        }
        
        </div>
    );
}
export default CompanyInfos;