import React from "react";
import { ReactComponent as Close } from '../assets/icons/close.svg';
import '../styles/components/notify-modal.css';
import Button from "./form/Button";

/**
 * @author Emerone power
 * @param {object} props    //{label, className, onClose}
 * @returns 
 */
function NotifyModal(props){
    return (
        <div className={`notify ${props.className}`}>
            <div className={`modal-content ${props.notifyClassName}`}>
                <div className="modal-content-head">
                    <label>{props.label}</label>
                    <Close onClick={props.onClose} />
                </div>
                <div className="modal-content-body">
                    {props.children}
                </div>
                <div className="modal-content-foot">
                    <Button 
                        label="ok"  
                        onClick={props.onClose}
                    />
                </div>
            </div>
        </div>
    );
}

export default NotifyModal;