import axios from 'axios';
import { 
   MEASUREUNIT_API_ADD_ROUTE,
   MEASUREUNIT_API_UPDATE_ROUTE,
   MEASUREUNIT_API_DELETE_ROUTE,
   MEASUREUNIT_API_LIST_ROUTE
} from '../../constants/routes';
import { convertObjectToURLSearchParams } from '../../utils/functions';

/**
 * @author Emerone Power
 * @param {int} skip 
 * @param {int} take 
 * @returns
 */
export function fetchMeasureUnits(start, range){
    return axios.get(MEASUREUNIT_API_LIST_ROUTE, {params: {start, range}});
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
export function addMeasureUnit(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(MEASUREUNIT_API_ADD_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {object} data 
 * @returns
 */
 export function updateMeasureUnit(data){
    const params = convertObjectToURLSearchParams(data);
    return axios.post(MEASUREUNIT_API_UPDATE_ROUTE, params);
}

/**
 * @author Emerone Power
 * @param {int} id
 * @returns
 */
export function deleteMeasureUnit(id){
    const params = convertObjectToURLSearchParams({id});
    return axios.post(MEASUREUNIT_API_DELETE_ROUTE, params);
}