import React from "react";
import { useNavigate } from 'react-router-dom';
import '../styles/components/flat-square-rounded-card.css';

/**
 * @author Emerone Power
 * @param {object} props    //{label, className, onClick}
 * @returns 
 */
function FlatSquareRoundedCard(props){
    //const [menuIsOpen, setMenuIsOpen] = useState(false);
    const Icon = props.icon;
    const navigate = useNavigate();

    const handleOnClick = () => {
        if(props.href){
            navigate(props.href);
            return;
        }

       // setMenuIsOpen(!menuIsOpen);
    }

    let active = props.active;
    let authorized = props.authorized;

    /*if(props.children && props.active)
        active = !menuIsOpen;*/

    return (
        <>
            {
                props.authorized && 

                <div className="card card-flat-sqare-rouded">
                    <div className="card-header">
                        <span className="title">
                            <label>{props.label}</label>
                        </span>
                    </div>
                    <div className="card-content">
                        {props.children}
                    </div>
                </div>
            }
        </>
    );
}

export default FlatSquareRoundedCard;