import React, {useState, useEffect} from 'react';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {stringIsEmpty} from '../../utils/functions';
import {updateInvoiceParams, fetchInvoiceParams} from './InvoiceparamsAPI';
import NotifyModal from "../../components/NotifyModal"; //Notify modal


/**
 * @author Emerone Power
 * @param {object} props    //{className, onClose, item, fetch}
 * @returns 
 */
function Update(props){
    const [invFormat, setInvFormat] = useState('');
    const [formatPrefix, setFormatPrefix] = useState('');
    const [id, setId] = useState('');
    const [lib, setLib] = useState('');
    const [libError, setLibError] = useState(null);
    const [descrip, setDescrip] = useState('');
    const [descripError, setDescripError] = useState(null);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(null);
    const [status, setStatus] = useState('');
    const [statusError, setStatusError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal

    useEffect(() => {
        if(Object.keys(props.item).length !== 0){
            setId(props.item.id);
            setLib(props.item.lib);
            setCode(props.item.code);
            setDescrip(props.item.descrip);
            setStatus(props.item.statusId);
            setInvFormat(props.item.descrip.slice(props.item.descrip.length-6, props.item.descrip.length));
            setFormatPrefix(props.item.descrip.slice(0, props.item.descrip.length-6));
        }
    }, [props.item]);

    const clearForm = () => {
        setLib('');
        setCode('');
        setDescrip('');
        setDescrip(1);
    }

    const controlInputs = () => {
        let valid = true;
        const localLib = stringIsEmpty(lib);
        const localDescrip = stringIsEmpty(descrip);

        if(localLib === null){
            valid = false;
            setLibError('Ce champ est obligatoire');
        }else{
            setLib(localLib);
            setLibError(null);
        }
        return valid;
    }

    const handleUpdateClick = () => {
        if(controlInputs()){
            updateInvoiceParams({id: props.item.id, code: code, lib: lib, descrip: descrip, status: status}).then(response => {
                setLoader(false);
                if(response.data.params.state == 'successfull'){
                    clearForm();
                    props.onClose();
                    props.fetch();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
            }).catch(error => {
                setLoader(false);
                console.log(error);
            });
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    return (
        <>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Modifier les informations"
            onPrimaryActionClick={handleUpdateClick}
            loader={loader}
        >
            <div className="input-line">
                {
                    code === 'matfmt' ? 
                        <>
                            <Input 
                                label="Description" 
                                value={formatPrefix} 
                                onChange={setFormatPrefix}
                                error={descripError}
                            />
                            <Select selected={descrip} label="Alogrithme" onChange={(e) => setDescrip(e.target.value)}>  
                                <option value={formatPrefix+":y:m:i"} key="0">{formatPrefix+":y:m:i"}</option>
                                <option value={formatPrefix+":m:y:i"} key="1">{formatPrefix+":m:y:i"}</option>
                                <option value={formatPrefix+":i:m:y"} key="2">{formatPrefix+":i:m:y"}</option>
                            </Select><br />
                            
                        </>
                        : 
                        <>
                            <Input 
                                label="Description" 
                                value={descrip} 
                                onChange={setDescrip}
                                error={descripError}
                            />
                            <Select selected={status} label="Status" onChange={(e) => setStatus(e.target.value)}>  
                                <option value="0" key="0">Caché</option>
                                <option value="1" key="1">Visible</option>
                            </Select>
                        </>
                }
            </div>
            {
                code === 'matfmt' ? 
                <p>NB : les caractères précédés de ':' sont les composantes variables permettant d'obtenir un numéro de facture unique. y= année en cours, m = mois en cours et i= index de facture de la journée en cours</p>
                    : ''
            }
        </ModalForm>
        <NotifyModal
            label="Modification de l'article"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>Le profile a bien été modifié !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la modification du profile !</p>
        </NotifyModal>
        </>
        
    );
}

export default Update;