import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import {navigate, setBreadcrumb} from '../../app/appSlice';
import { PRODUCTSUPPLIES_LIST_ROUTE} from '../../constants/routes';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import Select from "../../components/form/Select";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import Add from "./Add";
//import Update from "./Update";
import BackdropModal from "../../components/BackdropModal";
import NonedropModal from "../../components/NonedropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import {fetchProductSupplies, findProductSupplies, invalidateProduct } from './ProductSuppliesAPI';
import { fetchProducts } from '../Products/ProductsAPI';


/**
 * @author Emerone Power
 * @returns 
 */
function ProductSupplies(){
    const [addForm, setAddForm] = useState(false);
    const [list, setList] = useState([]);
    const [listProducts, setListProducts] = useState([]);
    const [category, setCategory] = useState("none");
    const [pattern, setPattern] = useState('');
    const [updateForm, setUpdateForm] = useState(false);
    const [itemToManage, setItemToManage] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const dispatch = useDispatch();
    const [params, setParams] = useState({});

    useEffect(() => {
        dispatch(navigate(PRODUCTSUPPLIES_LIST_ROUTE));
        dispatch(setBreadcrumb(['Gestion des approvisionnemet']));
        getProductSupplies();
    }, []);

    const getProductSupplies = (value = "none") => {
        setList([]);
        if (value !== "none") {
            value = parseInt(value)
        }
        switch (value) {
            case "none":
                fetchProductSupplies()
            .then(response => {
                const {ProdSuppliesDatas} = response.data;
                if (response.data.params && response.data.ProdSuppliesDatas) {
                    setList(ProdSuppliesDatas);
                    setParams(response.data.params);
                }else{
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
                break;
            case value:
                findProductSupplies(value, "product")
            .then(response => {
                const {ProdSuppliesDatas} = response.data;
                if (response.data.params && response.data.ProdSuppliesDatas) {
                    setList(ProdSuppliesDatas);
                    setParams(response.data.params);
                }
            })
            .catch(error => {
                console.log(error);
            });
                break;
        
            default:
                fetchProductSupplies()
            .then(response => {
                const {ProdSuppliesDatas} = response.data;
                if (response.data.params && response.data.ProdSuppliesDatas) {
                    setList(ProdSuppliesDatas);
                    setParams(response.data.params);
                }else{
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
                break;
        }
    }

    const getCategory = () => {
        setListProducts([]);
        fetchProducts()
        .then(response => {
            const {productDatas} = response.data;
            //setListCategory(productTypeDatas);
            if ( response.data.productDatas) {
                setListProducts(productDatas);
            }else{
                console.log(response.data);
            }
        })
        .catch(error => {
            console.log(error);
        }); 
          
    }

    const toggleAddForm = () => {
        setAddForm(!addForm);
    }

    const toggleUpdateForm = () => {
        setUpdateForm(!updateForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const handleUpdateIconClick = (item) => {
        setItemToManage(item);
        toggleUpdateForm();
    }

    const handleDeleteIconClick = (item) => {
        setItemToManage(item);
        toggleConfirmModal();
    }

    const deleteItem = () => {
        invalidateProduct(itemToManage.id, itemToManage.productId).then(response => {
            if(response.data.prodSupplies?.params.state === 'successfull'){
                getProductSupplies();
                toggleNotifyModal();
            }else{
                toggleNotifyModalError();
            }
            console.log(response.data);
        }).catch(error =>  {
            console.log(error);
        });
        
        toggleConfirmModal();
    }
    const handleChangeCategoryClick = (category) => {
        //setCategory(category);
        getProductSupplies(category);
        //console.log(category);
    }
    
    //None / info modal
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        setItemToManage(0);
        toggleNoneModal();
    }
    const assigments = useSelector((state) => state.app.userAssigments[3]);
    function getButton(type, item = null) {
        
        var toggleModal = handleNoneIconClic;
        if (type ==='add') {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = toggleAddForm
            }
            return  <Button 
            label="Ajouter un approvisionnement" 
            className="primary" 
            onClick={toggleModal}
            //onClick={toggleAddForm}
        />
        }
        if (type ==='update' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleUpdateIconClick(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Edit onClick={() => toggleModal(item)} />
        }
        if (type ==='delete' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleDeleteIconClick(item)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <Delete onClick={toggleModal} />
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    const handleNextClick = () => {
        if(params.end !== params.total){
            getProductSupplies(params.end, params.end + 25);
        }
            
    }

    const handlePreviousClick = () => {
        if(params.start !== 1){
            getProductSupplies(params.end - 25, params.start);
        }
            
    }

    return (
        <>
            <div className="main-head main-head-users">
                {
                    list.length > 0 &&
                    <>
                        <div className="table-infos">
                            <label>{`${params.start+1} - ${params.end} sur ${params.total}`}</label>
                            <ArrowLeft 
                                className={params.start+1 === 1 ? 'disable' : undefined} 
                                onClick={handlePreviousClick} 
                            />
                            <ArrowRight 
                                className={params.end === params.total ? 'disable' : undefined}
                                onClick={handleNextClick} 
                            />
                        </div>
                        <Select selected={category} onChange={e => handleChangeCategoryClick(e.target.value)}>
                            <option value="none" selected>Tout</option>
                                {
                                    listProducts.map(option => (
                                        <option 
                                            value={option.id} 
                                            key={option.id}
                                        >
                                            {`${option.lib}`}
                                        </option>
                                    ))
                                }

                        </Select>
                        
                        {
                            list.length > 0 &&
                            <Input 
                                placeholder="Rechercher" 
                                value={pattern}
                                onChange={setPattern}    
                            />
                        }
                    </> 
                }
                {
                    getButton('add')
                }
                
            </div>
            <div className="main-body">
                {
                    list.length > 0 ?
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Article</th>
                                <th>Quantité</th>
                                <th>Référence</th>
                                <th>Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((item, index) => (
                                    <tr 
                                        key={item.id} 
                                        className={
                                            pattern !== '' ?
                                            !item.productLib?.toLowerCase().includes(pattern.toLowerCase()) ? 'hide' : '' :
                                            ''
                                        }
                                    >
                                        <td>{index + 1}</td>
                                        <td>{item.productLib}</td>
                                        <td>{item.qty}</td>
                                        <td>{item.numref !== ''  && item.numref !== null? item.numref : '...'}</td>
                                        <td>{item.datereg}</td>
                                        <td className="table-actions">
                                            {/*<Edit onClick={() => handleUpdateIconClick(item)} />
                                            <Delete onClick={() => handleDeleteIconClick(item)} />*/}
                                            {getButton('delete', item)}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p>Vous n'avez aucun Approvisionnement</p>
                }
                
                <Add 
                    className={`medium-modal ${addForm ? '' : 'hide'}`} 
                    onClose={toggleAddForm}
                    fetch={getProductSupplies}
                />
                
                <BackdropModal
                    label="Confirmez cette action"
                    onClose={toggleConfirmModal}
                    onPrimaryActionClick={deleteItem}
                    className={showConfirmModal ? '' : 'hide'} 
                >
                    <p>Voulez vous vraiment supprimer cet approvisionnement ?</p>
                </BackdropModal>
                <NonedropModal
                    label="Action non autorisée"
                    onClose={toggleNoneModal}
                    className={showNonedropModal ? '' : 'hide'} 
                >
                    <p>Vous n'avez pas accès à ce type action</p>
                </NonedropModal>
                <NotifyModal
            label="Modification de profile"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>L'approvisionnement a bien été supprimé !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la suppression de l'approvisionnement !</p>
        </NotifyModal>
            </div>
        </>
    );
}

export default ProductSupplies;