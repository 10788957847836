import React, {useState, useEffect} from 'react';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {stringIsEmpty} from '../../utils/functions';
import {updateInventory, fetchInventory} from './InventoryAPI';
import { fetchUsers } from '../users/usersAPI';
import { fetchProducts } from '../Products/ProductsAPI';
import NotifyModal from "../../components/NotifyModal"; //Notify modal


/**
 * @author Don TG
 * @param {object} props    //{className, onClose, item, fetch}
 * @returns 
 */
function Update(props){
    const [id, setId] = useState('');
    const [listUsers, setListUsers] = useState([]);
    const [listRows, setListRows] = useState([]);
    const [listProducts, setListProducts] = useState([]);
    const [lib, setLib] = useState('');
    const [libError, setLibError] = useState(null);
    const [price, setPrice] = useState('');
    const [priceError, setPriceError] = useState(null);
    const [theoryQty, setTheoryQty] = useState(0);
    const [qty, setQty] = useState(0);
    const [qtyError, setQtyError] = useState(null);
    const [numref, setNumref] = useState('');
    const [numrefError, setNumrefError] = useState(null);
    const [descrip, setDescrip] = useState('');
    const [descripError, setDescripError] = useState(null);
    const [users, setUsers] = useState('');
    const [usersError, setUsersError] = useState(null);
    const [datereg, setDatereg] = useState('');
    const [dateregError, setDateregError] = useState(null);
    const [session, setSession] = useState('');
    const [sessionError, setSessionError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal

    useEffect(() => {
        fetchUsers(0, 50)
        .then(response => {
            const {userDatas} = response.data;
            setListUsers(userDatas);
        })
        .catch(error => {
            console.log(error);
        });
        getListUsers();
        getListProducts();
    }, []);

    useEffect(() => {
        if(Object.keys(props.item).length !== 0){
            setId(props.item.product.id);
            setLib(props.item.product.lib);
            setPrice(props.item.product.price);
            setTheoryQty(props.item.product.qty);
            setListRows(props.item.rows);
            setQty(0);
        }
    }, [props.item]);

    const getListUsers = () => {
        setListUsers([]);
        fetchUsers().then(response => {
            const {userDatas} = response.data;
            if (response.data.userDatas) {
                setListUsers(userDatas);
            }else{
                console.log(response.data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
    const getListProducts = () => {
        setListProducts([]);
        fetchProducts().then(response => {
            const {productDatas} = response.data;
            if (response.data.productDatas) {
                setListProducts(productDatas);
            }else{
                console.log(response.data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const clearForm = () => {
        setLib('');
        setPrice('');
    }

    const controlInputs = () => {
        let valid = true;
        const localLib = stringIsEmpty(lib);
        const localQty =  qty;

        if(localLib === null){
            valid = false;
            setLibError('Ce champ est obligatoire');
        }else{
            setLib(localLib);
            setLibError(null);
        }
        if(localQty <= 0){
            valid = false;
            setPriceError('Ce champ est obligatoire');
        }
        /*if(localQty === null){
            valid = false;
            setQtyError('Ce champ est obligatoire');
        }else{
            setQty(localCode);
            setQtyError(null);
        }*/


        return valid;
    }

    const handleUpdateClick = () => {
        
        if(controlInputs()){
           props.action(props.item.product, qty);
           props.onClose();
        }
    }
    const addInventoryRow = (item) => {
        listRows.push(item)
    }
    
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    return (
        <>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Modifier les informations"
            onPrimaryActionClick={handleUpdateClick}
            loader={loader}
        >
            <div className="input-line">
                <Input 
                    label="Article"
                    disabled = {true} 
                    value={lib} 
                    onChange={setLib}
                    error={libError}
                />
                <Input 
                    label="Quantité réel" 
                    value={qty} 
                    onChange={setQty}
                    error={qtyError}
                />
            </div>

        </ModalForm>
        <NotifyModal
            label="Modification de l'inventaire"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>L'inventaire a bien été modifié !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la modification de l'inventaire !</p>
        </NotifyModal>
        </>
        
    );
}

export default Update;