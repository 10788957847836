import React from "react";
import '../styles/components/loader.css';

/**
 * @author Don TG
 * @param {object} props        // {className, visible}
 * @returns 
 */
function Loader(props){
    return (
        <div className={`loader ${props.className} ${props.visible ? '' : 'hide'}`}></div> 
    )
}

export default Loader;