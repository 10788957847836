import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import {navigate, setBreadcrumb} from '../../app/appSlice';
import { COSNIGNATIONS_LIST_ROUTE, INVOICES_PARAMS_ROUTE} from '../../constants/routes';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import Select from "../../components/form/Select";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import Update from "./Update";
import Add from "./Add";
//import Update from "./Update";
import BackdropModal from "../../components/BackdropModal";
import NonedropModal from "../../components/NonedropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import {fetchconsignations, deleteConsignation, findConsignations } from './ConsignationAPI';
import {fetchInvoiceParams } from '../invoiceparams/InvoiceparamsAPI';
import {fetchClients} from '../Clients/ClientsAPI';


/**
 * @author Emerone Power
 * @returns 
 */
function Consignations(){
    const [addForm, setAddForm] = useState(false);
    const [list, setList] = useState([]);
    const [client, setClient] = useState('');
    const [clientList, setClientList] = useState([]);
    const [invParams, setInvParams] = useState([]);
    const [category, setCategory] = useState("none");
    const [pattern, setPattern] = useState('');
    const [updateForm, setUpdateForm] = useState(false);
    const [itemToManage, setItemToManage] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const dispatch = useDispatch();
    const [params, setParams] = useState({});

    useEffect(() => {
        dispatch(navigate(COSNIGNATIONS_LIST_ROUTE));
        dispatch(setBreadcrumb(['Gestion des consignations']));
        getConsignations();
        getInvparms();
        getClients();
    }, []);

    const getConsignations = (value = "none", property = 'returnQty') => {
        setList([]);
        setClient(value);
        if (value !== "none" && value != 'completed' && property !== 'client') {
            value = parseInt(value);
        }
        switch (value) {
            case "none":
                fetchconsignations()
            .then(response => {
                const {consignationDatas} = response.data;
                if (response.data.params && response.data.consignationDatas) {
                    setList(consignationDatas);
                    setParams(response.data.params);
                }else{
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
                break;
            case 'completed':
                findConsignations(value, "completed")
            .then(response => {
                const {consignationDatas} = response.data;
                if (response.data.params && response.data.consignationDatas) {
                    setList(consignationDatas);
                    setParams(response.data.params);
                }
            })
            .catch(error => {
                console.log(error);
            });
                break;
            case value:
                findConsignations(value, property)
            .then(response => {
                const {consignationDatas} = response.data;
                if (response.data.params && response.data.consignationDatas) {
                    setList(consignationDatas);
                    setParams(response.data.params);
                    setClient(value);
                }
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
                break;
            default:
                fetchconsignations()
            .then(response => {
                const {consignationDatas} = response.data;
                if (response.data.params && response.data.consignationDatas) {
                    setList(consignationDatas);
                    setParams(response.data.params);
                }else{
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
                break;
        }
    }
    const getInvparms = (value = "none") => {
        //get invoices Params infos
        fetchInvoiceParams().then(response => {
            const {invParamDatas} = response.data;
            console.log(invParamDatas);
            if(invParamDatas.length > 0){
                setInvParams(invParamDatas);
            }
        }).catch(error => {
            console.log()
        });
    }
    const getClients = () => {
        fetchClients()
        .then(response => {
            const {clientDatas} = response.data;
            if(clientDatas.length !== 0){
                setClientList(clientDatas);
            }
        })
        .catch(error => {
            console.log(error);
        }); 
    }
    const toggleAddForm = () => {
        setAddForm(!addForm);
    }

    const toggleUpdateForm = () => {
        setUpdateForm(!updateForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const handleUpdateIconClick = (item) => {
        setItemToManage(item);
        toggleUpdateForm();
    }

    const handleDeleteIconClick = (item) => {
        setItemToManage(item);
        toggleConfirmModal();
    }

    const deleteItem = () => {
        deleteConsignation(itemToManage.id, itemToManage.productId).then(response => {
            if(response.data.prodSupplies?.params.state === 'successfull'){
                getConsignations();
                toggleNotifyModal();
            }else{
                toggleNotifyModalError();
            }
            console.log(response.data);
        }).catch(error =>  {
            console.log(error);
        });
        
        toggleConfirmModal();
    }

    //None / info modal
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        setItemToManage(0);
        toggleNoneModal();
    }
    const assigments = useSelector((state) => state.app.userAssigments[3]);
    function getButton(type, item = null) {
        
        var toggleModal = handleNoneIconClic;
        if (type ==='add') {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = toggleAddForm
            }
            return  <Button 
            label="Ajouter" 
            className="primary" 
            onClick={toggleModal}
            //onClick={toggleAddForm}
        />
        }
        if (type ==='update' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleUpdateIconClick(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Edit onClick={() => toggleModal(item)} />
        }
        if (type ==='delete' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleDeleteIconClick(item)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <Delete onClick={toggleModal} />
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    const handleNextClick = () => {
        if(params.end !== params.total){
            getConsignations(params.end, params.end + 25);
        }
            
    }

    const handlePreviousClick = () => {
        if(params.start !== 1){
            getConsignations(params.end - 25, params.start);
        }
            
    }

    return (
        <>
            <div className="main-head main-head-users">
                {
                    list.length > 0 &&
                    <>
                        <div className="table-infos">
                            <label>{`${params.start+1} - ${params.end} sur ${params.total}`}</label>
                            <ArrowLeft 
                                className={params.start+1 === 1 ? 'disable' : undefined} 
                                onClick={handlePreviousClick} 
                            />
                            <ArrowRight 
                                className={params.end === params.total ? 'disable' : undefined}
                                onClick={handleNextClick} 
                            />
                        </div>
                        
                    </> 
                }
                <Select selected={client} onChange={e => getConsignations(e.target.value, 'client')}>
                    <option value="none" selected>Tout</option>
                    <option value="none" disabled>Clients</option>
                    <option value="unknow">Anonyme</option>
                    {
                        clientList.map(option => (
                            <option 
                                value={option.lib} 
                                key={option.lib}
                            >
                                {`${option.lib}`}
                            </option>
                        ))
                    }

                </Select>
                {
                    list.length > 0 &&
                    <Input 
                        placeholder="Rechercher" 
                        value={pattern}
                        onChange={setPattern}    
                    />
                }
                <Select selected={category} onChange={e => getConsignations(e.target.value)}>
                    <option value="none" selected>Tout</option>
                    <option value="completed" >Complets</option>
                    <option value="uncompleted" >Incomplets</option>
                    <option value="0" >Aucun</option>
                </Select>

                
            </div>
            <div className="main-body">
                {
                    list.length > 0 ?
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Client</th>
                                <th>Facture</th>
                                <th>Consignation</th>
                                <th>Retour</th>
                                <th>Total</th>
                                <th>PU</th>
                                <th>Montant</th>
                                <th>Statut</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((item, index) => (
                                    <tr 
                                        key={item.id} 
                                        className={
                                            pattern !== '' ?
                                            !item.productLib?.toLowerCase().includes(pattern.toLowerCase()) ? 'hide' : '' :
                                            ''
                                        }
                                    >
                                        <td>{index + 1}</td>
                                        <td>{item.invoiceClient}</td>
                                        <td>{item.invoiceMat}</td>
                                        <td>{item.qty}</td>
                                        <td>{item.returned}</td>
                                        <td>{item.qty - item.returned}</td>
                                        <td>{invParams.length > 0 ? invParams[8].descrip : ''}</td>
                                        <td>{invParams.length > 0 ? (item.qty-item.returned)*parseInt(invParams[8].descrip) : ''}</td>
                                        <td>{item.qty === item.returned ? 'complet' : 'incomplet'}</td>
                                        <td className="table-actions">
                                            {getButton('update', item)}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p>Vous n'avez aucun Approvisionnement</p>
                }
                
                
                <Update 
                    className={`medium-modal ${updateForm ? '' : 'hide'}`} 
                    onClose={toggleUpdateForm}
                    item={itemToManage}
                    fetch={getConsignations}
                />
                <BackdropModal
                    label="Confirmez cette action"
                    onClose={toggleConfirmModal}
                    onPrimaryActionClick={deleteItem}
                    className={showConfirmModal ? '' : 'hide'} 
                >
                    <p>Voulez vous vraiment supprimer cet approvisionnement ?</p>
                </BackdropModal>
                <NonedropModal
                    label="Action non autorisée"
                    onClose={toggleNoneModal}
                    className={showNonedropModal ? '' : 'hide'} 
                >
                    <p>Vous n'avez pas accès à ce type action</p>
                </NonedropModal>
                <NotifyModal
            label="Modification de profile"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>L'approvisionnement a bien été supprimé !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la suppression de l'approvisionnement !</p>
        </NotifyModal>
            </div>
        </>
    );
}

export default Consignations;