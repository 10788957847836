import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import usersReducer from '../features/users/usersSlice';
import profilesReducer from '../features/profiles/profilesSlice';
import assignmentsReducer from '../features/assignments/assignmentsSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    users: usersReducer,
    profiles: profilesReducer,
    assignments: assignmentsReducer
  }
});
