import React, {useState, useEffect} from 'react';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {stringIsEmpty} from '../../utils/functions';
import {updateInvoice, fetchInvoices} from './InvoicesAPI';
import {fetchClients} from '../Clients/ClientsAPI';
import NotifyModal from "../../components/NotifyModal"; //Notify modal


/**
 * @author Don TG
 * @param {object} props    //{className, onClose, item, fetch}
 * @returns 
 */
function Update(props){
    const [client, setClient] = useState('');
    const [clientError, setClientError] = useState(null);
    const [clientId, setClientId] = useState('');
    const [clientIdError, setClientIdError] = useState(null);
    const [clientList, setClientList] = useState([]);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(null);
    const [css, setCss] = useState('');
    const [cssError, setCssError] = useState(null);
    const [datelivr, setDatelivr] = useState('');
    const [datelivrError, setDatelivrError] = useState(null);
    const [datereg, setDatereg] = useState('');
    const [dateregError, setDateregError] = useState(null);
    const [dateupdate, setDateupdate] = useState('');
    const [dateupdateError, setDateupdateError] = useState(null);
    const [delay, setDelay] = useState('');
    const [delayError, setDelayError] = useState(null);
    const [htprice, setHtprice] = useState('');
    const [htpriceError, setHtpriceError] = useState(null);
    const [matricul, setMatricul] = useState('');
    const [matriculError, setMatriculError] = useState(null);
    const [netprice, setNetprice] = useState('');
    const [netpriceError, setNetpriceError] = useState(null);
    const [numpaie, setNumpaie] = useState('');
    const [numpaieError, setNumpaieError] = useState(null);
    const [numref, setNumref] = useState('');
    const [numrefError, setNumrefError] = useState(null);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(null);
    const [sessionid, setSessionid] = useState('');
    const [sessionidError, setSessionidError] = useState(null);
    const [sold, setSold] = useState(0);
    const [soldError, setSoldError] = useState(null);
    const [discount, setDiscount] = useState('');
    const [discountError, setDiscountError] = useState(null);
    const [statusid, setStatusid] = useState(0);
    const [statusidError, setStatusidError] = useState(null);
    const [tps, setTps] = useState('');
    const [tpsError, setTpsError] = useState(null);
    const [tva, setTva] = useState('');
    const [tvaError, setTvaError] = useState(null);
    const [payment, setPayment] = useState(0);
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal


    useEffect(() => {
        if(Object.keys(props.item).length !== 0){
            setClient(props.item.client);
            setClientId(props.item.client);
            setCode(props.item.code);
            setCss(props.item.css);
            setDatelivr(props.item.datelivr)
            setDatereg(props.item.datereg)
            setDateupdate(props.item.dateupdate);
            setDelay(props.item.datereg);
            setHtprice(props.item.htprice);
            setMatricul(props.item.matricul);
            setNetprice(props.item.netprice);
            setNumpaie(props.item.numpaie);
            setNumref(props.item.numref);
            setPhone(props.item.phone);
            setSessionid(props.item.sessionid);
            setSold(props.item.sold);
            setDiscount(props.item.discount);
            setStatusid(props.item.statusid);
            setTps(props.item.tps);
            setTva(props.item.tva);
        }
    }, [props.item]);
    useEffect(() => {
        fetchClients()
        .then(response => {
            const {clientDatas} = response.data;
            if(clientDatas.length !== 0){
                setClientList(clientDatas);
            }
        })
        .catch(error => {
            console.log(error);
        });    
    }, []);

    const clearForm = () => {
        setClient('');
        setCode('');
        setCss('');
        setDatelivr('');
        setDatereg('');
        setDateupdate('');
        setDelay('');
        setHtprice('');
        setMatricul('');
        setNetprice('');
        setNumpaie('');
        setPhone('');
        setSold(0);
        setTps('');
        setTva('');
        setSold(0);
        setPayment(0);
    }

    const controlInputs = () => {
        let valid = true;
        const localClient = stringIsEmpty(client);
        const localPhone =  stringIsEmpty(phone);
        const localCode = stringIsEmpty(code);
        const localMatricul = stringIsEmpty(matricul);
        const localCss= stringIsEmpty(css);
        

        if(localClient === null){
            valid = false;
            setClientError('Ce champ est obligatoire');
        }else{
            setClient(localClient);
            setClientError(null);
        }

        return valid;
    }

    const handleUpdateClick = () => {
        if(controlInputs()){
            let newSold = parseInt(sold) + parseInt(payment);
            let newStatus = 1;
            if (newSold > 0) {
                setSold(newSold);
            }
            newSold === netprice? newStatus = 2 : newStatus = 1;

            updateInvoice({id: props.item.id, code: code, matricule: matricul, numref: numref, numpaie: numpaie, tva: tva, tps: tps, css: css, delay: delay, client: client, phone: phone, netprice: netprice, htprice: htprice, sold: newSold, status: newStatus, datelivr: datelivr, datereg: datereg}).then(response => {
                setLoader(false);
                console.log(response);
                if(response.data.params.state == 'successfull'){
                    clearForm();
                    props.onClose();
                    props.fetch();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
            }).catch(error => {
                setLoader(false);
                console.log(error);
            });
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }
    

    return (
        <>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Modifier les informations"
            onPrimaryActionClick={handleUpdateClick}
            loader={loader}
        >
            <div className="input-line">
                <Input 
                    disabled={true}
                    label="Matricule" 
                    value={matricul} 
                    onChange={setMatricul}
                    error={matriculError}
                />
                <Input
                    disabled={true}
                    label="Client"
                    value={client} 
                    onChange={client}
                    error={false}
                />
            </div>
            
            <div className="input-line">

                
                <Input 
                    label="Tel" 
                    value={phone} 
                    onChange={setPhone}
                    error={phoneError}
                />
                <Input
                    label="Numéro de référence" 
                    value={numref} 
                    onChange={setNumref}
                    error={numrefError}
                />
            </div>
            <div className="input-line">
                <Input
                    type="number"
                    label="Nouvel acompte" 
                    value={payment !== null ? payment : 0} 
                    onChange={setPayment}
                    error={""}
                />
                <Input
                    disabled={true}
                    type="number" 
                    label="PMTS" 
                    value={sold !== null ? parseInt(sold) + parseInt(payment) : 0} 
                    onChange={setSold}
                    error={soldError}
                />
            </div>
            <div className="input-line">
                
                <Input
                    type="date"
                    label="Date" 
                    value={datereg.slice(0,10)} 
                    onChange={setDatereg}
                    error={dateregError}
                />
                
            </div>
            <div className="input-line">
                <Input
                    disabled={true}
                    type="number"
                    label="TPS" 
                    value={tps !== null && tps !== 0 ? (netprice*10)/100 : 0} 
                    onChange={setTps}
                    error={tpsError}
                />
                <Input
                    disabled={true}
                    type="number" 
                    label="TVA" 
                    value={tva !== null && tva!==0 ? (netprice*8)/100 : 0} 
                    onChange={setTva}
                    error={tvaError}
                />
                <Input
                    disabled={true}
                    type="number" 
                    label="css" 
                    value={css !== null && css !== 0 ? (netprice*1)/100 : 0} 
                    onChange={setTva}
                    error={tvaError}
                />
            </div>
            
        </ModalForm>
        <NotifyModal
            label="Modification de l'article"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>Le profile a bien été modifié !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la modification du profile !</p>
        </NotifyModal>
        </>
        
    );
}

export default Update;