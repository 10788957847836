import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ModalForm from '../../components/ModalForm';
import Select from '../../components/form/Select';
import './add.css';
import {createThunk} from './assignmentsSlice';

/**
 * @author Don TG
 * @param {object} props    //{className, onClose}
 * @returns 
 */
function Add(props){
    const [user, setUser] = useState('');
    const [profile, setProfile] = useState('');
    const users = useSelector(state => state.users.list);
    const profiles = useSelector(state => state.profiles.list);
    const dispatch = useDispatch();

    useEffect(() => {
        if(users.length > 0 && user === '' && profiles.length > 0 && profile === ''){
            setUser(`${users[0].id}|${users[0].lastname} ${users[0].firstname}`);
            setProfile(profiles[0].id + '|' + profiles[0].name);
        }
    }, [users, profiles]);

    const controlInputs = () => {
        return (user !== '' && profile !== '');
    }

    const handleAddClick = () => {
        if(controlInputs()){
            const userData = user.split('|');
            const profileData = profile.split('|');

            dispatch(createThunk({
                id_user: userData[0],
                user: userData[1],
                id_profile: profileData[0],
                profile: profileData[1]
            }));
            props.onClose();
        }
    }

    return (
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Nouvelle affectation"
            onPrimaryActionClick={handleAddClick}
        >
            <Select label="Utilisateur" onChange={e => setUser(e.target.value)}>
                {
                    users.map(option => (
                        <option 
                            value={`${option.id}|${option.lastname} ${option.firstname}`} 
                            key={option.id}
                        >
                            {`${option.lastname} ${option.firstname}`}
                        </option>
                    ))
                }
            </Select>
            <Select label="Profil" onChange={e => setProfile(e.target.value)}>
                {
                    profiles.map(option => (
                        <option 
                            value={option.id + '|' + option.name} 
                            key={option.id}
                        >
                            {`${option.name}`}
                        </option>
                    ))
                }
            </Select>
        </ModalForm>
    );
}

export default Add;