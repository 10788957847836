import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import {navigate, setBreadcrumb} from '../../app/appSlice';
import { convertNumberToMoneyFormat } from "../../utils/functions";
//import {remove} from './ProductsSlice';
import {PRODUCTTYPES_LIST_ROUTE, PRODUCTS_LIST_ROUTE, PRODUCTTYPE_API_ADD_ROUTE, PRODUCTTYPE_API_DELETE_ROUTE, PRODUCTTYPE_API_FIND_ROUTE, PRODUCTTYPE_API_UPDATE_ROUTE} from '../../constants/routes';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import Select from "../../components/form/Select";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import ButtonCircle from "../../components/ButtonCircle";
import Add from "./Add";
import Update from "./Update";
import BackdropModal from "../../components/BackdropModal";
import NonedropModal from "../../components/NonedropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import {fetchProductTypes, deleteProductTypes, findProductTypes, updateProductTypes} from './ProductTypesAPI.js';


/**
 * @author Emerone Power
 * @returns 
 */
function ProductTypes(){
    const [addForm, setAddForm] = useState(false);
    const [list, setList] = useState([]);
    const [pattern, setPattern] = useState('');
    const [updateForm, setUpdateForm] = useState(false);
    const [itemToManage, setItemToManage] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const dispatch = useDispatch();
    const navigateIn = useNavigate();
    const [params, setParams] = useState({});

    useEffect(() => {
        dispatch(navigate(PRODUCTTYPES_LIST_ROUTE));
        dispatch(setBreadcrumb(['Gestion des Catégories']));
        getProductTypes();
    }, []);

    const getProductTypes = () => {
        setList([]);
        fetchProductTypes().then(response => {
                const {productTypeDatas} = response.data;
                if (response.data.productTypeDatas && response.data.params) {
                    setList(productTypeDatas);
                    setParams(response.data.params);
                }else{
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const toggleAddForm = () => {
        setAddForm(!addForm);
    }

    const toggleUpdateForm = () => {
        setUpdateForm(!updateForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const handleUpdateIconClick = (item) => {
        setItemToManage(item);
        toggleUpdateForm();
    }

    const handleDeleteIconClick = (item) => {
        setItemToManage(item);
        toggleConfirmModal();
    }

    const deleteItem = () => {
        deleteProductTypes(itemToManage.id).then(response => {
            if(response.data.params.state === 'successfull'){
                getProductTypes();
                toggleNotifyModal();
            }else{
                toggleNotifyModalError();
            }
        }).catch(error =>  {
            console.log(error);
        });
        
        toggleConfirmModal();
    }
    
    //None / info modal
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        setItemToManage(0);
        toggleNoneModal();
    }
    const assigments = useSelector((state) => state.app.userAssigments[3]);
    function getButton(type, item = null) {
        
        var toggleModal = handleNoneIconClic;
        if (type ==='add') {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = toggleAddForm
            }
            return  <Button 
            label="Ajouter une catégorie" 
            className="primary" 
            onClick={toggleModal}
            //onClick={toggleAddForm}
        />
        }
        if (type ==='update' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleUpdateIconClick(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Edit onClick={() => toggleModal(item)} />
        }
        if (type ==='delete' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleDeleteIconClick(item)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <Delete onClick={toggleModal} />
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    const handleNextClick = () => {
        if(params.end !== params.total){
            getProductTypes(params.end, params.end + 25);
        }
            
    }

    const handlePreviousClick = () => {
        if(params.start !== 1){
            getProductTypes(params.end - 25, params.start);
        }
            
    }

    const handleLinkClick = (href) => {
        if(href){
            navigateIn(href);
        }
    }
    return (
        <>
            <div className="main-head main-head-users">
                {
                    list.length > 0 &&
                    <>
                        <div className="table-infos">
                            <label>{`${params.start+1} - ${params.end} sur ${params.total}`}</label>
                            <ArrowLeft 
                                className={params.start+1 === 1 ? 'disable' : undefined} 
                                onClick={handlePreviousClick} 
                            />
                            <ArrowRight 
                                className={params.end === params.total ? 'disable' : undefined}
                                onClick={handleNextClick} 
                            />
                        </div>
                        
                        {
                            list.length > 0 &&
                            <Input 
                                placeholder="Rechercher" 
                                value={pattern}
                                onChange={setPattern}    
                            />
                        }
                        {/*<Button 
                            label="Ajouter un profil" 
                            className="primary" 
                            onClick={toggleAddForm}
                        />*/}
                        {getButton('add')}
                    </>
                }
                
            </div>
            <div className="main-body">
                {
                    list.length > 0 ?
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Nom</th>
                                <th>Description</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((item, index) => (
                                    <tr 
                                        key={item.id} 
                                        className={
                                            pattern !== '' ?
                                            !item.lib?.toLowerCase().includes(pattern.toLowerCase()) ? 'hide' : '' :
                                            ''
                                        }
                                    >
                                        <td>{index + 1}</td>
                                        <td>{item.lib}</td>
                                        <td>{item.descrip}</td>
                                        <td className="table-actions">
                                            {/*<Edit onClick={() => handleUpdateIconClick(item)} />
                                            <Delete onClick={() => handleDeleteIconClick(item)} />*/}
                                            {getButton('update', item)}
                                            {getButton('delete', item)}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p>Vous n'avez aucune catégorie</p>
                }
                <br />
                <ButtonCircle
                    label="Etat de stocks" 
                    className="buttton-circle-info" 
                    onClick={() => navigateIn(PRODUCTS_LIST_ROUTE)}/>
                <Add 
                    className={`medium-modal ${addForm ? '' : 'hide'}`} 
                    onClose={toggleAddForm}
                    fetch={getProductTypes}
                />
                <Update 
                    className={`medium-modal ${updateForm ? '' : 'hide'}`} 
                    onClose={toggleUpdateForm}
                    item={itemToManage}
                    fetch={getProductTypes}
                />
                <BackdropModal
                    label="Confirmez cette action"
                    onClose={toggleConfirmModal}
                    onPrimaryActionClick={deleteItem}
                    className={showConfirmModal ? '' : 'hide'} 
                >
                    <p>Voulez vous vraiment supprimer cette catégorie ?</p>
                </BackdropModal>
                <NonedropModal
                    label="Action non autorisée"
                    onClose={toggleNoneModal}
                    className={showNonedropModal ? '' : 'hide'} 
                >
                    <p>Vous n'avez pas accès à ce type action</p>
                </NonedropModal>
                <NotifyModal
            label="Modification de profile"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>La catégorie a bien été supprimée !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la suppression de la catégorie !</p>
        </NotifyModal>
            </div>
        </>
    );
}

export default ProductTypes;