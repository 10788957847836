import React, {useState, useEffect} from 'react';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {addInvoice, fetchInvoices} from './InvoicesAPI';
import {stringIsEmpty} from '../../utils/functions';
import NotifyModal from "../../components/NotifyModal"; //Notify modal

/**
 * @author Don TG
 * @param {object} props    //{className, onClose, fetch}
 * @returns 
 */

function Add(props){
    const [lib, setlib] = useState('');
    const [libError, setLibError] = useState(null);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(null);
    const [nif, setNif] = useState('');
    const [nifError, setNifError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal

    
    

    const clearForm = () => {
        setlib('');
        setCode('');
        setPhone('');
        setEmail('');
        setNif('');
    }

    const controlInputs = () => {
        let valid = true;
        const localLib = stringIsEmpty(lib);
        const localPhone =  stringIsEmpty(phone);
        const localCode = stringIsEmpty(code);
        const localEmail = stringIsEmpty(email);
        const localNif = stringIsEmpty(nif);

        if(localLib === null){
            valid = false;
            setLibError('Ce champ est obligatoire');
        }else{
            setlib(localLib);
            setLibError(null);
        }

        return valid;
    }

    const handleAddClick = () => {
        setLoader(true);
        let responseType = false;
        if(controlInputs()){
            addInvoice({lib: lib, code: code, phone: phone, email: email, nif: nif}).then(response => {
                setLoader(false);
                //console.log(response.data);
                if(response.data.params.state === 'successfull'){
                    clearForm();
                    props.onClose();
                    props.fetch();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
            }).catch(error => {
                setLoader(false);
            });
        }else
        setLoader(false);
        
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
    }
    
    return (<>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Nouveau client"
            onPrimaryActionClick={handleAddClick}
            loader={loader}
        >
            <div className="input-line">
                <Input 
                    label="Nom du client" 
                    value={lib} 
                    onChange={setlib}
                    error={libError}
                />
                <Input 
                    label="Tel" 
                    value={phone} 
                    onChange={setPhone}
                    error={phoneError}
                />
                
            </div>
            <div className="input-line">
                <Input 
                    label="Email" 
                    value={email} 
                    onChange={setEmail}
                    error={emailError}
                />
                <Input 
                    label="NIF" 
                    value={nif} 
                    onChange={setNif}
                    error={nifError}
                />
                
            </div>
            
            
        </ModalForm>
        <NotifyModal
            label="Ajout article"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>Le profile a été ajouté !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors l'ajout du profile !</p>
        </NotifyModal>
        </>
        
    );
}

export default Add;