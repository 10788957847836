import React, {useState, useEffect} from "react";
import Chart from 'chart.js/auto';
import '../../styles/components/chart/chart.css';
//import { Chart } from "chart.js/dist";

/**
 * @author Emerone Power
 * @param {object} props    //{legend, type, title, axeX, axeY}
 * @returns 
 */
function ChartView(props){
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        if(Object.keys(props).length !== 0){
            
            //props.datas.map(item => item.sold);
            
            
            setDatas(
                /*[
                    {year: 2019, count: 10},
                    {year: 2020, count: 20},
                    {year: 2021, count: 25},
                    {year: 2022, count: 15},
                    {year: 2021, count: 25},
                    {year: 2022, count: 15},
                    {year: 2023, count: 25},
                    {year: 2024, count: 15},
                ]*/
                props.datas

            );
            //props.datas.map((item, index) => (console.log(item.sold)));
            /*datas.map((row, index) => (
                localdatas.push(row.sold)
            ));*/
            
            load();
            
        }
    }, [props]);

    const load = async () => {
        let localdatas = props.datas;
        //console.log(props.datas);   
        if(document.getElementById(props.canvas) != '' && localdatas.length > 0){
            new Chart ( 
                document.getElementById(props.canvas),
                {
                    type: 'bar',
                    data: {
                        labels: localdatas.map((item => item.day)),
                        datasets: [
                            {
                                label: "chiffre d'affaires",
                                data : localdatas.map((item => item.sold))
                            }
                        ]
                    }
                } 
            )
        }
        
        //return <canvas id="chart-1"></canvas>;
        setDatas(localdatas);
    }
    return (
        <div className="chart-view">
            {
                props.title && 
                <label>{props.title}</label>
            }
            <div className="canvas">

                
                
            </div>
        </div>
    );
}

export default ChartView;