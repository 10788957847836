import React, {useState, useEffect} from 'react';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {stringIsEmpty} from '../../utils/functions';
import {updateInvoice, fetchInvoices} from './InvoicesAPI';
import {fetchClients} from '../Clients/ClientsAPI';
import {addPaiment} from '../paiments/PaimentsAPI';
import NotifyModal from "../../components/NotifyModal"; //Notify modal


/**
 * @author Don TG
 * @param {object} props    //{className, onClose, item, fetch}
 * @returns 
 */
function UpdateTable(props){
    const [dateupdate, setDateupdate] = useState('');
    const [dateupdateError, setDateupdateError] = useState(null);
    const [htprice, setHtprice] = useState('');
    const [htpriceError, setHtpriceError] = useState(null);
    const [netprice, setNetprice] = useState('');
    const [netpriceError, setNetpriceError] = useState(null);
    const [sessionid, setSessionid] = useState('');
    const [sessionidError, setSessionidError] = useState(null);
    const [sold, setSold] = useState(0);
    const [soldError, setSoldError] = useState(null);
    const [discount, setDiscount] = useState('');
    const [discountError, setDiscountError] = useState(null);
    const [statusid, setStatusid] = useState(0);
    const [statusidError, setStatusidError] = useState(null);
    const [payment, setPayment] = useState(0);
    const [paymentError, setPaymentError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal

    useEffect(() => {
        if(Object.keys(props.item).length !== 0){
            setDateupdate(props.item.dateupdate);
            setHtprice(props.item.htprice);
            setNetprice(props.item.netprice);
            setSessionid(props.item.sessionid);
            setSold(props.item.sold);
            setDiscount(props.item.discount);
            setStatusid(props.item.statusid);
        }
    }, [props.item]);
    
    useEffect(() => {
        fetchClients()
        .then(response => {
            const {clientDatas} = response.data;
            if(clientDatas.length !== 0){
                //setClientList(clientDatas);
            }
        })
        .catch(error => {
            console.log(error);
        });    
    }, []);

    const clearForm = () => {
        setDateupdate('');
        setHtprice('');
        setNetprice('');
        setSold(0);
        setPayment(0);
    }

    const controlInputs = () => {
        let valid = true;

        const localPayment= stringIsEmpty(payment);

        if(localPayment === null){
            valid = false;
            setPaymentError('Ce champ est obligatoire');
        }else{
            setPayment(payment);
            setPaymentError(null);
        }
        return valid;
    }

    const handleUpdateClick = () => {
        if(controlInputs()){
            let newSold = parseInt(sold) + parseInt(payment);
            let newStatus = 1;
            if (newSold > netprice) {
                newSold = netprice;
            }
            if (newSold > 0) {
                setSold(newSold);
            }
            newSold === netprice? newStatus = 2 : newStatus = 1;
            newSold < netprice && newSold !== 0 ? newStatus = 3 : newStatus = 1;

            updateInvoice({id: props.item.id,  code: props.item.code, matricule: props.item.matricul, numref: props.item.numref, numpaie: props.item.numpaie, tva: props.item.tva, tps: props.item.tps, css: props.item.css, delay: props.item.delay, client: props.item.client, phone: props.item.phone, netprice: props.item.netprice, htprice: props.item.htprice, sold: newSold, status: newStatus, datelivr: props.item.datelivr, datereg: props.item.datereg}).then(response => {
                setLoader(false);
                if(response.data.params.state == 'successfull'){
                    clearForm();
                    props.onClose();
                    props.fetch();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
            }).catch(error => {
                setLoader(false);
                console.log(error);
            });

            //insert Paimment
            if(payment > 0){
                addPaiment({price: payment, datereg: props.item.datereg, invoice: props.item.id, session: null}).then(res => {
                    if (res.data.params.state == 'successfull') {
                        
                        
                    }
                }).catch(error => {
                    setLoader(false);
                    console.log(error);
                });
            }
        }
    }

    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }
    

    return (
        <>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Modifier les informations"
            onPrimaryActionClick={handleUpdateClick}
            loader={loader}
        >
            

            <h2 className='text-center'>NAP : <b>{ netprice }</b></h2>
            <div className="input-line">
                <Input
                    type="number"
                    label="Nouvel acompte" 
                    value={payment !== null ? payment : 0} 
                    onChange={setPayment}
                    error={""}
                />
                
            </div>
            <div className="input-line">
                <Input
                    disabled={true}
                    type="number"
                    label="Solde" 
                    value={payment !== null ? parseInt(netprice) - ((parseInt(sold) + parseInt(payment))) : 0} 
                    onChange={""}
                    error={""}
                />
                <Input
                    disabled={true}
                    type="number" 
                    label="PMTS" 
                    value={sold !== null ? parseInt(sold) + parseInt(payment) : 0} 
                    onChange={setSold}
                    error={soldError}
                />
            </div>
            
        </ModalForm>
        <NotifyModal
            label="Modification de l'article"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>Le profile a bien été modifié !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la modification du profile !</p>
        </NotifyModal>
        </>
        
    );
}

export default UpdateTable;