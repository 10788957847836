import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import {navigate, setBreadcrumb} from '../../app/appSlice';
import { INVOICES_PAIMENTS_ROUTE, INVOICES_LIST_ROUTE} from '../../constants/routes';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import Select from "../../components/form/Select";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import ButtonCircle from "../../components/ButtonCircle";
//import Update from "./Update";
import BackdropModal from "../../components/BackdropModal";
import NonedropModal from "../../components/NonedropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import {fetchPaimments, invalidatePaiment, findPaiments } from './PaimentsAPI';


/**
 * @author Emerone Power
 * @returns 
 */
function Paiments(){
    const [addForm, setAddForm] = useState(false);
    const [list, setList] = useState([]);
    const [category, setCategory] = useState("none");
    const [pattern, setPattern] = useState('');
    const [updateForm, setUpdateForm] = useState(false);
    const [itemToManage, setItemToManage] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const dispatch = useDispatch();
    const navigateIn = useNavigate();
    const [params, setParams] = useState({});

    useEffect(() => {
        dispatch(navigate(INVOICES_PAIMENTS_ROUTE));
        dispatch(setBreadcrumb(['Gestion des Paiments']));
        getPaiments();
    }, []);

    const getPaiments = (value = "none") => {
        setCategory(value);
        setList([]);
        if (value !== "none") {
            value = parseInt(value)
        }
        switch (value) {
            case "none":
                fetchPaimments()
            .then(response => {
                const {paimentDatas} = response.data;
                if (response.data.params && response.data.paimentDatas) {
                    setList(paimentDatas);
                    setParams(response.data.params);
                }else{
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
                break;
            case value:
                findPaiments(value, "price")
            .then(response => {
                const {paimentDatas} = response.data;
                if (response.data.params && response.data.paimentDatas) {
                    setList(paimentDatas);
                    setParams(response.data.params);
                }
            })
            .catch(error => {
                console.log(error);
            });
                break;
            default:
                fetchPaimments()
            .then(response => {
                const {paimentDatas} = response.data;
                if (response.data.params && response.data.paimentDatas) {
                    setList(paimentDatas);
                    setParams(response.data.params);
                }else{
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
                break;
        }
    }

    const toggleAddForm = () => {
        setAddForm(!addForm);
    }

    const toggleUpdateForm = () => {
        setUpdateForm(!updateForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const handleUpdateIconClick = (item) => {
        setItemToManage(item);
        toggleUpdateForm();
    }

    const handleDeleteIconClick = (item) => {
        setItemToManage(item);
        toggleConfirmModal();
    }

    const deleteItem = () => {
        invalidatePaiment(itemToManage.id, itemToManage.productId).then(response => {
            if(response.data.paiment?.params.state === 'successfull'){
                getPaiments();
                toggleNotifyModal();
            }else{
                toggleNotifyModalError();
            }
            console.log(response.data);
        }).catch(error =>  {
            console.log(error);
        });
        
        toggleConfirmModal();
    }

    //None / info modal
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        setItemToManage(0);
        toggleNoneModal();
    }
    const assigments = useSelector((state) => state.app.userAssigments[3]);
    function getButton(type, item = null) {
        
        var toggleModal = handleNoneIconClic;
        if (type ==='add') {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = toggleAddForm
            }
            return  <Button 
            label="Ajouter" 
            className="primary" 
            onClick={toggleModal}
            //onClick={toggleAddForm}
        />
        }
        if (type ==='update' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleUpdateIconClick(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Edit onClick={() => toggleModal(item)} />
        }
        if (type ==='delete' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleDeleteIconClick(item)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <Delete onClick={toggleModal} />
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    const handleNextClick = () => {
        if(params.end !== params.total){
            getPaiments(params.end, params.end + 25);
        }
            
    }

    const handlePreviousClick = () => {
        if(params.start !== 1){
            getPaiments(params.end - 25, params.start);
        }
            
    }

    return (
        <>
            <div className="main-head main-head-users">
                {
                    list.length > 0 &&
                    <>
                        <div className="table-infos">
                            <label>{`${params.start+1} - ${params.end} sur ${params.total}`}</label>
                            <ArrowLeft 
                                className={params.start+1 === 1 ? 'disable' : undefined} 
                                onClick={handlePreviousClick} 
                            />
                            <ArrowRight 
                                className={params.end === params.total ? 'disable' : undefined}
                                onClick={handleNextClick} 
                            />
                        </div>
                        
                    </> 
                }
                <Select selected={category} onChange={e => getPaiments(e.target.value)}>
                    <option value="none" selected>Tout</option>
                    <option value="5000" >5 000f</option>
                    <option value="10000" >10 000f</option>
                    <option value="20000" >20 000 f</option>
                    <option value="30000" >30 000 f</option>
                    <option value="40000" >40 000 f</option>
                    <option value="50000" >50 000 f</option>
                    <option value="60000" >60 000 f</option>
                    <option value="70000" >70 000 f</option>
                    <option value="1000000" >100 000 f</option>
                </Select>
                {
                            list.length > 0 &&
                            <Input 
                                placeholder="Rechercher (date)" 
                                value={pattern}
                                onChange={setPattern}    
                            />
                        }
            </div>
            <div className="main-body">
                {
                    list.length > 0 ?
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Facture</th>
                                <th>Montant</th>
                                <th>Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((item, index) => (
                                    <tr 
                                        key={item.id} 
                                        className={
                                            pattern !== '' ?
                                            !item.datereg?.includes(pattern.toLowerCase()) ? 'hide' : '' :
                                            ''
                                        }
                                    >
                                        <td>{index + 1}</td>
                                        <td>{item.invoiceMat}</td>
                                        <td>{item.price}</td>
                                        <td>{item.datereg}</td>
                                        <td className="table-actions">
                                            {getButton('delete', item)}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p>Vous n'avez aucun paiment</p>
                }
                <br />
                <ButtonCircle
                    label="Gestion factures" 
                    className="buttton-circle-info" 
                    onClick={() => navigateIn(INVOICES_LIST_ROUTE)}/>
                <BackdropModal
                    label="Confirmez cette action"
                    onClose={toggleConfirmModal}
                    onPrimaryActionClick={deleteItem}
                    className={showConfirmModal ? '' : 'hide'} 
                >
                    <p>Voulez vous vraiment supprimer cet approvisionnement ?</p>
                </BackdropModal>
                <NonedropModal
                    label="Action non autorisée"
                    onClose={toggleNoneModal}
                    className={showNonedropModal ? '' : 'hide'} 
                >
                    <p>Vous n'avez pas accès à ce type action</p>
                </NonedropModal>
                <NotifyModal
            label="Modification de profile"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>L'approvisionnement a bien été supprimé !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la suppression du paiment !</p>
        </NotifyModal>
            </div>
        </>
    );
}

export default Paiments;