import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {navigate, setBreadcrumb} from '../../app/appSlice';
import {FEATURES_LIST_ROUTE, DASHBOARD_LIST_ROUTE} from '../../constants/routes';
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import Button from "../../components/form/Button";
import ButtonCircle from "../../components/ButtonCircle";
import NonedropModal from "../../components/NonedropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import { fetchFeatures, updateFeature } from "./featuresAPI";
import { fetchProfiles } from "../profiles/profilesAPI";
import './features.css';

/**
 * @author Don TG
 * @returns 
 */
function Features(){
    const [profile, setProfile] = useState(0);
    const [features, setFeatures] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [pattern, setPattern] = useState('');
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const dispatch = useDispatch();
    const navigateIn = useNavigate();
    

    useEffect(() => {
        dispatch(navigate(FEATURES_LIST_ROUTE));
        dispatch(setBreadcrumb(['Gestion des fonctionnalités']));

        fetchProfiles()
        .then(response => {
            const {profileDatas} = response.data;

            if(profileDatas.length !== 0){
                setProfiles(profileDatas);
                setProfile(profileDatas[0].id);
                getFeaturesOfProfile(profileDatas[0].id);
            }
        })
        .catch(error => {
            console.log(error);
        });    

    }, []);

    const getFeaturesOfProfile = (profile_id) => {
        fetchFeatures(profile_id).then(response => {
            console.log(response.data);
            setFeatures(response.data.accessDatas?.assigments);
        }).catch(error => {
            console.error(error);
        });
    }

    const handleChangeProfile = (profile_id) => {
        setProfile(profile_id);
        getFeaturesOfProfile(profile_id);
    }

    const handleSaveButtonClick = () => {
        let format = '';
        for(let feature of features){
            format += `${feature.module};${feature.Authorized};${feature.manage}:`;
        }
        format = format.slice(0, -1);

        updateFeature({id: profile, assigments: format}).then(response => {
            if(response.data.ProfileAccess?.params.state == 'succesful'){
                toggleNotifyModal();
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const handleToggleCheckbox = (module_id, type) => {
        setFeatures(features.map(feature => {
            if(feature.module == module_id){
                if(type === 1){
                    const nextValue = (feature.Authorized == 0 ? 1 : 0);
                    if(nextValue === 0)
                        feature.manage = 0;
                    feature.Authorized = nextValue;
                }else{
                    const nextValue = (feature.manage == 0 ? 1 : 0);
                    if(nextValue === 1)
                        feature.Authorized = 1;
                    feature.manage = (feature.manage == 0 ? 1 : 0);
                }
            }
            return feature;
        }));
    }

    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    //
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        //setItemToManage(0);
        toggleNoneModal();
    }
    const assigments = useSelector((state) => state.app.userAssigments[5]);
    //console.log(useSelector((state) => state.app.userAssigments));
    function getButton(type, item = null) {
        
        var toggleModal = handleNoneIconClic;
    
        if (type ==='save') {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = handleSaveButtonClick;
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Button 
            label="Enregistrer" 
            className="primary" 
            onClick={toggleModal}
        />
        }
        if (type ==='authorized' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleToggleCheckbox(item.module, 1)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <input 
            type="checkbox" 
            checked={item.Authorized != 0 ? 'checked' : ''} 
            onChange={toggleModal}
            //onChange={() => handleToggleCheckbox(item.module, 1)}
            />
        }
        if (type ==='managed' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleToggleCheckbox(item.module, 2)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <input 
            type="checkbox" 
            checked={item.manage != 0 ? 'checked' : ''} 
            onChange={toggleModal}
            //onChange={() => handleToggleCheckbox(item.module, 2)}
            />
        }
    }

    return (
        <>
        {
            features?.length > 0 ?
            <>
                <div className="main-head main-head-users main-head-features">
                    <Select selected={profile} onChange={e => handleChangeProfile(e.target.value)}>
                        {
                            profiles.map(option => (
                                <option 
                                    value={option.id} 
                                    key={option.id}
                                >
                                    {`${option.lib}`}
                                </option>
                            ))
                        }
                    </Select>
                    <Input 
                        placeholder="Rechercher" 
                        value={pattern}
                        onChange={setPattern}    
                    />
                    {getButton('save')}
                    {/*<Button 
                        label="Enregistrer" 
                        className="primary" 
                        onClick={handleSaveButtonClick}
                    />*/}
                </div>
                <div className="main-body main-body-features">
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th>Fonctionnalité</th>
                                <th className="center">Autoriser</th>
                                <th className="center">Gérer</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                features.map((item, index) => (
                                    <tr 
                                        key={index}
                                        className={
                                            pattern !== '' ? 
                                            !item.lib?.toLowerCase().includes(pattern.toLowerCase()) ? 'hide' : '' :
                                            ''
                                        }
                                    >
                                        <td>{index + 1}</td>
                                        <td>{item.lib}</td>
                                        <td className="center">
                                            {getButton('authorized', item)}
                                                    
                                            {/*<input 
                                                type="checkbox" 
                                                checked={item.Authorized != 0 ? 'checked' : ''} 
                                                onChange={() => handleToggleCheckbox(item.module, 1)}
                                    />*/}
                                        </td>
                                        <td className="center">
                                         {getButton('managed', item)}
                                            {/*<input 
                                                type="checkbox" 
                                                checked={item.manage != 0 ? 'checked' : ''} 
                                                onChange={() => handleToggleCheckbox(item.module, 2)}
                                />*/}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </>
            :
            <p>Il n'y a aucune fonctionnalité dans l'application</p>
        }
            <ButtonCircle
                label="Accueil" 
                className="buttton-circle-info" 
                onClick={() => navigateIn(DASHBOARD_LIST_ROUTE)}/>
            <NotifyModal
                label="MMise à jour des droits d'accès"
                onClose={toggleNotifyModal}
                className={showNotifyModal ? '' : 'hide'}
                notifyClassName={'modal-success'}
            >
                <p>Les modifications ont bien été Enregistré !</p>
            </NotifyModal>

        <NonedropModal
            label="Action non autorisée"
            onClose={toggleNoneModal}
            className={showNonedropModal ? '' : 'hide'} 
        >
            <p>Vous n'avez pas accès à ce type action</p>
        </NonedropModal>
        </>
    );
}

export default Features;