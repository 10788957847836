import React from 'react';
import { useSelector} from 'react-redux';
import { ReactComponent as Group } from '../assets/icons/group.svg';
import { ReactComponent as Profile } from '../assets/icons/profile.svg';
import { ReactComponent as Key } from '../assets/icons/key.svg';
import { ReactComponent as Book } from '../assets/icons/book.svg';
import { ReactComponent as Company } from '../assets/icons/company.svg';
import { ReactComponent as Product } from '../assets/icons/stacked-print-products.svg';
import { ReactComponent as Contact } from '../assets/icons/user-check.svg';
import { ReactComponent as Invoice } from '../assets/icons/file-invoice.svg';
import { ReactComponent as InvoiceParams } from '../assets/icons/file-config.svg';
import { ReactComponent as CashMachine } from '../assets/icons/cash-machine.svg';
import { ReactComponent as CartDashboard } from '../assets/icons/business-chart.svg';
//import { ReactComponent as ActionsSys } from '../assets/icons/manage_accounts.svg';
//import { ReactComponent as Functions } from '../assets/icons/psychology.svg';
//import { ReactComponent as Affectation } from '../assets/icons/person_add_disabled.svg';
import NavLink from './NavLink';
import {
    USERS_LIST_ROUTE, PROFILES_LIST_ROUTE, ASSIGNMENTS_LIST_ROUTE,
    FEATURES_LIST_ROUTE, ACTIONS_LIST_ROUTE, ENTREPRISE_INFO_ROUTE, 
    PRODUCTS_LIST_ROUTE, PRODUCTSUPPLIES_LIST_ROUTE, CLIENTS_LIST_ROUTE, CASHREGISTER_PAD_ROUTE,
    INVOICES_LIST_ROUTE, INVOICES_PARAMS_ROUTE,COSNIGNATIONS_LIST_ROUTE, INVENTORY_LIST_ROUTE,
    DASHBOARD_LIST_ROUTE,
    INVCOMMANDS_LIST_ROUTE
  } from '../constants/routes';
import '../styles/components/nav.css';
import Products from '../features/Clients/Clients';
import Dashboard from '../features/dashboard/Dashboard';

/**
 * @author Don TG
 * @returns 
 */
function Nav(){
    const page = useSelector((state) => state.app.page);
    const assigments = useSelector((state) => state.app.userAssigments);
    console.log(assigments);
    function getModule(assigment, module) {
        if(assigment[module].authorized === 1 && module === 5){
            return <ul>
                    <li className='title'>Utilisateurs</li>
                    <NavLink 
                        active={page === USERS_LIST_ROUTE} 
                        authorized={1} 
                        label="Gestion des utilisateurs" 
                        icon={Group} 
                        href={USERS_LIST_ROUTE} 
                    />
                    <NavLink 
                        active={page === PROFILES_LIST_ROUTE}
                        label="Gestion des profils" 
                        icon={Profile} 
                        href={PROFILES_LIST_ROUTE} 
                    />
                    <NavLink 
                        label="Gestion des droits" 
                        icon={Key} 
                        href={FEATURES_LIST_ROUTE} 
                        active={page = FEATURES_LIST_ROUTE} 
                    />
                </ul>
        }
        if(assigment[module].authorized === 1 && module === 4){
            return <ul>
            <li className='title'>Bibliothèques</li>
            <NavLink 
                active={page === ENTREPRISE_INFO_ROUTE} 
                label="Infos Entreprise" 
                icon={Company} 
                href={ENTREPRISE_INFO_ROUTE} 
            />
            
        </ul>
        }
        if(assigment[module].authorized === 1 && module === 3){
            return <ul>
            <li className='title'>Catalogue</li>
            <NavLink 
                active={page === PRODUCTS_LIST_ROUTE} 
                label="Articles / produits" 
                icon={Product} 
                href={PRODUCTS_LIST_ROUTE} 
            />
            <NavLink 
                active={page === CLIENTS_LIST_ROUTE} 
                label="Clients" 
                icon={Contact} 
                href={CLIENTS_LIST_ROUTE} 
            />
            <NavLink 
                active={page === PRODUCTSUPPLIES_LIST_ROUTE} 
                label="Approvisionnements" 
                icon={Contact} 
                href={PRODUCTSUPPLIES_LIST_ROUTE} 
            />
            <NavLink 
                active={page === INVENTORY_LIST_ROUTE} 
                label="Inventaires" 
                icon={InvoiceParams} 
                href={INVENTORY_LIST_ROUTE} 
            />
            
             
        </ul>
        }
        if(assigment[module].authorized === 1 && module === 0){
            return <ul>
            <li className='title'>Caisse</li>
            <NavLink 
                active={page === CASHREGISTER_PAD_ROUTE} 
                label="Console" 
                icon={CashMachine} 
                href={CASHREGISTER_PAD_ROUTE} 
            />
             
        </ul>
        }
        if(assigment[module].authorized === 1 && module === 1){
            return <ul>
            <li className='title'>Facturation</li>
            <NavLink 
                active={page === INVOICES_LIST_ROUTE} 
                label="Factures" 
                icon={Invoice} 
                href={INVOICES_LIST_ROUTE} 
            />
            <NavLink 
                active={page === INVCOMMANDS_LIST_ROUTE} 
                label="Commandes" 
                icon={Invoice} 
                href={INVCOMMANDS_LIST_ROUTE} 
            />
            <NavLink 
                active={page === COSNIGNATIONS_LIST_ROUTE} 
                label="Consignations" 
                icon={InvoiceParams} 
                href={COSNIGNATIONS_LIST_ROUTE} 
            />
        </ul>
        }
        if(assigment[module].authorized === 1 && module === 6){
            return <ul>
            <li className='title'>Accueil</li>
            <NavLink 
                active={page === DASHBOARD_LIST_ROUTE} 
                label="Tableau de bord" 
                icon={CartDashboard} 
                href={DASHBOARD_LIST_ROUTE} 
            />
            
             
        </ul>
        }
    }
    return (
        <nav  className='v-nav'>
            {/*getModule(assigments, 6)*/}
            {/*getModule(assigments, 0)*/}
            {/*getModule(assigments, 1)*/}
            {/*getModule(assigments, 3)*/} 
            {/*getModule(assigments, 2)*/}
            {/*getModule(assigments, 5)*/}
            {/*getModule(assigments, 4)*/}
            
        </nav>
    );
}

export default Nav;