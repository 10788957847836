import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import {navigate, setBreadcrumb} from '../../app/appSlice';
import {remove} from './profilesSlice';
import {PROFILES_LIST_ROUTE, DASHBOARD_LIST_ROUTE} from '../../constants/routes';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import ButtonCircle from "../../components/ButtonCircle";
import Add from "./Add";
import Update from "./Update";
import BackdropModal from "../../components/BackdropModal";
import NonedropModal from "../../components/NonedropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import {fetchProfiles, deleteProfile} from './profilesAPI';

/**
 * @author Don TG
 * @returns 
 */
function Profiles(){
    const [addForm, setAddForm] = useState(false);
    const [list, setList] = useState([]);
    const [pattern, setPattern] = useState('');
    const [updateForm, setUpdateForm] = useState(false);
    const [itemToManage, setItemToManage] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const dispatch = useDispatch();
    const navigateIn = useNavigate();

    useEffect(() => {
        dispatch(navigate(PROFILES_LIST_ROUTE));
        dispatch(setBreadcrumb(['Gestion des profils']));
        getProfiles();
    }, []);

    const getProfiles = () => {
        fetchProfiles()
        .then(response => {
            const {profileDatas} = response.data;
            setList(profileDatas);
        })
        .catch(error => {
            console.log(error);
        });    
    }

    const toggleAddForm = () => {
        setAddForm(!addForm);
    }

    const toggleUpdateForm = () => {
        setUpdateForm(!updateForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const handleUpdateIconClick = (item) => {
        setItemToManage(item);
        toggleUpdateForm();
    }

    const handleDeleteIconClick = (item) => {
        setItemToManage(item);
        toggleConfirmModal();
    }

    const deleteItem = () => {
        deleteProfile(itemToManage.id).then(response => {
            if(response.data.profile?.params.state === 'successfull'){
                getProfiles();
                toggleNotifyModal();
            }else{
                toggleNotifyModalError();
            }
        }).catch(error =>  {
            console.log(error);
        });
        
        toggleConfirmModal();
    }
    
    //None / info modal
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        setItemToManage(0);
        toggleNoneModal();
    }
    const assigments = useSelector((state) => state.app.userAssigments[3]);
    function getButton(type, item = null) {
        
        var toggleModal = handleNoneIconClic;
        if (type ==='add') {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = toggleAddForm
            }
            return  <Button 
            label="Ajouter un profile" 
            className="primary" 
            onClick={toggleModal}
            //onClick={toggleAddForm}
        />
        }
        if (type ==='update' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleUpdateIconClick(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Edit onClick={() => toggleModal(item)} />
        }
        if (type ==='delete' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleDeleteIconClick(item)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <Delete onClick={toggleModal} />
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    return (
        <>
            <div className="main-head main-head-users">
                {
                    list.length > 0 &&
                    <Input 
                        placeholder="Rechercher" 
                        value={pattern}
                        onChange={setPattern}    
                    />
                }
                {/*<Button 
                    label="Ajouter un profil" 
                    className="primary" 
                    onClick={toggleAddForm}
                />*/}
                {getButton('add')}
            </div>
            <div className="main-body">
                {
                    list.length > 0 ?
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th>Nom du profil</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((item, index) => (
                                    <tr 
                                        key={item.id} 
                                        className={
                                            pattern !== '' ?
                                            !item.lib?.toLowerCase().includes(pattern.toLowerCase()) ? 'hide' : '' :
                                            ''
                                        }
                                    >
                                        <td>{index + 1}</td>
                                        <td>{item.lib}</td>
                                        <td className="table-actions">
                                            {/*<Edit onClick={() => handleUpdateIconClick(item)} />
                                            <Delete onClick={() => handleDeleteIconClick(item)} />*/}
                                            {getButton('update', item)}
                                            {getButton('delete', item)}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    <p>Vous n'avez aucun profil</p>
                }
                <ButtonCircle
                    label="Accueil" 
                    className="buttton-circle-info" 
                    onClick={() => navigateIn(DASHBOARD_LIST_ROUTE)}/>
                <Add 
                    className={`small-modal ${addForm ? '' : 'hide'}`} 
                    onClose={toggleAddForm}
                    fetch={getProfiles}
                />
                <Update 
                    className={`small-modal ${updateForm ? '' : 'hide'}`} 
                    onClose={toggleUpdateForm}
                    item={itemToManage}
                    fetch={getProfiles}
                />
                <BackdropModal
                    label="Confirmez cette action"
                    onClose={toggleConfirmModal}
                    onPrimaryActionClick={deleteItem}
                    className={showConfirmModal ? '' : 'hide'} 
                >
                    <p>Voulez vous vraiment supprimer ce profil ?</p>
                </BackdropModal>
                <NonedropModal
                    label="Action non autorisée"
                    onClose={toggleNoneModal}
                    className={showNonedropModal ? '' : 'hide'} 
                >
                    <p>Vous n'avez pas accès à ce type action</p>
                </NonedropModal>
                <NotifyModal
            label="Modification de profile"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>Le profile a bien été supprimé !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la suppression du profile !</p>
        </NotifyModal>
            </div>
        </>
    );
}

export default Profiles;