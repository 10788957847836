import axios from 'axios';
import {AUTH_API_ROUTE} from '../../constants/routes';
import { convertObjectToURLSearchParams } from '../../utils/functions';

/**
 * @author Don TG
 * @param {object} data     //{login, password}
 * @returns
 */
export function auth(data){
    const params = convertObjectToURLSearchParams(data);

    return axios.post(AUTH_API_ROUTE, params);
}