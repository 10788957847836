import React, {useState, useEffect} from 'react';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {addProduct, fetchProductTypes} from './ProductsAPI';
import {stringIsEmpty} from '../../utils/functions';
import { fetchMeasureUnits } from '../measureUnits/MeasureUnitsAPI';
import NotifyModal from "../../components/NotifyModal"; //Notify modal

/**
 * @author Don TG
 * @param {object} props    //{className, onClose, fetch}
 * @returns 
 */

function Add(props){
    const [listCategory, setListCategory] = useState([]);
    const [lib, setlib] = useState('');
    const [libError, setLibError] = useState(null);
    const [price, setPrice] = useState('');
    const [priceError, setPriceError] = useState(null);
    const [priceBuy, setPriceBuy] = useState('');
    const [priceBuyError, setPriceBuyError] = useState(null);
    const [category, setCategory] = useState(0);
    const [categoryError, setCategoryError] = useState(null);
    const [qty, setQty] = useState('');
    const [qtyError, setQtyError] = useState(null);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(null);
    const [unit, setUnit] = useState(0);
    const [listUnit, setListUnit] = useState([]);
    const [loader, setLoader] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal

    
    useEffect(() => {
        setListCategory([]);
        fetchProductTypes(0, 50)
        .then(response => {
            const {productTypeDatas} = response.data;
            if(response.data.productTypeDatas){
                setListCategory(productTypeDatas);
            }else{
                console.log(response.data)
            }
            
        })
        .catch(error => {
            console.log(error);
        });
        getListUnits();
    }, []);

    const getListUnits = () => {
        setListUnit([]);
        fetchMeasureUnits().then(response => {
            const {unitDatas} = response.data;
            if (response.data.unitDatas) {
                setListUnit(unitDatas);
                setUnit(unitDatas[0]);
                if (setListUnit.lenght > 0) {
                    //setUnit(listUnit[0]);
                }else{
                    setUnit(1);
                }
            }else{
                console.log(response.data);
            }
        })
        .catch(error => {
            console.log(error);
        });
        
    }

    const clearForm = () => {
        setlib('');
        setCode('');
        setQty('');
        setPrice('');
        
        if (setListUnit.lenght > 0) {
            setUnit(listUnit[0]);
        }else{
            setUnit(1);
        }
    }

    const controlInputs = () => {
        let valid = true;
        const localLib = stringIsEmpty(lib);
        const localPrice =  stringIsEmpty(price);
        const localPriceBuy =  stringIsEmpty(priceBuy);
        const localCode = stringIsEmpty(code);
        const localQty = stringIsEmpty(qty);

        if(localLib === null){
            valid = false;
            setLibError('Ce champ est obligatoire');
        }else{
            setlib(localLib);
            setLibError(null);
        }
        if(localPrice === null){
            valid = false;
            setPriceError('Ce champ est obligatoire');
        }else{
            setPrice(localPrice);
            setPriceError(null);
        }
        if(localCode === null){
            valid = false;
            setCodeError('Ce champ est obligatoire');
        }else{
            setCode(localCode);
            setCodeError(null);
        }
        if(localQty === null){
            valid = false;
            setQtyError('Ce champ est obligatoire');
        }else{
            setQty(localCode);
            setQtyError(null);
        }
        if(localPriceBuy === null){
            valid = false;
            setPriceBuyError('Ce champ est obligatoire');
        }else{
            setPriceBuy(localPriceBuy);
            setPriceBuyError(null);
        }
        return valid;
    }

    const handleAddClick = () => {
        setLoader(true);
        let responseType = false;
        if(controlInputs()){
            addProduct({lib: lib, code: code, price: price, pricebuy: priceBuy, qty: qty, category: category, unit: unit}).then(response => {
                setLoader(false);
                console.log(response);
                if(response.data.params.state === 'successfull'){
                    clearForm();
                    props.onClose();
                    props.fetch();
                    toggleNotifyModal();
                }else{
                    toggleNotifyModalError();
                }
            }).catch(error => {
                setLoader(false);
            });
        }else
        setLoader(false);
        
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
    }
    
    return (<>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label="Nouvel article"
            onPrimaryActionClick={handleAddClick}
            loader={loader}
        >
            <div className="input-line">
                <Input 
                    label="Nom article" 
                    value={lib} 
                    onChange={setlib}
                    error={libError}
                />
                <Input 
                    label="Référence" 
                    value={code} 
                    onChange={setCode}
                    error={codeError}
                />
                
            </div>
            <div className="input-line">
                <Input 
                    label="PU" 
                    value={price} 
                    onChange={setPrice}
                    error={priceError}
                />
                <Input 
                    label="Coût d'achat" 
                    value={priceBuy} 
                    onChange={setPriceBuy}
                    error={priceBuyError}
                />
            </div>
            <div className="input-line">
                <Input 
                    label="Stock" 
                    value={qty} 
                    onChange={setQty}
                    error={qtyError}
                />
                
            </div>
            <div className="input-line">
                <Select selected={category} label="Catégorie" onChange={(e) => setCategory(e.target.value)}>
                <option value="none" key="">...</option>
                    {
                        listCategory.map(cat => (
                            <option value={cat.id} key={cat.id}>{cat.lib}</option>
                        ))
                    }
                </Select>
                <Select label='Unité' selected={unit} onChange={e => setUnit(e.target.value)}>
                        {
                            listUnit.map(option => (
                                <option 
                                    value={option.id} 
                                    key={option.id}
                                >
                                    {`${option.lib}`}
                                </option>
                            ))
                        }
                </Select>
            </div>
            
        </ModalForm>
        <NotifyModal
            label="Ajout article"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>L'article a été ajouté !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors l'ajout du profile !</p>
        </NotifyModal>
        </>
        
    );
}

export default Add;