import React, {useState, useEffect, useRef, useCallback} from 'react';
import { convertNumberToMoneyFormat } from '../../utils/functions';
import ModalForm from '../../components/ModalForm';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import './cash-register.css'; 
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import {stringIsEmpty} from '../../utils/functions';
import { fetchMeasureUnits } from '../measureUnits/MeasureUnitsAPI';
import NotifyModal from "../../components/NotifyModal"; //Notify modal


/**
 * @author Emerone Power
 * @param {object} props    //{className, onClose, item}
 * @returns 
 */
function ProductItem(props){
    const [id, setId] = useState(0);
    const [lib, setLib] = useState("");
    const [price, setPrice] = useState("");
    const [qty, setQty] = useState(0);
    const [qtyError, setQtyError] = useState(null);
    const [stock, setStock] = useState(0);
    const [stockError, setStockError] = useState(null);
    const [unit, setUnit] = useState(0);
    const [item, setItem] = useState({});
    const [row, setRow] = useState(null);
    const [sessionid, setSessionid] = useState('');
    const [discount, setDiscount] = useState(0);
    const [discountError, setDiscountError] = useState(null);
    const [tps, setTps] = useState('');
    const [css, setCss] = useState('');
    const [tpsError, setTpsError] = useState(null);
    const [tva, setTva] = useState('');
    const [tvaError, setTvaError] = useState(null);
    const [listUnit, setListUnit] = useState([]);
    const [loader, setLoader] = useState(false);
    //const [elementFocus, setElementFocus] = useFocus(false);
    //const [inputRef, setInputRef] = useRef(null);
    //const [inputRef, setInputRef] = useRef(null);
    const inputRef = useRef('null');
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal


    useEffect(() => {
        setItem({});
        setRow(null);
        setQty(0);
        setDiscount(0);
        if(Object.keys(props.item).length !== 0){
            setRow(props.row);
            setItem(props.item);
            setLib(props.item.product.lib);
            setPrice(props.item.product.price);
            setStock(props.item.product.qty);
            setQty(props.item.qty);
            setDiscount(props.item.discount);
            setUnit(props.item.unit);
            //
            setTps((props.item.netprice*10)/100 );
            setTva((props.item.netprice*8)/100);
            setCss((props.item.netprice*1)/100);
            if(!props.item.product.id){
                setLib(props.item.lib);
                setPrice(props.item.price);
            }
            getListUnits();
        }
        //getFocused(getElementById('inputQty'));
        console.log(document.getElementById('inputQty'));
    }, [props.item, props.row]);

    useEffect(() => {
          //inputFocused();
        //inputRef.current.focus();

        console.log(document.getElementById('inputQty'));
        if(inputRef.current){
            console.log(inputRef);
            //inputRef.current.focus(); 
        }
    }, []);

    const getListUnits = () => {
        setListUnit([]);
        fetchMeasureUnits().then(response => {
            const {unitDatas} = response.data;
            if (response.data.unitDatas) {
                setListUnit(unitDatas);
            }else{
                console.log(response.data);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
    const clearForm = () => {
        setQty(1);
        setDiscount(0);
    }

    const controlInputs = () => {
        let valid = true;
        const localQty = qty;
        const localDiscount = discount;
        const localStock = stock-qty;

        if( localStock < 0){
            valid = false;
            setStockError('Stock insuffisant');
        }else{
            setStock(stock);
            setStockError(null);
        }
        if(localQty === null || localQty < 1){
            valid = false;
            setQtyError('Ce champ est obligatoire (min : 1)');
        }else{
            setQty(qty);
            setQtyError(null);
        }
        if(localDiscount === null || localDiscount < 0){
            valid = false;
            setDiscount('Ce champ est obligatoire (min : 0)');
        }else{
            setDiscount(discount);
            setDiscountError(null);
        }

        return valid;
    }

    const handleUpdateClick = () => {
        if(controlInputs() && item.product.id > 0){
            if(qty >= 1 && discount >= 0){
                props.action(item.product, qty, discount, unit, row, "update")
                props.onClose();
            }
        
        }
        if(controlInputs() && item.product > 0){
            
            if(qty >= 1 && discount >= 0){
                
                props.action(item, qty, discount, unit, row, "update");
                
                props.onClose();
            }
            
        }
    }
    const handleDeleteIconClick = () =>{
        if(item.product.id > 0){
            props.action(item.product, qty, discount, unit, row, "delete")
            props.onClose();
        }

        if(item.product > 0){
            props.action(item, qty, discount, unit, row, "delete");
            props.onClose();
        }
    }
    function getFocused(element) {
        const ipref = inputRef.current;
        ipref.current.focus();
        console.log(ipref);
    }
    const inputFocused = useCallback((inputElement)=> {
        if(inputRef){
            inputRef.focus();
        }
        console.log(inputRef.current)
    },[]);
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }
    

    return (
        <>
        <ModalForm 
            onClose={props.onClose} 
            className={props.className} 
            label={"Paramètres de l'article : "}
            onPrimaryActionClick={handleUpdateClick}
            loader={loader}
        >
            <div>
                <h3>{lib} : {convertNumberToMoneyFormat(price)}</h3>
                <h4></h4>
            </div>
            <div className="input-line input-focus">
                <Input
                    id="inputQty"
                    ref={inputRef}
                    autofocus = {true}
                    type="number"
                    min="1"
                    label="Quantité" 
                    value={qty}  
                    onChange={setQty}
                    error={qtyError}
                    focus={true}
                    
                />
            </div>
            <div className="input-line">
                <Input
                    type="number"
                    min="0"
                    label="Remise" 
                    value={discount} 
                    onChange={setDiscount}
                    error={discountError}
                />
                
            </div>
            <div className="input-line">
                <Select selected={unit} onChange={e => setUnit(e.target.value)}>
                        {
                            listUnit.map(option => (
                                <option 
                                    value={option.id} 
                                    key={option.id}
                                >
                                    {`${option.lib}`}
                                </option>
                            ))
                        }

                </Select>
                
            </div>
            <div className="input-line btn-delete">
                {
                    row !== null && row >= 0 ? 
                    <Delete className="button" onClick={() => handleDeleteIconClick()} />
                    : ''
                }
            </div>
            <div className="input-line btn-delete">
                <Input
                    type="number"
                    min="0"
                    label="Stock" 
                    value={row !== null && row >= 0 ? item.stock - qty : stock - qty} 
                    onChange={setStock}
                    error={stockError}
                    disabled
                />
            </div>
            
        
        </ModalForm>
        <NotifyModal
            label="Modification article"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        
        >
            <p>L'articles a bien été Ajouté !</p>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors de l'ajout de l'article !</p>
        </NotifyModal>
        </>
        
    );
}

export default ProductItem;