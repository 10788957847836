import { createSlice } from "@reduxjs/toolkit";
import {DEFAULT_LIST_ITEMS_NUMBER} from '../../constants/config';
import { 
    fetchAssignments, 
    addAssignment,
    deleteAssignment 
} from "./assignmentsAPI";

/**
 * @author Don TG
 */
 export const assignmentsSlice = createSlice({
    name: 'assignments',
    initialState: {
        list: [], // list of assignments in current page
        from: 1, // number left
        to: DEFAULT_LIST_ITEMS_NUMBER, // number right
        by: DEFAULT_LIST_ITEMS_NUMBER, // number of assignments per page
        total: null, // total of assignments in database,
        pattern: '', // input search text
    },
    reducers: {
        fetch: (state, action) => {
            state.list = action.payload.list;
            state.from = action.payload.from;
            state.to = action.payload.to;
            state.total = action.payload.total;
        },
        create: (state, action) => {
            state.list = [action.payload, ...state.list];
        },
        remove: (state, action) => {
            state.list = state.list.filter(item => (
                item.id_user !== action.payload.id_user ||
                item.id_profile !== action.payload.id_profile
            ));
        }
    }
});

export const { fetch, create, remove } = assignmentsSlice.actions;

/**
 * First fetching.
 * 
 * @author Don TG
 * @returns 
 */
 export const firstFetchThunk = () => (dispatch, getState) => {
    const { by } = getState();

    fetchAssignments(0, by)
    .then(response => {
        const { assignments, total } = response.data; 
        const to = by > total ? total : by;
        dispatch(fetch({list: assignments, from: 1, to, total}));
    })
    .catch(error => {
        console.log(error);
    });    
}

/**
 * Add new assignment.
 * 
 * @author Don TG
 * @param {object} data 
 * @returns 
 */
 export const createThunk = (data) => (dispatch, getState) => {
    dispatch(create(data));
    /*addAssignment(data)
    .then(response => {
        dispatch(create(data));
    })
    .catch(error => {
        console.log(error);
    }); */
}

/**
 * Delete assignement.
 * 
 * @author Don TG
 * @param {int} id_user
 * @param {int} id_profile
 * @returns 
 */
 export const deleteThunk = (id_user, id_profile) => (dispatch, getState) => {
    dispatch(remove({id_user, id_profile}));
    /*deleteAssignment(id)
    .then(response => {
        dispatch(remove({id_user, id_profile}));
    })
    .catch(error => {
        console.log(error);
    }); */
}

export default assignmentsSlice.reducer;