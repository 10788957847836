import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import {navigate, setBreadcrumb} from '../../app/appSlice';
import { convertNumberToMoneyFormat } from "../../utils/functions";
//import {remove} from './ProductsSlice';
import {PRODUCTS_LIST_ROUTE, MEASUREUNITS_LIST_ROUTE, PRODUCTTYPES_LIST_ROUTE, DASHBOARD_LIST_ROUTE} from '../../constants/routes';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import Select from "../../components/form/Select";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import ChartView from "../../components/charts/Chart";
import "./dashboard.css";
import NavLink from "../../components/NavLink";
import HorizontalFlatNav from "../../components/HorizontalFlatNav";
import BackdropModal from "../../components/BackdropModal";
import NonedropModal from "../../components/NonedropModal";
import NotifyModal from "../../components/NotifyModal"; //Notify modal
import {findProducts} from '../Products/ProductsAPI';
import {findInvoices} from '../invoices/InvoicesAPI';
import {findConsignations} from '../consignations/ConsignationAPI';
import {fetchProductTypes} from '../productTypes/ProductTypesAPI';
import {fetchClients} from '../Clients/ClientsAPI';
import {findProductSupplies} from '../ProductSupplies/ProductSuppliesAPI';
import { fetchMeasureUnits } from '../measureUnits/MeasureUnitsAPI';


/**
 * @author Emerone Power
 * @returns 
 */
function Dashboard(){
    const [addForm, setAddForm] = useState(false);
    const [list, setList] = useState([]);
    const [rankinglist, setRankinList] = useState([]);
    const [productStats, setProductStats] = useState([]);
    const [invoiceStats, setInvoiceStats] = useState([]);
    const [chartDatas, setChartDatas] = useState([]);
    const [commandDatas, setCommandDatas] = useState([]);
    const [consignStats, setConsignStats] = useState([]);
    const [clientStats, setClientStats] = useState([]);
    const [prodtypeStats, setProdtypeStats] = useState([]);
    const [listCategory, setListCategory] = useState([]);
    const [listUnit, setListUnit] = useState([]);
    const [stockResume, setStockResume] = useState('');
    const [category, setCategory] = useState("none");
    const [totalQty, setTotalQty] = useState(0);
    const [totalQtyCasier, setTotalQtyCasier] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceCasier, setTotalPriceCasier] = useState(0);
    const [periode, setPeriode] = useState('');
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [pattern, setPattern] = useState('');
    const [updateForm, setUpdateForm] = useState(false);
    const [itemToManage, setItemToManage] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNonedropModal, setShowNonedropModal] = useState(false);
    const [showNotifyModal, setShowNotifyModal] = useState(false); //Notify Modal
    const [showNotifyModalError, setShowNotifyModalError] = useState(false); //Notify Modal
    const dispatch = useDispatch();
    const navigateIn = useNavigate();
    const [params, setParams] = useState({});

    useEffect(() => {
        dispatch(navigate(DASHBOARD_LIST_ROUTE));
        dispatch(setBreadcrumb(['Tableau de bord']));
        setPeriode("today");
        setMonth('today');  
        setYear('year');   
        getStats(); 
        
    }, []);

    useEffect(() => {
        getTotalResume(list);
    }, [list]);

    const getInvoices = (value = "today", periode = "today") => {
        setInvoiceStats([]);
        if (value !== "none") {
            //value = parseInt(value);
            findInvoices(value, "fullNetprice")
            .then(response => {
                const {invoiceDatas} = response.data;
                const {chartDatas} = response.data;
                const {commandDatas} = response.data;
                console.log(response.data);   
                if (response.data.invoiceDatas) {
                    setInvoiceStats(invoiceDatas);
                    setChartDatas(chartDatas);
                    setCommandDatas(commandDatas);
                }
            })   
            .catch(error => {
                console.log(error);
            });
        }else{
            findInvoices('all', "fullNetprice")
            .then(response => {
                const {invoiceDatas} = response.data;
                console.log(response.data);
                if (response.data.invoiceDatas) {
                    setInvoiceStats(invoiceDatas);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
    const getProducts = (value = "today", periode = "today") => {
        setProductStats([]);
        if (value !== "none") {
            //value = parseInt(value);
            findProducts(value, "fullstats")
            .then(response => {
                const {productDatas} = response.data;
                if (response.data.productDatas) {
                    setProductStats(productDatas);
                }
            }) 
            .catch(error => {
                console.log(error);
            });
            //produt types
            setProdtypeStats([]);
            fetchProductTypes(0, 500)
            .then(response => {
                const {productTypeDatas} = response.data;
                if (response.data.productTypeDatas) {
                    setProdtypeStats(productTypeDatas);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
       
    }
    const getConsigns = (value = "none", periode = "today") => {
        setConsignStats([]);
        if (value !== "none") {
            value = parseInt(value);
            findConsignations(value, "fullstats")
            .then(response => {
                const {consignationDatas} = response.data;
                if (response.data.params && response.data.consignationDatas) {
                    setConsignStats(consignationDatas);
                }
            })       
            .catch(error => {
                console.log(error);
            });
        }
        
    }
    const getClients = (value = "none", periode = "today") => {
        setClientStats([]);
        if (value !== "none") {
            value = parseInt(value);
            fetchClients(0, 500)
            .then(response => {
                const {clientDatas} = response.data;
                if (response.data.params && response.data.clientDatas) {
                    setClientStats(clientDatas);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
        
    }

    const getCategory = () => {
        setListCategory([]);
    }

    const getStats = (periode = "today") => {
        getInvoices(periode);
        getProducts("fullstats", periode);
        getConsigns("fullstats", periode);
        getClients("fullstats", periode);
    }


    const toggleAddForm = () => {
        setAddForm(!addForm);
    }

    const toggleUpdateForm = () => {
        setUpdateForm(!updateForm);
    }

    const toggleConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal);
    }

    const handleUpdateIconClick = (item) => {
        setItemToManage(item);
        toggleUpdateForm();
    }

    const handleDeleteIconClick = (item) => {
        setItemToManage(item);
        toggleConfirmModal();
    }

    
    const handleChangePeriodeClick = (value, type) => {
        let locM = '';
        let locY = year;
        let locPeriod = 'year-month';
        if(type == 'm'){
            setMonth(value);
            if(value == 'none'){
                value = 'all-month';
            }
            locM = value
            //setPeriode(locY+'-'+locM); 
        }
        if(type == 'y'){
            setYear(value); 
            if(value == 'none'){
                value = 'all-year';
            } 
            locY = value;
        }
        //setPeriode(value);
        if(value == "today"){
            locPeriod = value;
        }
        if(value != "today" && value !='month' ){
            locPeriod = locY+'-'+locM;
        }
        
        setPeriode(locPeriod);
        console.log(locPeriod)
        getStats(locPeriod);   
    }
    const handleChangeCategoryClick = (category) => {
        setCategory(category);
    }
    
    //None / info modal
    const toggleNoneModal = () => {
        //setShowConfirmModal(!setAddForm(!addForm););
        setShowNonedropModal(!showNonedropModal);
    }

    function handleNoneIconClic() {
        setItemToManage(0);
        toggleNoneModal();
    }
    const assigments = useSelector((state) => state.app.userAssigments[6]);
    function getButton(type, item = null) {
        
        var toggleModal = handleNoneIconClic;
        if (type ==='add') {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = toggleAddForm
            }
            return  <Button 
            label="Ajouter un article" 
            className="primary" 
            onClick={toggleModal}
            //onClick={toggleAddForm}
        />
        }    
        if (type ==='update' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleUpdateIconClick(item)
            }
            //<Edit onClick={() => handleUpdateIconClick(item)} />
            return  <Edit onClick={() => toggleModal(item)} />
        }
        if (type ==='delete' && item != null) {
            toggleModal = handleNoneIconClic;
            if (assigments.manage === 1) {
                toggleModal = () => handleDeleteIconClick(item)
            }
            //<Delete onClick={() => handleDeleteIconClick(item)} />
            return  <Delete onClick={toggleModal} />
        }
    }
    //Notify modal
    function toggleNotifyModal () {
        setShowNotifyModal(!showNotifyModal);
    }
    function toggleNotifyModalError () {
        setShowNotifyModalError(!showNotifyModalError);
        
    }

    const handleRankinClick = (item, value) => {
        //let result =    any;
        setRankinList([]);
        console.log('click');
        if(item = 'product'){
            findProducts(value, "sales-ranking")
            .then(response => {
                const {productDatas} = response.data;
                console.log(productDatas);
                if (response.data.productDatas) {
                    setRankinList(productDatas);
                    toggleNotifyModal();
                
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
    const handleNextClick = () => {
        if(params.end !== params.total){
            getProducts(params.end, params.end + 25);
        }
            
    }

    const handlePreviousClick = () => {
        if(params.start !== 1){
            getProducts(params.end - 25, params.start);
        }
            
    }

    function getTotalResume(items){
        let qty = 0
        let localqty = 0
        let price = 0
        let localStock = ''
        if(items.length > 0){
            items.map((item, index) => (
                qty = parseInt(parseInt(qty) + parseInt(item.qty)),
                price = parseInt(parseInt(price) + parseInt(item.price*item.qty))
            ));
        }
        setStockResume(localStock)
        setTotalQty(qty);
        setTotalPrice(price);
        //getTotalCasier(list);
    }
    
    const handleLinkClick = (href) => {
        if(href){
            navigateIn(href);
        }
    }
    
    return (
        <>
            
            <div className="main-head main-head-users">
                {
                    invoiceStats.length > 0 && productStats.length > 0 &&
                    <>
                        
                        <Select selected={year} onChange={e => setYear(e.target.value)}>
                            <option value="none" >Tout</option>
                            <option value="year" >Année Courrante</option>      
                        </Select> 
                        <Select selected={month} onChange={e => handleChangePeriodeClick(e.target.value, 'm')}>
                            <option value="none" >Tout</option>
                            <option value="today" >Aujourd'hui</option>
                            <option value="01" >Janvier</option>    
                            <option value="02" >Fevrier</option>    
                            <option value="03" >Mars</option>    
                            <option value="04" >Avril</option>    
                            <option value="05" >Mai</option>    
                            <option value="06" >Juin</option>     
                            <option value="07" >Juillet</option>    
                            <option value="08" >Août</option>    
                            <option value="09" >Septembre</option>    
                            <option value="10" >Octobre</option>    
                            <option value="11" >Novembre</option>    
                            <option value="12" >Décembre</option>    
                        </Select>
                    </> 
                }
                
            </div>
            <div className="main-body">
                {
                    assigments.authorized === 1 ? 
                        <>
                            <div className="options page-navlink">
                                <ul>
                                    <li>
                                        
                                    </li>
                                    <li>
                                        
                                    </li>
                                </ul>
                                
                            </div>
                            <div className="dsh-data-stats">
                                <div className="row">
                                    {
                                        invoiceStats.length > 0 && productStats.length > 0 && consignStats.length > 0 && clientStats.length > 0 ?
                                        <ul>
                                            <li className="data-row">
                                                <div className="data-item bg-grey"> <span className="item-label">Chiffre d'affaires </span> <span className="item-value">{ convertNumberToMoneyFormat(invoiceStats[0].fullNetprice? invoiceStats[0].fullNetprice : 0 ) }</span></div>
                                                <div className="data-item bg-grey"> <span className="item-label">Marge ({invoiceStats[0].fullProfit? Math.floor((invoiceStats[0].fullProfit*100)/invoiceStats[0].fullNetprice) : ''})%</span> <span className="item-value">{ convertNumberToMoneyFormat(invoiceStats[0].fullProfit? invoiceStats[0].fullProfit : 0) }</span></div>
                                                <div className="data-item bg-grey"> <span className="item-label">Créances </span>  <span className="item-value">{ convertNumberToMoneyFormat(invoiceStats[0].fullSold? invoiceStats[0].fullSold : 0 ) }</span></div>
                                                
                                            <div className="data-item bg-grey" id="data-item-cmd">
                                                <div className="sub-item">
                                                    <span className="item-label">Commandes en cours</span> <span className="item-value">{ commandDatas[0].total_count }</span>
                                                </div>
                                                <div className="sub-item">
                                                    <span className="item-label">Valeur</span> <span className="item-value">{ convertNumberToMoneyFormat(commandDatas[0].total_value) }</span>
                                                </div>
                                            </div>
                                            </li>
                                            <li className="data-row" id="data-row-center">
                                                <div className="data-item bg-grey"> <span className="item-label">Valeur Stock Total</span> <span className="item-value">{ convertNumberToMoneyFormat(productStats[0].fullPrice? productStats[0].fullPrice : 0) }</span></div>
                                                <div className="data-item bg-grey"> <span className="item-label">Casiers en Consignation</span> <span className="item-value">{ consignStats[0].fullConsign }</span> </div>
                                                <div className="data-item bg-grey"> <span className="item-label">Valeur consignations</span> <span className="item-value">{ convertNumberToMoneyFormat(consignStats[0].fullConsign*250) }</span></div>
                                                {chartDatas.length > 0 ? 
                                            <div className="data-item-g bg-grey"> <div className="chart-view">
                                                
                                                <canvas id="chart-1"></canvas>
                                                <ChartView label={"tab"} canvas={'chart-1'} datas={chartDatas}/> 
                                                </div> </div> : ''}
                                            </li>
                                            <li className="data-row" id="data-row-right">
                                                <div className="data-item bg-grey"> <span className="item-label">Nombre d'articles </span> <span className="item-value">{ productStats[0].totalEntries }</span></div>
                                                <div className="data-item bg-grey"> <span className="item-label">Nombre de clients</span> <span className="item-value">{ clientStats.length}</span></div>
                                            <div className="data-item bg-grey"> <span className="item-label">Nombre de Catégories</span> <span className="item-value">{ prodtypeStats.length}</span></div>
                                            <div className="data-item bg-grey"> <span className="item-label">Nombre de factures </span> <span className="item-value">{ invoiceStats[0].totalEntries }</span></div>
                                            </li>
                                            
                                            
                                            
                                            
                                            
                                            
                                            
                                            
                                            <div className="data-item-buttons"> <span className="item-btn" onClick={ () => { handleRankinClick('product', 5)}}>Top 5</span> <span className="item-btn" onClick={ () => { handleRankinClick('product', -5)}}>Flop 5</span></div>
                                        </ul> : ''
                                    }
                                </div>
                            </div>
                            <div className="horizontal-navigation">
                                <div className="row">
                                    {
                                        invoiceStats.length > 0 && productStats.length > 0 && consignStats.length > 0 && clientStats.length > 0 ?
                                        <HorizontalFlatNav /> : '' 
                                    }
                                </div>
                            </div>
                        </>
                    : <p>Bienvenu sur IKOKU</p>
                }
                
                
        
    
        <NonedropModal
            label="Action non autorisée"
            onClose={toggleNoneModal}
            className={showNonedropModal ? '' : 'hide'} 
        >
            <p>Vous n'avez pas accès à ce type action</p>
        </NonedropModal>
        <NotifyModal
            label="Classement"
            onClose={toggleNotifyModal}
            className={showNotifyModal ? '' : 'hide'}
            notifyClassName={'modal-success'}
        >
            <pre>{ rankinglist.length > 0 ? 
            rankinglist.map((item, index) => (
                <p> {index+1 +' - ' +item.lib +' ('+item.qty+')'}</p> 
            )) : '' }</pre>
        </NotifyModal>
        <NotifyModal
            label="Erreur"
            onClose={toggleNotifyModalError}
            className={showNotifyModalError ? '' : 'hide'}
            notifyClassName={'modal-danger'}
        >
            <p>Erreur lors la suppression du profile !</p>
        </NotifyModal>
            </div>
        </>
    );
}

export default Dashboard;